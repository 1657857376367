#main-login-register{
    &:after {
        position: absolute;
        display: block;
        width: 100%;
        height: 30%;
        top: 0;
        left: 0;
        background-color: $dark;
        background-image: url(../images/bg-login-register.jpg);
        background-size: cover;
        background-position: bottom;
        content: '';

        @include media-breakpoint-up(md) {
            height: 40%;
        }

        @include media-breakpoint-up(lg) {
            min-height: 12rem;
        }
    }
    #visitor_login_submit,
    #visitor_registration_form_submit{
        width: 350px;
        max-width: 100%;
    }
    .login-social-row{
        > a{
            display: block;
            width: 350px;
            max-width: 100%;
            margin: 0 calc(-15px / 2);
            padding: 1.5rem 1.6rem;
            &:hover{
                text-decoration: none;
            }
            & + a{
                margin-top: 1.5rem;
            }
            img{
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}
