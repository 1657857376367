footer {
  .footer-row{
    .footer-1,
    .footer-2,
    .footer-3{
      text-align: center;
      margin-bottom: 0;
      color: #fff;
    }
    .footer-3{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .footer-3 a + a{
      @include media-breakpoint-down(md){
        margin-left: 15px;
      }
    }
    .icon-social{
      @include media-breakpoint-down(md){
        margin-right: 0;
        padding: 0 4px;
      }
    }
    @include media-breakpoint-up(xl){
      .footer-1{
        text-align: left;
      }
      .footer-2{
        text-align: center;
      }
      .footer-3{
        justify-content: flex-end;
      }
    }
    @include media-breakpoint-down(lg){
      flex-direction: column;
      align-items: center;
      gap: 20px;
      .footer-1{
        color: rgba(255, 255, 255, 0.5);
      }
      .footer-1,
      .footer-3{
        order: 2;
      }
      .footer-2{
        order: 1;
      }
    }
    .footer-links {
      display: flex;
      justify-content: center;
      gap: 20px;
    }
  }
}