//depends on _video-list.scss
.video-list.featured{
  .video-list-grid {
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(auto-fit, calc(50% - 15px));
    }
    .video-list-item{
      background-image: linear-gradient(to right, #004F9E 0%, #0386A3 100%);
      color: #fff;
    }
    .item-label{
      color: #accee4;
    }
    .item-speaker::before{
      background-image: url("../vectors/ico-user-academy-light.svg");
    }
  }
}