.main-dashboard {
    &__welcome {
        background-image: url(../images/bg-login-register.jpg);
        background-size: cover;
        background-position: center;

        &--roadshow {
            background-image: url(../images/padova.jpg);

            &:before {
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: rgba($dark, .4);
                content: '';
            }
        }

        .text-primary {
            background: linear-gradient(165deg, $primary 38%, $accent);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    &__welcome-bottom {
        margin-top: -4rem;

        .filters__heading {
            top: 0;
        }
    }
}

.dashboard-avatar {
    background-image: url('../vectors/icon-user-dark.svg');
    background-color: $light;
    background-repeat: no-repeat;
    background-size: 5rem;
    background-position: 50% 40%;

    @include media-breakpoint-up(md) {
        background-size: 8rem;
    }

    &:hover {
        background-color: $white;
        transition: background-color .4s ease;
    }

    &__cta {
        border-top: 1px solid $color-border;
    }
}

.main-dashboard__list {
    li {
        .main-dashboard__list__el {
            border-top: 1px solid rgba($dark, .1);

            .calendar-icon{
                width: 40px;
                height: 40px;
                object-fit: contain;
            }
        }

        &:first-child {
            .main-dashboard__list__el {
                border-top: 1px solid transparent;
            }
        }
    }
}

#visitor_profile_edit_channelSwitch_placeholder,
label[for="visitor_profile_edit_channelSwitch_placeholder"]{
    display: none;
}

.loader-schedule-my-area {
    top: 25px;
}
