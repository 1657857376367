@mixin speaker-dark-ico{
  font-size: 1.5rem;
  margin-top: 10px;
  font-weight: 400;
  position: absolute;
  bottom: 20px;
  padding: 0 20px 0 43px;
  &::before{
    content: '';
    display: inline-block;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 13px;
    height: 14px;
    background-image: url("../vectors/ico-user-academy.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.video-list{
  max-width: 1140px;
  padding: 0 30px;
  margin: auto auto 80px auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  .white-card &{
    padding: 0;
    .video-list-grid .video-list-item{
      background-color: #EFF2F3;
    }
  }
  .profile &{
    margin-top: 32px;
    .video-list-grid{
      grid-template-columns: 100%;
      @include media-breakpoint-up(md) {
        grid-template-columns: repeat(auto-fit, calc(50% - 20px));
      }
      .video-list-item .item-title{
        font-size: 1.8rem;
        line-height: 2.1rem;
        @include media-breakpoint-up(md) {
          font-size: 2.2rem;
          line-height: 2.6rem;
        }
      }
    }
  }

  .video-list-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, 100%);
    @include media-breakpoint-up(md){
      grid-template-columns: repeat(auto-fit, calc(50% - 15px));
    }
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(auto-fit, calc(33.33% - 20px));
    }
    gap: 30px;
    width: 100%;
    .video-list-item{
      border-radius: 10px;
      background-color: #fff;
      overflow: hidden;
      color: #2E283D;
      position: relative;
      padding-bottom: 52.5px;
      &:not(:has(.item-speaker)) {
        padding-bottom: 20px;
      }
      &:has(.item-speaker) {
        padding-bottom: 52.5px;
      }
      &.new-item{
        animation: fade-in .8s ease;
      }
      @keyframes fade-in {
        0% {
          opacity: 0;
          transform: scale(0.9);
        }
        100% {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
    a:hover{
      text-decoration: none;
      img{
        transform: scale(1.2);
      }
    }
    .item-image {
      width: 100%;
      display: block;
      overflow: hidden;
      img{
        width: 100%;
        object-fit: cover;
        transition: transform .5s ease;
      }
    }
    span{
      display: block;
    }
    .item-label{
      color: #97939f;
      font-size: 1.2rem;
      text-transform: uppercase;
      margin-top: 21px;
      padding: 0 20px;
    }
    .item-title{
      font-size: 1.8rem;
      @include media-breakpoint-up(lg) {
        font-size: 2.2rem;
      }
      line-height: 2.6rem;
      margin-top: 10px;
      padding: 0 20px;
    }
    .item-speaker{
      @include speaker-dark-ico;
    }
    & + .load-more{
      margin-top: 60px;
    }
  }
  .load-more{
    color: #0386A3;
    font-size: 1.2rem;
    padding: 14px 20px;
    border-radius: 100px;
    border: solid 2px #0386A3;
    display: inline-block;
    width: fit-content;
    &[disabled]{
      opacity: .5;
      cursor: default;
      pointer-events: none;
    }
  }
}