$color-primary: #0386A3;
$primary: #0386A3;

@mixin speaker-light-ico{
  position: relative;
  color: #fff;
  font-size: 1.6rem;
  padding-left: 25px;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 13px;
    height: 14px;
    display: inline-block;
    background-image: url('../vectors/ico-user-academy-light.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }
}
@mixin speaker-dark-ico{
  font-size: 1.6rem;
  font-weight: 400;
  position: relative;
  &::before{
    content: '';
    display: inline-block;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 13px;
    height: 14px;
    background-image: url("../vectors/ico-user-academy.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
}