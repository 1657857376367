/* readmore */
#vector-cutted-rectangle{
  fill: var(--bodyColor, #EFF2F3);
}
.read-more {
  position: relative;
  overflow: hidden;
  padding-bottom: 3rem;
  &.is-active .read-more__footer a::before{
    transform: translateY(-50%) rotate(90deg);
  }
}
.read-more__content {
  overflow: hidden;
  transition: height .3s;
  /*display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;*/
  .read-more.is-active &{
    display: block;
  }
}
.read-more__footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #eff2f3;
  @include readmoreIcon();
  a {
    font-weight: 400;
    font-size: 1.6rem;
  }
}

/* // */
/* spinner */
@mixin spinner{
  &::before{
    content: '';
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    width: 10px;
    height: 1px;
    animation: spinner 1.5s cubic-bezier(0.18, 0.89, 0.32, 1.28) infinite;
  }
  @keyframes spinner {
    0%{
      transform: translate(-70px, 0);
      width: 60px;
    }
    25%{
      transform: translate(-70px, 0);
      width: 200px;
    }
    50%{
      transform: translate(70px, 0);
      width: 60px;
    }
    75%{
      transform: translate(-70px, 0);
      width: 200px;
    }
    100%{
      transform: translate(-70px, 0);
      width: 60px;
    }
  }
}

[id^="hx-spinner-"]:empty{
  display: block;
  height: 450px;
  position: relative;
  background-color: #e1e1e1;
  overflow: hidden;
  border-radius: 10px;
  margin: 20px 0;
  &::after{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 200%;
      height: 450px;
      background-image: linear-gradient(-45deg, transparent 0%, transparent 25%, #fff 50%, transparent 75%, transparent 100%);
      animation: loading-shine 1.7s ease-in-out infinite;
  }
  @keyframes loading-shine {
      0%{
          transform: translateX(-100%);
      }
      100%{
          transform: translateX(100%);
      }
  }
}
/* // */
body{
  a{
    color: $color-primary;
  }
}
body.page.academy{
  overscroll-behavior: none;
  &.page-blank{
    #slider-page > .top-items{
      max-width: 1140px;
    }
    .top-items{
      .item-title,
      .item-content,
      h1.item-topic,
      h2.item-topic,
      h3.item-topic,
      h4.item-topic,
      h5.item-topic{
        color: #000;
      }
    }
  }
  .bg-academy-gray {
    background-color: #EFF2F3 !important;
  }
  .bg-secondary{
    background-color: #0386A3 !important;
  }
  .academy-container{
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    padding-left: 30px;
    padding-right: 30px;
    &.narrow{
      max-width: 790px;
    }
    &.desktop-only{
      padding-left: 0;
      padding-right: 0;
      max-width: none;
      @include media-breakpoint-up(md){
        padding-left: 30px;
        padding-right: 30px;
        max-width: 1140px;
      }
      &.narrow{
        max-width: none;
        @include media-breakpoint-up(md){
          padding-left: 30px;
          padding-right: 30px;
          max-width: 790px;
        }
      }
    }
  }
  .white-card{
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;
    width: 100%;
    margin-top: 43px;
    .item-title{
      color: #2E283D;
      font-weight: 700;
      font-size: 2.8rem;
      @include media-breakpoint-up(md){
        font-size: 3.2rem;
      }
    }
  }
  .title-element{
    max-width: 1140px;
    margin: auto auto 32px auto;
    font-size: 2.8rem;
    font-weight: 700;
    padding: 0 30px;
    @include media-breakpoint-up(md) {
      font-size: 3.2rem;
      margin: auto auto 40px auto;
    }
  }
}
.bg-polygon{
  --height: 50dvh;
  &.height-1{
    --height: 586px;
  }
  &.height-2{
    --height: 613px;
  }
  position: absolute;
  width: 100%;
  height: calc(var(--height, 100%) + 6.16vw);
  background-image: linear-gradient(227deg, #004F9E 0%, #0386A3 100%);
  display: inline-block;
  clip-path: polygon(0% calc(6.16vw + var(--height)), 0 0, 100% 0, 100%  var(--height));
  transition: clip-path .5s ease-out, opacity .5s ease-out, height .5s ease-out;
  aspect-ratio: 1/1;
  z-index: -1;
}
.bg-variable{
  background-color: var(--bodyColor, #EFF2F3) !important;
}
.welcome--participant {
  &:before {
    background-image: url('../vectors/shape-cutted-rettangle-grey.svg');
  }
}
.area-wysiwyg{
  max-width: 1140px;
  margin: 0 auto;
  padding: 15px 30px;
}
.navbar-brand img{
  max-height: 6rem;
}
.btn-navbar-login {
  background-image: none;
  @include media-breakpoint-up(md){
    padding-right: calc(2rem + 40px);
    padding-left: 60px;
  }
  &:hover{
    span,
    &::before{
      filter: invert(1);
    }
  }
  span{
    color: #fff;
    font-weight: 400;
    transition: filter .3s ease;
  }
  &::before{
    content: '';
    display: inline-block;
    position: absolute;
    left: 35px;
    top: 50%;
    transform: translateY(-50%);
    width: 2rem;
    height: 2rem;
    background-image: url("../vectors/ico-user-academy-light.svg");
    background-repeat: no-repeat;
    z-index: 1;
    transition: filter .3s ease;
  }
  &::after {
    background-color: #0386A3;
  }
}
//general top items
.top-items {
  .item-topic {
    margin-top: 60px;
    text-transform: uppercase;
    a,
    p{
      color: rgba(255, 255, 255, 0.5);
      font-size: 1.2rem;
      font-weight: 700;
      @include media-breakpoint-up(md) {
        font-size: 1.6rem;
      }
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    &.item-topic {
      color: rgba(255, 255, 255, 0.5);
      font-size: 1.2rem;
      font-weight: 700;
      @include media-breakpoint-up(md) {
        font-size: 1.6rem;
      }
    }
  }

  .item-title {
    color: #fff;
    font-size: 3.4rem;
    line-height: 3.8rem;
    font-weight: 700;
    margin-top: 60px;
    @include media-breakpoint-up(md) {
      font-size: 4.5rem;
      line-height: 5rem;
    }
  }
  .item-topic + .item-title{
    margin-top: 22px;
  }

  .item-content {
    color: #fff;
    font-size: 1.9rem;
    line-height: 2.8rem;
    margin-top: 20px;
  }

  .item-speaker {
    @include speaker-light-ico;
  }

  .item-buttons{
    margin-top: 33px;
    display: flex;
    gap: 20px;
    flex-direction: column;
    @include media-breakpoint-up(md){
      flex-direction: row;
      gap: 30px;
    }
    a{
      border: solid 1px #fff;
      padding: 15px 20px;
      min-width: 160px;
      text-align: center;
      color: #fff;
      border-radius: 5px;
      font-size: 1.6rem;
      font-weight: 700;
      background-color: rgba(255, 255, 255, 0);
      transition: background-color 0.1s ease, color 0.1s ease;
      width: 100%;
      @include media-breakpoint-up(md){
        width: auto;
      }
      &:hover{
        background-color: rgba(255, 255, 255, 0.7);
        color: #000;
        text-decoration: none;
      }
      &:active,
      &:focus{
        background-color: rgba(255, 255, 255, 1);
        color: #000;
      }
    }
  }
}

