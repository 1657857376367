.schedule-subscription {
  position: relative;
  display: block;
  background-color: #e9ecef;
  width: 100%;
  height: 0;
  padding-top: 100%;
  color: #2e283d;
  border-radius: 20px;
  transition: background .4s ease;
}

.schedule-subscription {
  transform: scale(.97);
  transform-origin: center;
  transition: transform .3s ease, background .3s ease;
}

.schedule-subscription svg * {
  fill: #343a40;
  transition: all .4s ease;
}

.schedule-subscription:hover {
  background-color: #344af0;
}

.schedule-subscription:hover {
  transform: scale(1);
}

.schedule-subscription:hover svg * {
  fill: #FFFFFF;
}

.schedule-subscription svg{
  width: 90px;
  @include media-breakpoint-down(sm) {
    width: 70px;
  }
}