.favorite-link,
.jssocials-shares .jssocials-share .jssocials-share-link{
  filter: grayscale(0) brightness(1);
  transition: filter .1s ease;
  &:hover,
  &:active,
  &:focus{
    filter: grayscale(1) brightness(2.2);
  }
}
.favorite-link{
  display: block;
  margin-bottom: 24px;
  &.favorite .favorite-icon{
    background-image: url("../vectors/ico-preferiti-pieno.svg");
  }
  .favorite-icon{
    background-image: url("../vectors/ico-preferiti.svg");
    background-repeat: no-repeat;
    width: 18px;
    height: 24px;
    display: block;
    margin-left: 6px;
  }
}