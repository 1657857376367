.navbar .logo-container{
  padding-left: 30px;
  @include media-breakpoint-up(lg) {
    padding-left: 40px;
  }
  .navbar-brand{
    height: 66px;
    img{
      height: 100%;
    }
    @include media-breakpoint-up(md){
      height: unset;
    }
  }
}
.header-search{
  padding: 0 25px;
  position: relative;
  display: flex;
  align-items: center;
  transform: skew(10deg, 0);
  //transform: translateX(90px);
  transition: transform .3s ease;
  z-index: 2;
  /*@include media-breakpoint-up(lg) {
    &:focus-within {
      transform: translateX(0);

      form {
        input {
          width: 160px;
          max-width: calc(100% - 90px);
        }
      }

      &::after {
        width: 425px;
      }

      button[type="reset"] {
        opacity: 1;
        pointer-events: all;
      }
    }
  }*/
  &:hover,
  &:active,
  &:focus{
    z-index: 6;
    &::after{
      transform: scaleX(1.35);
    }
  }
  &::after{
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    content: '';
    background-color: #EFF2F3;
    //transition: width .3s ease;
    transform: scaleX(1);
    transition: transform .3s ease;
  }
  form{
    position: relative;
    z-index: 1;
    input{
      width: 90px;
      transition: width .3s ease;
    }
  }
  span.search-mobile-btn {
    display: block;
    background-image: url("/bundles/smau/vectors/ico-search-academy-dark.svg");
    background-repeat: no-repeat;
    background-size: 23px;
    z-index: 1;
    width: 23px;
    height: 23px;
    object-fit: contain;
    vertical-align: middle;
    position: relative;
    transform: skew(-10deg, 0);
  }
  button{
    all: unset;
    background-repeat: no-repeat;
    width: 23px;
    height: 23px;
    object-fit: contain;
    vertical-align: middle;
    position: relative;
    /*&[type="submit"],
    &.search-mobile-btn{
      background-image: url("/bundles/smau/vectors/ico-search-academy-dark.svg");
      z-index: 1;
    }*/
    &[type="reset"] {
      opacity: 0;
      pointer-events: none;
      transition: opacity .3s ease;
      position: absolute;
      right: -140px;
      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 15px;
        height: 1px;
        display: inline-block;
        left: 0;
        top: 50%;
        background-color: #000;
      }
      &::before {
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
      }
    }
  }
  input{
    all: unset;
    user-select: auto;
    &::selection{
      color: #fff;
      background-color: #0986a3;
    }
  }
}
.overlay-bg{
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
}
// Navbar
.navbar__toggler::before{
  right: -13px;
  width: calc(100% + 13px);
  background-color: #0386A3;
  opacity: 1;
  transition: opacity .7s ease;
  html.menu-open &{
    opacity: 0;
  }
}
.navbar__side-menu__content__background::before{
  background-image: linear-gradient(180deg, #004f9E 0%, #0386A3 100%);
}
.navbar__quick-links {
  font-size: 1.4rem;
}
.social-header{
  width: 100%;
  position: fixed;
  top: 0;
  height: 78.13px;
  transform: translateY(-100%);
  transition: transform .2s ease;
  background-color: #fff;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  @include media-breakpoint-up(md){
    display: none;
  }
  &.scrolled{
    transform: translateY(0);
  }
  .navbar-brand {
    padding: 0;
    img {
      @include media-breakpoint-up(xs) {
        min-height: 4rem;
        max-height: 4rem;
      }
    }
  }
  .smau-social-share{
    display: flex;
    gap: 16px;
    align-items: center;
    .favorite-link{
      margin-bottom: 0;
    }
  }
  .jssocials-share{
    & + .jssocials-share{
      margin-left: 16px;
    }
    img.jssocials-share-logo{
      height: 2.9rem;
    }
  }
}