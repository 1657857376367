.bottom-navigation {
    .open {
        .bottom-navigation__title:after {
           @include media-breakpoint-down(md) {
               content: '-';
           }
        }
    }

    &__title {
        &:after {
            display: inline-block;
            margin-left: .7rem;
            opacity: .5;
            content: '+';

            @include media-breakpoint-up(md) {
                content: none;
            }
        }
    }

    &__input-newsletter {
        border-width: 2px !important;
    }

    &__list {
        display: none;

        @include media-breakpoint-up(md) {
            display: block !important; // avoid slideToggle effects on resize
        }
    }

    a {
        transition: opacity .3s ease;

        &:hover {
            opacity: .7;
        }
    }
}

#meeting_request_create_form textarea {
    min-height: 15rem;
}

#meeting_request_create_form .form__input {
    -webkit-box-flex: 0;
    flex: 0 0 83.33333%;
    max-width: 80%;
    margin-left: 10%;
}

.navbar-station {
    height: 50px;
    margin-right: -10px;

    img {
        margin-top: 10px;
    }
}