.navbar {

    &-brand {
        padding: 1rem 0;
        margin-right: 0;

        img {
            width: auto;
            height: auto;
            max-width: calc(100vw - 125px);
            max-height: 5rem;

            @include media-breakpoint-up(xs) {
                min-height: 5rem;
                max-width: 13.5rem;
                object-fit: contain;
            }

            @include media-breakpoint-up(sm) {
                max-width: 23rem;
            }

            @include media-breakpoint-up(md) {
                max-width: none;
            }
        }
    }

    &__toggler {
        &:before {
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            top: 0;
            right: -13px;
            z-index: 0;
            background-color: $primary;
            transform: skew(10deg, 0deg);
            content: '';
        }

        &__icon {
            margin-right: 1.5rem;

            @include media-breakpoint-up(md) {
                margin-right: 2.2rem;
            }

            &__line {
                width: 2.7rem;
                height: 2px;
                margin-bottom: 5px;
                transform-origin: left;

                &:nth-child(2) {
                    transform: scaleX(.7);
                }

                &:nth-child(3) {
                    transform: scaleX(.5);
                }
            }
        }

        &__text__close {
            opacity: 0;
            visibility: hidden;
            transform: translateX(50%);
        }
    }

    &__quick-links {
        font-weight: 600;

        > a, .dropdown > a {
            padding: 0 1.5rem;

            &:before {
                position: absolute;
                display: block;
                width: 100%;
                height: .4rem;
                bottom: 0;
                left: 0;
                background-image: linear-gradient(16deg, $primary 8%, #5A2392 100%);
                z-index: 1;
                transform: translateY(100%) scaleX(1);
                transform-origin: bottom;
                transition: background .3s ease, transform .3s ease;
                content: '';
            }

            &.active {
                &:before {
                    transform: translateY(0%) scaleX(1);
                }

                &:hover {
                    &:before {
                        transform: translateY(0%) scaleX(.8);
                    }
                }
            }
        }
    }

    &__side-menu {
        visibility: hidden;
        opacity: 1;
        transform: translateX(15%);

        &__content {
            &__background {
                transform: translateX(100%);

                &:before {
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background-color: $primary;
                    background-color: #2E283D;
                    background-image: linear-gradient(172deg, $primary 32%, #5A2392 100%);
                    transform: translateX(0%) skew(10deg, 0deg) scaleX(1.5);
                    content: '';

                    @include media-breakpoint-up(sm) {
                        transform: translateX(0%) skew(10deg, 0deg) scaleX(1.8);
                    }

                    @include media-breakpoint-up(md) {
                        transform: translateX(0%) skew(10deg, 0deg) scaleX(1.3);
                    }
                }
            }

            &__search {
                padding: 1rem 4rem 1rem 0;
                border-width: 0 0 3px 0;
                border-style: solid;
                border-color: $white;

                &:hover {
                    &::placeholder {
                        color: rgba($white, .6);
                    }
                }

                &::placeholder {
                    color: rgba($white, .5);
                    transition: color .3s ease;
                }
            }

            &__search-content {
                display: none;
            }
        }

        &__col {
            opacity: 0;
            transform: translateY(10rem) rotate(-4deg);
            transform-origin: top;

            a {
                transition: opacity .3s ease;

                &:hover {
                    opacity: .7;
                }
            }

            &__location,
            &__language,
            &__login {
                &::before {
                    display: inline-block;
                    width: 1.8rem;
                    height: 1.8rem;
                    margin-right: .6rem;
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    background-position: center bottom;
                    content: '';
                }
            }

            &__location {
                &::before {
                    background-image: url('../vectors/icon-map-marker.svg');
                }
            }

            &__language{
                &::before{
                    background-image: url('../vectors/icon-globe-white.svg');
                }
            }

            &__login {
                &::before {
                    background-image: url('../vectors/icon-user.svg');
                }
            }
        }
    }

    .dropdown {
        > a:after {
            position: relative;
            display: flex;
            width: 1.1rem;
            height: 1.1rem;
            margin-left: 1rem;
            background-image: url('../vectors/icon-caret.svg');
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: 50% 53%;
            transition: transform .4s ease .05s;
            content: '';
        }

        &.show {
            > a:after {
                transform: rotate(-180deg);
            }
        }
    }

    .dropdown-menu {
        font-size: 1.4rem;
        border-radius: 0;
        z-index: 0;
        opacity: 0;
        visibility: hidden;
        transform: translateY(-$navbar-height-lg / 2);
        transition: all .4s ease;

        &.show {
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
            transition: opacity .1s linear, visibility .4s, transform .4s ease;
        }

        img {
            transition: transform .8s ease;

            &:hover {
                transform: scale(1.1);
            }
        }
        
        .col{
            max-width: 360px;
        }
    }

    &__side-menu__content .overflow-auto {
        padding-left: 1.5rem;
        padding-right: 1.5rem;

        @include media-breakpoint-up(md) {
            padding-left: 3rem;
            padding-right: 3rem;
        }
    }

}

.impersonitification-header{
    text-align: center;
    font-weight: 600;
    background-color: $color-impersonate--background;
    color: $color-impersonate--text;
    border: 1px solid $color-impersonate--border;

    padding: 1rem;

    p {
        margin: 0;
        padding: 0;
    }
}


.type-hint {
    display: none;
    visibility: hidden;
    opacity: 0;
    transform: translateY(100px);
}

.navbar__side-menu__main-search-container {
    @include media-breakpoint-down(md) {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
    }
}

.search-result {
    background-color: $white;
    transform: translate3d(0, 0, 0);

    @include media-breakpoint-down(md) {
        .embed-responsive {
            max-width: 14rem;
        }
    }

    @supports (backdrop-filter: blur(10px)) {
        backdrop-filter: blur(10px);
        background-color: rgba($white, .9);
    }
}

.search-reveal {
    opacity: 0;
    visibility: hidden;
    transform: translateY(2rem);
    .schedule__el__abstract{
        display: flex;
        flex-direction: column;
        a{
            margin-top: 1rem;
        }
    }
    a{
        color: $primary;
        font-weight: bold;
    }
}

.search-content__type {
    border-bottom: 2px solid rgba($white, .5);
}

.navbar__side-menu__content__search-content {
    opacity: 0;
    visibility: hidden;
}

.navbar__side-menu__btn-search {
    width: 3rem;
    height: 3rem;
    margin-top: -2rem;
    background-image: url('../vectors/icon-search.svg');
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 3rem;
    transition: transform .5s ease;

    &:hover {
        transform: rotate(-20deg)
    }
}

.dropdown-menu{
    > .container{
        > .row{
            > .col:nth-child(6) {
                display: block;
                @media (max-width: 1024px) {
                    display: none;
                }
            }
        }
    }
}

