// theme colors
$color-primary: #ED1C24 !default;
$color-secondary: #344AF0 !default;
$color-primary--dark: #2E283D !default;
$accent: #E2189C !default;
$light: #F5F5F5;
$white: #FFFFFF;
$green: #69C881;

$primary: $color-primary;
$secondary: $color-secondary;
$dark: $color-primary--dark;
$black: #222222;
$color-border: #D9D9D9;

// Smau focus
$color-focus__industry: #4891CD;
$color-focus__digital-trends: #29B473;
$color-focus__open-innovations: #684cc8;
$color-focus__mobile: #F6921E;
$color-focus__trade-tourism: #146E96;
$color-focus__international: #0F9EA5;
$color-focus__health: #ffd500;
$color-focus__ict: #f28e1e;
$color-focus__open-innovation: #006bdc;
$color-focus__food-retail: #be4c49;
$color-focus__smart-manufacturing: #bedb65;
$color-focus__energy-environment: #62cced;
$color-focus__smart-mobility: #2c388f;
$color-focus__e-health: #2ca377;
$color-focus__alternative: #ea899a;
$color-focus__energy: #adff2f;
$color-focus__mobility: #c3b8f9;
$color-focus__hr: #ffdead;
$color-focus__sustainability: #89f5a0;
$color-focus__artificial-intelligence: #173e87;
$color-focus__emerging-technologies: #ff7bac;

// Smau Impersonate Bar
$color-impersonate--background: $light;
$color-impersonate--text: #333;
$color-impersonate--border: #333;

// social colors
$facebook: #305991 !default;
$twitter: #25A8D2 !default;
$pinterest: #F40024 !default;
$instagram: #863BB3 !default;
$youtube: #CD201F !default;
$eventbrite: #F05537 !default;

// typography
$display-initial-size: 1.4rem;
$display5-size: 1.2rem;

// DOM elements
$navbar-height-lg: 7rem;
$navbar-height-sm: 6.2rem;
