@media (max-width: 540px) {
    #main-program .filters__heading--schedule.container {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (min-width: 541px) {
    #main-program .filters__heading--schedule.container:after {
        content: '';
        display: inline-block;
        width: 30px;
        position: absolute;
        right: 15px;
        top: 0;
        bottom: 0;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    }
}

#tag-filter-title {
    position: sticky;
    left: 0;
    background: #fff;
    padding: 12px 15px;
    margin-right: 15px;
    z-index: 100;
}
@media (min-width: 541px) {
    #tag-filter-title {
        padding-left: 0;
    }
}
#tag-filter-title:after {
    content: '';
    display: inline-block;
    width: 30px;
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
}