.academy > .slider{
  .slider__slide{
    @media(max-aspect-ratio: 16/7) and (min-width: 992px){
      max-height: unquote("max(67vh, 470px)");
    }
  }
  .slider--welcome__pagination{
    bottom: 6rem;
  }
  .swiper-pagination{
    @include media-breakpoint-up(lg){
      display: none;
    }
  }
  margin-bottom: 50px;
  .slide__content__container{
    bottom: 110px;
    position: absolute;
    @include media-breakpoint-up(md){
      position: static;
    }
  }
  .slider__slide::after{
    background-color: rgba(0, 0, 0, 0.4);
  }
  &.no-overlay .slider__slide::after {
    @include media-breakpoint-up(md){
      background-color: transparent;
    }
  }
  .slider__slide__content{
    .span,
    h1,
    p {
      @include media-breakpoint-up(md){
        max-width: 44.667%;
      }
    }
    @media (min-aspect-ratio: 19/9), (min-width: 1800px) {
      padding-bottom: 0;
    }
  }
  .slider__slide__content__label{
    margin-bottom: 30px;
    display: block;
  }
  .slider__slide__content__title.display-1{
    font-size: 3.4rem;
    line-height: 3.8rem;
    @include media-breakpoint-up(lg){
      font-size: 4.5rem;
      line-height: 5rem;
    }
  }
  .mobile-slide{
    display: block;
    @include media-breakpoint-up(md){
      display: none;
    }
  }
  .desktop-slide{
    display: none;
    @include media-breakpoint-up(md){
      display: block;
    }
  }
}

#slider-page{
  .slider__slide,
  .slider__slide__content,
  .desktop-slide{
    @media(max-aspect-ratio: 16/7) and (min-width: 992px){
      max-height: unquote("max(67vh, 470px)");
    }
  }
  section.welcome.welcome{
    margin-bottom: 50px;
  }
  .welcome__shape{
    width: 100%;
  }
  .slider {
    background-color: transparent;
  }
  .slider__slide__content {
    padding-bottom: 0;
    position: relative;
  }
  .slide__content__container{
    bottom: 110px;
    position: absolute;
    @include media-breakpoint-up(md){
      position: static;
    }
  }
  .slider__slide__content__{
    &label {
      color: rgba(46, 40, 61, 0.5);
      font-size: 1.2rem;
      margin-bottom: 15px;
      @include media-breakpoint-up(lg) {
        color: rgba(255, 255, 255, 0.5);
      }
      @include media-breakpoint-up(lg) {
        font-size: 1.4rem;
      }
    }
    &title{
      font-size: 3.4rem;
      @include media-breakpoint-up(lg) {
        font-size: 4.5rem;
      }
    }
    &paragraph{
      margin-top: 19px;
      font-size: 1.6rem;
      line-height: 2.2rem;
      max-width: none;
    }
  }
  .slider__slide__bottom{
    margin-top: 30px;
    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }
  .slider__slide {
    &::before {
      @media (min-width: 768px) and (max-height: 1113px) {
        padding-top: 0;
      }

      @media (min-aspect-ratio: 16/9) and (min-width: 1700px) {
        padding-top: 0;
      }
      @include media-breakpoint-down(md){
        padding-top: 0;
      }
    }
    &::after{
      content: none;
    }
  }
  .slider--welcome__pagination{
    display: block;
    @include media-breakpoint-down(md){
      bottom: 4rem;
    }
    @include media-breakpoint-up(md){
      display: none;
    }
  }
  .home-slide-button a:not(.x-btn){
    border-color: #000;
    color: #000;
    @include media-breakpoint-up(lg){
      border-color: #fff;
      color: #fff;
    }
  }
  @include media-breakpoint-down(lg) {
    .slider__slide__bottom {
      //margin-top: 30px;
    }
    .slider__slide__content__title {
      color: #2E283D;
    }
    .slider__slide__content__paragraph {
      color: #2E283D;
    }
  }
  @include media-breakpoint-up(lg) {
    .slider__slide__content__title,
    .slider__slide__content__paragraph{
      color: #fff;
    }
    .slider__slide__bottom{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .mobile-slide{
    display: block;
    @include media-breakpoint-up(lg){
      display: none;
    }
  }
  .desktop-slide{
    display: none;
    @include media-breakpoint-up(lg){
      display: block;
    }
  }
}