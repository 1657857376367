@mixin readmoreIcon{
  a {
    position: relative;
    padding-left: 24px;
    &::before,
    &::after{
      content: '';
      position: absolute;
      left: calc(14px / 2);
      top: 50%;
      height: 13px;
      width: 1px;
      border-right: solid 1px #0386A3;
      display: inline-block;
      transition: transform .3s ease;
    }
    &::before {
      transform: translateY(-50%) rotate(0deg);
    }
    &::after {
      transform: translateY(-50%) rotate(90deg);
    }
  }
}
.search-page{
  .top-items .item-topic{
    color: rgba(0,0,0,0.5);
  }
  form{
    position: relative;
  }
  .error-message{
    position: absolute;
    bottom: -20px;
    left: 0;
    font-size: 1.2rem;
    color: #FF0000;
    animation: reveal .3s ease-out;
  }
  @keyframes reveal {

    0% {
      transform: translateX(50px);
    }
    100% {
      transform: translateX(0);
    }
  }
  #academy_search_form {
    position: relative;
    .form__input{
      padding-right: 70px;
      border-bottom: solid 1px #000;
    }
    .form__input.invalid{
      border-bottom: solid 1px #FF0000;
    }
    #academy_search_form_query {
      all: unset;
      font-size: 1.8rem;
      font-weight: 700;
      padding-bottom: 15px;
      @include media-breakpoint-up(md) {
        font-size: 4.5rem;
      }

      &::selection {
        background-color: #0386a3;
        color: #fff;
      }
    }
    button[type="reset"],
    button[type="submit"]{
      all: unset;
      cursor: pointer;
      position: relative;
      padding: 1rem 20px !important;
      &::before,
      &::after{
        content: '';
        position: absolute;
        right: 0;
        top: 2px;
        width: 25px;
        height: 2px;
        background-color: #000;
        transition: transform .1s ease;
      }
      &:hover,
      &:focus{
        &::before{
          transform: rotate(-45deg) scale(1.15);
        }
        &::after{
          transform: rotate(45deg) scale(1.15);
        }
      }
      &::before,
      &:active::before{
        transform: rotate(-45deg) scale(1);
      }
      &::after,
      &:active::after{
        transform: rotate(45deg) scale(1);
      }
      span{
        display: block;
        overflow: hidden;
        width: 0;
        height: 0;
        clip: rect(0, 0, 0, 0);
        position: absolute;
      }
    }
    button[type="submit"]{
      top: -7px;
      transform-origin: 50px;
      transition: transform .1s ease;
      &::before{
        width: 16px;
        height: 16px;
        border: solid 2px #000;
        border-radius: 25px;
        background-color: transparent;
        right: 5px;
        top: 0;
      }
      &::after{
        right: 0;
        top: 16px;
        width: 10px;
      }
      &:hover,
      &:focus{
        transform: scale(1.15);
        &::before{
          transform: rotate(-45deg) scale(1);
        }
        &::after{
          transform: rotate(45deg) scale(1);
        }
      }
    }
    .form-group + div,
    .form-group + div + div{
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
    .form-group + div{
      right: 40px;
    }
  }
  .search-form-container{
    position: relative;
    &.has-no-input{
      .animation-container .search-hint{
        transform: translateX(0px);
        opacity: 1;
      }
    }
  }
  .animation-container {
    position: absolute;
    top: 50%;
    width: calc(100% - 70px);
    overflow: hidden;
    pointer-events: none;
    transform: translateY(calc(-50% - 8px));
    @include media-breakpoint-up(md) {
      transform: translateY(-50%);
    }
    .search-hint {
      color: rgba(0, 0, 0, 0.5);
      font-weight: 700;
      font-size: 1.8rem;
      margin-bottom: 0;
      transform: translateX(500px);
      opacity: 0;
      transition: transform .8s ease-out, opacity .8s ease-out;
      white-space: nowrap;
      @include media-breakpoint-up(md){
        font-size: 3.5rem;
      }
    }
  }
  .search-filters{
    margin-top: 60px;
    display: flex;
    gap: 8.5px;
    white-space: nowrap;
    overflow: auto;
    padding: 0 30px 15px 30px;
    margin-left: -30px;
    width: calc(100% + 60px);
    & + .video-list{
      margin-top: 35px;
    }
    @include media-breakpoint-up(md){
      margin-top: 100px;
      width: 100%;
      margin-left: 0;
      padding: 0;
    }
    button[data-filter-id]{
      --filterBgColor: #eff2f3;
      --filterTextColor: #000;
      --filterCounterBgColor: #000;
      --filterCounterColor: #eff2f3;
      --filterBorderColor: #000;
      border-radius: 21px;
      height: 42px;
      display: flex;
      align-items: center;
      padding: 0 15px;
      font-size: 1.2rem;
      font-weight: 700;
      border-style: solid;
      color: var(--filterTextColor);
      background-color: var(--filterBgColor);
      border-color: var(--filterBorderColor);
      transition: color .1s ease, background-color .1s ease, border-color .1s ease;
      &:hover{
        --filterBgColor: #000;
        --filterTextColor: #eff2f3;
        --filterCounterBgColor: #eff2f3;
        --filterCounterColor: #000;
        --filterBorderColor: #000;
      }
      &:active{
        --filterBgColor: #54b7d6;
        --filterTextColor: #fff;
        --filterCounterBgColor: #fff;
        --filterCounterColor: #54b7d6;
        --filterBorderColor: #0386A3;
      }
      &.selected{
        --filterBgColor: #0386A3;
        --filterTextColor: #fff;
        --filterCounterBgColor: #fff;
        --filterCounterColor: #0386A3;
        --filterBorderColor: #0386A3;
      }
      .counter-container{
        margin-left: 5px;
        height: 16px;
        display: flex;
        padding: 0 5px;
        align-items: center;
        border-radius: 10px;
        background-color: var(--filterCounterBgColor);
        color: var(--filterCounterColor);
        transition: color .1s ease, background-color .1s ease;
      }
    }
  }
  .video-list{
    padding: 0;
  }
  .video-list-grid{
    grid-template-columns: 100%;
    .video-list-item {
      display: flex;
      padding: 30px;
      gap: 30px;
      height: 350px;
      @include media-breakpoint-up(md){
        height: 285px;
      }
      &:has(.item-speaker),
      &:not(:has(.item-speaker)){
        padding-bottom: 30px;
      }
      &.filtered {
        display: none;
      }
      &.disabled{
        background-color: #e7e7e7;
        a{
          pointer-events: none;
        }
        .item-link{
          display: none;
        }
      }
    }
    .item-content{
      padding-bottom: 23px;
    }
    .item-image{
      width: 227px;
      height: 227px;
      border: solid 1px rgba(46, 40, 61, 0.2);
      flex: 0 0 auto;
      display: none;
      @include media-breakpoint-up(md){
        display: block;
      }
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .item-label{
      margin-top: 0;
    }
    .item-title{
      margin-top: 0;
      padding-left: 0;
      color: #000;
      padding: 0 0 1px;
      margin-bottom: 10px;
      font-size: 2rem;
      line-height: 2.4rem;
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      @include media-breakpoint-up(md){
        line-height: 3.1rem;
        font-size: 2.6rem;
      }
    }
    .item-description{
      margin-top: 5px;
      font-size: 1.5rem;
      line-height: 2.2rem;
      font-weight: 400;
      display: -webkit-box;
      -webkit-line-clamp: 8;
      -webkit-box-orient: vertical;
      overflow: hidden;
      @include media-breakpoint-up(md){
        -webkit-line-clamp: 4;
        font-size: 1.8rem;
        line-height: 2.6rem;
        margin-top: 15px;
      }
    }
    .item-link{
      position: absolute;
      bottom: 30px;
      @include readmoreIcon();
      a{
        font-size: 1.6rem;
        font-weight: 400;
        transition: color .2s ease-out;
        &::before,
        &::after{
          transition: border-color .2s ease-out;
        }
        &:hover{
          color: #54b7d6;
          &::before,
          &::after{
            border-color: #54b7d6;
          }
        }
      }
    }
  }
}