.floating-notification {
    width: 100%;
    max-width: 45rem;
    opacity: 0;
    visibility: hidden;
    transform: translateX(0) translateY(100%);

    &--revealed {
        .floating-notification__progress__current {
            animation: notificationProgress 6s linear;
        }
    }

    &:before {
        display: block;
        position: absolute;
        top: 3rem;
        left: 1.5rem;
        width: 3rem;
        height: 3rem;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 2rem;
        content: '';
    }

    &__progress {
        width: 100%;
        height: .4rem;
        background-color: rgba($dark, .1);

        &__current {
            height: .4rem;
            background-color: $dark;
            transform-origin: left;
        }
    }

    &__body {
        padding-left: 6rem;
        padding-right: 2rem;
    }

    &--success {
        .floating-notification__progress__current {
            background-color: $green;
        }

        &:before {
            background-image: url('../vectors/icon-check-color.svg');
        }
    }

    &--warning {
        .floating-notification__progress__current {
            background-color: orange;
        }

        &:before {
            background-image: url('../vectors/icon-alert-color.svg');
        }
    }

    &--error {
        .floating-notification__progress__current {
            background-color: $primary;
        }

        &:before {
            background-image: url('../vectors/icon-error-color.svg');
        }
    }
}
