.loader {
    width: 4rem;
    height: 4rem;
    transition: all 1s ease;
    transform: translateX(-50%) scale(1);

    svg {
        max-width: 4rem;
        animation: 2s linear infinite svg-animation;
    }

    circle {
        display: block;
        fill: transparent;
        /*        stroke: $primary;*/
        stroke-linecap: square;
        stroke-dasharray: 283;
        stroke-dashoffset: 280;
        stroke-width: 10px;
        transform-origin: 50% 50%;
        animation: 1.4s ease-in-out infinite both circle-animation;
    }

    &--hided {
        opacity: 0;
        z-index: -1;
        transform: translateX(-50%) scale(.2);
    }
}

.navbar__side-menu__main-search-container {
    .loader {
        top: 25rem;
    }
}
