.video-container{
    margin-top: 25px;
    margin-bottom: 30px;
    width: 100%;
    aspect-ratio: 16 / 9;
    background-color: #000;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    .spinner{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: none;
    }
    hx\:include:empty + .spinner{
        display: block;
        @include spinner;
    }
}
.vote-widget {
    display: flex;
    flex-wrap: wrap;
    margin-bottom:  30px;
    padding-bottom: 10px;
    width: 250px;
@include media-breakpoint-up(md) {
    border-bottom: dashed 1px #b6b7be;
    padding-bottom: 30px;
    width: 100%;
}
    p,
    .form-group{
        margin-bottom: 0;
    }
    .vote-title{
        margin-left: 0;
        font-weight: 700;
        margin-right: 8px;
    @include media-breakpoint-up(md){
        margin-right: 20px;
    }
    }
    span{
        font-size: 1.6rem;
    @include media-breakpoint-up(md) {
        margin-left: 10px;
    }
    }
    #video_vote_create_form_votes{
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        gap: 2px;
    }
    input[type="radio"] {
        position: relative;
        width: 21px;
        height: 21px;
        appearance: none;
        display: block;
        p{
            width: 100%;
            height: 100%;
            display: block;
        }
        &::before,
        &::after{
            top: 0;
            left: 0;
            overflow: visible;
            border: none;
            width: 21px;
            height: 21px;
            background-color: transparent;
            background-repeat: no-repeat;
        }
        &::before{
            background-image: url("../vectors/ico-star-empty.svg");
        }
        &::after{
            background-image: url("../vectors/ico-star-full.svg");
            background-color: transparent;
            transform: none;
            opacity: 0;
            transition: opacity .3s ease;
        }
        &:checked,
        &:checked ~ input,
        &:hover{
            &::after{
                opacity: 1;
            }
        }
    }
    p:has(input[type="radio"]:checked){
        & ~ p > input{
            &::after{
                opacity: 1;
            }
        }
    }
    &:has(p > input[type="radio"]:hover){
        p > input:not(:hover)::after{
            opacity: 0;
        }
        p:has(input[type="radio"]:hover) ~ p > input{
            &::after{
                opacity: 1;
            }
        }
    }
    label{
        display: none;
    }
}

// details bottom
.bottom-items{
    //margin-top: 50px;
    display: flex;
    //@include media-breakpoint-up(md) {
    //    margin-top: 70px;
    //}
    //.logged-in &{
    //    margin-top: 30px;
    //}
    .smau-social-share {
        display: none;
        @include media-breakpoint-up(md) {
            display: block;
        }
        .jssocials-shares {
            display: flex;
            flex-direction: column;
            padding-right: 65px;
            font-size: 1.43em;
            margin: 0;
            gap: 10px;

            .jssocials-share {
                margin-top: 0;
                margin-bottom: 0;

                .jssocials-share-link {
                    display: block;
                }
            }
        }
    }
    .event-title{
        font-weight: 700;
        font-size: 2rem;
        @include media-breakpoint-up(md) {
            font-size: 2.6rem;
        }
    }
    .event-content{
        font-size: 1.6rem;
        margin: 24px 0 30px;
        p{
            margin-bottom: 0;
        }
    }
    .item-detail-speaker{
        border-top: dashed 1px #B6B7BE;
        border-bottom: dashed 1px #B6B7BE;
        padding: 30px 0;
        margin-bottom: 35px;
        .item-speaker-inner{
            display: flex;
            gap: 17px;
            flex-direction: column;
            @include media-breakpoint-up(md){
                flex-direction: row;
            }
            .speaker-img{
                width: 78px;
                height: 78px;
                border-radius: 100px;
                overflow: hidden;
                flex: 0 0 auto;
                &.placeholder{
                    border: solid 1px #c9c9c9;
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .speaker-name{
                font-size: 2.2rem;
                font-weight: 700;
                margin-bottom: 12px;
            }
            .speaker-bio{
                font-size: 1.6rem;
                p{
                    margin-bottom: 2rem;
                }
            }
        }
    }
}

main.video .white-card {
    margin-bottom: 50px !important;
    @include media-breakpoint-up(md) {
        margin-bottom: 70px !important;
    }
}