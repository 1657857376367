body {
    #pc-cookie-notice {
        background-image: linear-gradient(16deg, #ed1c24 8%, #5a2392);
        padding: 12px 0 12px;
        font-size: 14px;
        font-family: $font-family-sans-serif;

        @include media-breakpoint-up(lg) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        #pc-message {
            width: auto;
            float: left;

            @media (max-width: 768px) {
                float: none;
                padding: 0 25px 0 25px;
                text-align: center;
            }
        }

        #pc-button {
            position: relative;
            float: right;
            margin-right: 13px;
            top: auto;
            right: auto;

            @media (max-width: 768px) {
                float: none;
                padding: 8px 25px 0 25px;
                text-align: center;
            }

            button {
                padding: .5rem 5rem;
                background-color: transparent;
                font-size: 14px;
                color: $white;
                font-family: $font-family-sans-serif;
                border: 2px solid $white;
                border-radius: 2rem;
                box-shadow: 0 1px 5px rgba(46, 40, 61, .08);
                transition: all .4s ease;

                &:hover {
                    background-color: $white;
                    color: $dark;

                    @supports (backdrop-filter: blur(10px)) {
                        background-color: rgba($white, .95);
                        backdrop-filter: blur(10px);
                    }
                }
            }
        }
    }
}
