main.login{
  .top-items{
    opacity: 0;
    transition: 1s ease;
  }
  body.bg-polygon-ready &,
  body.bg-polygon-error &{
    .top-items{
      opacity: 1;
    }
  }
  body.bg-polygon-error & {
    .bg-polygon{
      opacity: 0;
    }
    .top-items {
      .item-title,
      .item-content,
      .item-speaker {
        color: #000;
      }

      .item-speaker::before {
        background-image: url("../vectors/ico-user-academy.svg");
      }
    }
  }
}

body.page.academy main.login{
  padding-bottom: 100px;
  .before-social{
    margin-bottom: 20px;
    text-align: center;
    font-size: 1.4rem;
    overflow: hidden;
  }
  .collapse-link{
    margin-top: 20px;
    text-align: center;
    font-size: 1.6rem;
    overflow: hidden;
    transition: margin-top .2s ease, height .2s ease;
    .signup-string{
      transition: transform .5s ease, opacity .5s ease;
      transform: translateX(0);
    }
    .login-string{
      display: block;
      transition: transform .5s ease, opacity .5s ease;
      transform: translateX(0);
      opacity: 1;
      button {
        position: relative;
      }
    }
    button {
      all: unset;
      color: #0386a3;
      text-decoration: underline;
      font-weight: 400;
      cursor: pointer;
    }
  }
  .smau-academy-login-collapse {
    .form-group:first-child {
      margin-top: 0;
    }
    & > h3 + form .form-group:first-child {
      margin-top: 25px;
    }
  }

  .smau-academy-login-collapse:not(.show) + .collapse-link {
    margin-top: 0;
    height: 0;
    .signup-string {
      opacity: 0;
      transform: translateX(-120px);
      pointer-events: none;
    }
  }
  .smau-academy-login-collapse.show + .collapse-link .login-string{
    opacity: 0;
    transform: translateX(120px);
    pointer-events: none;
  }
}