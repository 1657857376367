.breadcrumb {
    background: none;
    padding-left: 0;
    .breadcrumb-item{
        &.active{
            color: #fff;
            opacity: .8;
            &.text-black{
                color: #000;
                opacity: .8;
            }
        }
    }
}
