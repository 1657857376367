.ajax-form,
.form{
  .form__input--submit{
    transition: opacity .2s ease, color .2s ease, background-color .2s ease;
  }
}
.form-btn{
  background-image: none;
  background-color: #0386A3;
  border-radius: 5px;
  font-size: 1.6rem;
  color: #fff;
  width: 100%;
  font-weight: 700;
  opacity: 1;
  transition: opacity .2s ease, color .2s ease, background-color .2s ease;
  @include media-breakpoint-up(md){
    font-size: 1.8rem;
  }
  &:hover,
  &:active,
  &:focus{
    opacity: .7;
  }
  &.secondary{
    background-color: #fff;
    color: #0386A3;
    border: solid 1px #0386A3;
    text-decoration: none;
    transition: opacity .2s ease, color .2s ease, background-color .2s ease;
    &:hover,
    &:active,
    &:focus{
      opacity: .7;
      color: #fff;
      background-color: #0386A3;
    }
  }
}
.social-btn{
  position: relative;
  & + .social-btn{
    margin-top: 1.5rem;
  }
  &::before{
    content: '';
    display: inline-block;
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 17px;
    height: 17px;
    background-repeat: no-repeat;
    background-size: contain;
    @include media-breakpoint-up(md){
      width: 27px;
      height: 27px;
    }
  }
  &.google::before{
    background-image: url("../vectors/ico-button-google.svg");
  }
  &.facebook::before{
    background-image: url("../vectors/ico-button-facebook.svg");
  }
  &.linkedin::before{
    background-image: url("../vectors/ico-button-linkedin.svg");
  }
}
.form,
.ajax-form{
  .form__input,
  .formbuilder-row .form-group{
    input{
      border: solid 1px rgba(#2E283D, .3);
      border-radius: 5px;
      padding: 10px 15px;
      &:focus{
        border-color: #0386A3;
      }
    }
  }
  .form__input--submit{
    background-image: none;
  }
}
.custom-control.custom-checkbox,
.form-check{
  label {
    padding-left: 2.6rem;
    line-height: 1.8rem;
    &::before{
      width: 1.4rem;
      height: 1.4rem;
      border: solid 1px #2E283D;
      background-color: #fff;
    }
    &::after{
      width: 5px;
      height: 10px;
      left: 5px;
    }
  }
  input[type="checkbox"]{
    &:checked + label::before{
      background-color: #0386A3;
      border-color: #0386A3;
    }
  }
}
.item-form{
  .inner-container{
    max-width: 350px;
    margin: auto;
  }
  .form-title{
    font-size: 2rem;
    color: #2E283D;
    font-weight: 700;
    text-align: center;
    margin-bottom: 6px;
    @include media-breakpoint-up(md){
      font-size: 2.6rem;
    }
  }
  .form-group{
    margin-top: 25px;
  }
  label{
    font-size: 1.5rem;
    font-weight: 400;
  }
}