main.profile{
  .top-items{
    opacity: 0;
    transition: 1s ease;
  }
  body.bg-polygon-ready &,
  body.bg-polygon-error &{
    .top-items{
      opacity: 1;
    }
  }
  body.bg-polygon-error & {
    .bg-polygon{
      opacity: 0;
    }
    .top-items {
      .item-title,
      .item-content,
      .item-speaker {
        color: #000;
      }

      .item-speaker::before {
        background-image: url("../vectors/ico-user-academy.svg");
      }
    }
  }
}
body.page.academy .profile .white-card{
  margin-bottom: 100px;
  padding: 40px 20px;
  @include media-breakpoint-up(md) {
    padding: 40px 30px;
  }
  .video-list .video-list-grid+.load-more{
    margin-top: 50px;
  }
}