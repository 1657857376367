.snam-container,
#slider-page > .top-items{
  width: 100%;
  max-width: 790px;
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;
}
.item-snam{
  &:first-child{
    margin-top: 50px;
  }
  &:last-child{
    margin-bottom: 50px;
  }
  .item-upper{
    background-color: rgba(46, 40, 61, 0.2);
    border-radius: 10px;
    padding: 20px;
    @include media-breakpoint-up(md) {
      padding: 30px;
    }
    .item-title-container{
      display: flex;
      gap: 15px;
    }
    .item-counter{
      font-weight: 700;
      background-color: var(--counterColor, #EBA83A);
      color: var(--textColor, #fff);
      aspect-ratio: 1/1;
      padding: 8px;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2.2rem;
      height: 39.5px;
      @include media-breakpoint-up(md){
        font-size: 2.6rem;
        height: 46.5px;
      }
    }
    .item-title{
      color: var(--textColor, #fff);
      font-weight: 700;
      font-size: 2rem;
      @include media-breakpoint-up(md) {
        font-size: 2.6rem;
      }
    }
    .item-content{
      margin-top: 20px;
      color: var(--textColor, #fff);
      font-size: 1.6rem;
      @include media-breakpoint-up(md){
        font-size: 1.9rem;
      }
    }
  }
  .item-video{
    margin-top: 20px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #d8d8d8;
    img{
      width: 100%;
      object-fit: cover;
    }
  }
  .item-lower{
    display: flex;
    margin-top: 20px;
    padding-bottom: 50px;
    gap: 30px;
    @include media-breakpoint-up(md) {
      gap: 75px;
    }
    .item-speaker{
      @include speaker-light-ico;
      color: var(--textColor, #fff);
    }
    .item-minutes{
      color: var(--textColor, #fff);
      position: relative;
      padding-left: 25px;
      &::before{
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-image: url("../vectors/ico-clock.svg");
        background-repeat: no-repeat;
        width: 15px;
        height: 15px;
      }
    }
  }
}