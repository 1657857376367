::selection {
    background: #f5f5f5;
}

::-moz-selection {
    background: #f5f5f5;
}

img::selection {
    background: transparent;
}

img::-moz-selection {
    background: transparent;
}

select:-moz-focusring,
option:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;

    &:active, &:focus {
        color: transparent;
        text-shadow: 0 0 0 #000
    }
}

html {
    min-height: 100%;
    font-size: 10px;
}

body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    font-family: $font-family-sans-serif;
    font-size: $display-initial-size;
    color: $dark;
    background-color: $light;
}

main {
    flex: 1 0 auto;
}

.vh {
    &-50 {
        height: 50vh;
    }

    &-50-min {
        min-height: 50vh;
    }

    &-100 {
        height: 100vh;
    }

    &-100-min {
        min-height: 100vh;
    }
}

.top {
    top: 0;

    &-25 {
        top: 25%;
    }

    &-50 {
        top: 50%;
    }
}

.left {
    left: 0;

    &-50 {
        left: 50%;
    }
}

.bottom {
    bottom: 0;
}

.right {
    right: 0;
}

.t-50 {
    transform: translate(-50%, -50%);
}

.tx-50 {
    transform: translateX(-50%);
}

.ty-50 {
    transform: translateY(-50%);
}

.z {
    &-1 {
        z-index: 1;
    }

    &-2 {
        z-index: 2;
    }

    &-3 {
        z-index: 3;
    }

    &-4 {
        z-index: 4;
    }

    &-5 {
        z-index: 5;
    }
}

.fit {
    &-cover {
        object-fit: cover;
        object-position: center;
    }

    &-contain {
        object-fit: contain;
        object-position: center;
    }
}

.o-hidden {
    overflow: hidden;
}

.column-2 {
    @include media-breakpoint-up(md) {
        column-count: 2;
        column-gap: 0;
    }
}

.delay {
    &-1 {
        transition-delay: .1s !important;;
    }

    &-2 {
        transition-delay: .2s !important;;
    }

    &-3 {
        transition-delay: .3s !important;;
    }

    &-4 {
        transition-delay: .4s !important;;
    }

    &-5 {
        transition-delay: .5s !important;;
    }

    &-6 {
        transition-delay: .6s !important;;
    }

    &-7 {
        transition-delay: .7s !important;;
    }

    &-8 {
        transition-delay: .8s !important;;
    }

    &-9 {
        transition-delay: .9s !important;
    }

    &-10 {
        transition-delay: 1s !important;;
    }
}

.outline-none {
    outline: none;
    box-shadow: none;

    &:hover, &:active, &:focus {
        outline: none;
    }
}

.divider {
    display: block;
    width: 100%;
    height: 2px;
}

.img-cover {
    background-size: cover;
    background-repeat: no-repeat;
}

.pt-navbar {
    padding-top: $navbar-height-lg;

    @include media-breakpoint-up(md) {
        padding-top: $navbar-height-lg;
    }
}

.border-lg-right {
    @include media-breakpoint-up(lg) {
        border-right: 1px solid $color-border;
    }
}

.mt-navbar {
    margin-top: $navbar-height-sm;

    @include media-breakpoint-up(sm) {
        margin-top: $navbar-height-lg;
    }
}

.top-navbar {
    top: $navbar-height-sm;

    @include media-breakpoint-up(sm) {
        top: $navbar-height-lg;
    }
}

.icon-social {
    max-width: 2.5rem;
    max-height: 2rem;
    vertical-align: sub;
    margin-right: 0.8rem;
    &.icon-podcast{
        transform: scale(1.5);
    }
}

.reveal {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 1s linear, transform 1s cubic-bezier(.4, .44, .27, 1.01);

    &.not-revealed {
        opacity: 0;
        transform: translateY(10%);
    }
}

.has-right-arrow {
    transition: opacity .4s ease;

    &:after {
        display: inline-block;
        width: 2.2rem;
        height: 1rem;
        margin-left: 1rem;
        background-image: url('../vectors/icon-arrow-right-dark.svg');
        background-repeat: no-repeat;
        background-size: 100%;
        transition: transform .4s ease;
        content: '';
    }

    &:hover {
        opacity: .8;

        &:after {
            transform: translateX(10px);
        }
    }
}
.has-live-show-icon {
    @include media-breakpoint-up(lg) {
        padding-left: 3rem;
    }

    &[data-live-show="true"]{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
        padding-left: 0;
        @media(min-width: 992px){
            justify-content: flex-end;
        }
    }

    &::before {
        display: inline-block;
        width: 5.6rem;
        height: 4rem;
        margin-right: .8rem;
        vertical-align: middle;
        background-image: url('../vectors/icon-live-show-new.svg');
        background-repeat: no-repeat;
        background-size: 4.5rem;
        background-position: left center;
        content: '';
    }
}

.has-speaker-icon,
.has-time-icon,
.has-message-icon {
    &:before {
        display: inline-block;
        width: 3.5rem;
        height: 3.5rem;
        background-repeat: no-repeat;
        background-position: center;
        content: '';
    }
}


.has-speaker-icon {
    &:before {
        background-image: url("../vectors/icon-mic.svg");
        background-size: 2rem 2.8rem;
    }
}

.has-time-icon {
    &:before {
        background-image: url("../vectors/icon-time.svg");
        background-size: 2rem 2.4rem;
    }
}

.has-message-icon {
    &:before {
        background-image: url("../vectors/icon-message.svg");
        background-size: 2rem 2.8rem;
    }
}

.has-pin-icon {
    &:before {
        display: inline-block;
        width: 2rem;
        height: 2.5rem;
        margin-right: .8rem;
        vertical-align: middle;
        background-image: url('../vectors/icon-map-marker-dark.svg');
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-position: left center;
        content: '';
    }
}

.has-bookmark-saved-icon {
    padding-left: 5rem;

    &:before {
        position: absolute;
        display: inline-block;
        width: 2rem;
        height: 2.5rem;
        top: .5rem;
        left: 2.2rem;
        background-image: url('../vectors/icon-bookmark-saved-color.svg');
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-position: left center;
        content: '';
    }
}

.filters__heading {
    top: $navbar-height-lg;
    background-color: $white;
    border-bottom: 1px solid $color-border;

    @include media-breakpoint-up(md) {
        > div {
            //padding: 0 0 0 1rem;
            padding: 0;
        }
    }

    @supports (backdrop-filter: blur(10px)) {
        backdrop-filter: blur(10px);
        background-color: rgba($white, .9);
    }

    .nav-item.active {
        &:after {
            display: block;
            width: 130%;
            height: .5rem;
            margin-top: 1rem;
            margin-left: -15%;
            background-color: $primary;
            content: '';
        }
    }

    &--schedule {
        @include media-breakpoint-down(sm) {
            top: 1rem;
        }
    }
}

.schedule {
    min-height: 70vh;

    &__day__time-range {
        &--hided {
            display: none !important;
        }

        &__indicator {
            position: sticky;
            top: 11.7rem;
            background-color: rgba($white, .9);
            backdrop-filter: blur(10px);
            margin: auto;
            text-align: center;

            @include media-breakpoint-up(md) {
                position: relative;
                top: 0;
                text-align: left;
            }

            &__time {
                border-bottom: 1px solid $color-border;

                @include media-breakpoint-up(md) {
                    position: sticky;
                    top: 25rem;
                    border-bottom: none;
                }

                &__hour {
                    font-size: $display4-size;
                    font-weight: 300;
                    line-height: 1.2;

                    @include media-breakpoint-up(md) {
                        font-size: $display4-size;
                    }

                    @include media-breakpoint-up(lg) {
                        font-size: $display3-size;
                    }
                }

                &__day {
                    font-size: 2.8vw;

                    @include media-breakpoint-up(md) {
                        font-size: unset;
                    }
                }
            }
        }
    }

    &__el {
        &__areas,
        &__details {
            font-size: $display5-size;
        }

        &__abstract {
            max-width: 55rem;
            a{
                color: $primary;
                font-weight: bold;
            }
        }
    }

    &-main-el {
        transition: all 1s ease;

        &--faded {
            transform: translateY(-10px);
            opacity: 0;
        }
    }
}

.welcome--participant {
    &:before {
        position: absolute;
        display: block;
        width: 50%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: url('../vectors/shape-cutted-rectangle-red.svg');
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: top left;
        content: '';

        @include media-breakpoint-up(md) {
            width: 44%;
            height: 40rem;
            background-size: cover;
            background-position: right bottom;
        }

        @include media-breakpoint-up(xl) {
            height: 45rem;
        }

        @include media-breakpoint-up(xxl) {
            height: 30vw
        }
    }

    &__avatar {
        &:after {
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: $secondary;
            border-radius: 1.5rem;
            z-index: 0;
            transform: translate(-7%, -5%);
            box-shadow: 1rem 0 3rem rgba($dark, .5);
            content: '';

            @include media-breakpoint-up(xs) {
                border-radius: 2rem;
            }

            @include media-breakpoint-up(md) {
                border-radius: 3rem;
            }
        }

        &__img {
            border-radius: 1.5rem;
            z-index: 1;
            box-shadow: 1rem 0 3rem rgba($dark, .5);

            @include media-breakpoint-up(sm) {
                border-radius: 2rem;
            }

            @include media-breakpoint-up(md) {
                border-radius: 3rem;
            }
        }
    }

    &__main-btn {
        border-width: 2px;
    }
}

.has-left-border {
    padding-left: 1.5rem;
    border-left: 4px solid rgba($dark, .15);
}

.participant__article {
    &:nth-child(odd) {
        background-color: rgba($dark, .035);
    }

    &__title {
        font-size: $display4-size;

        @include media-breakpoint-up(md) {
            font-size: $display3-size;
        }
    }
}

/* WIDGET */
.widget-button {
    button,
    a {
        display: table;
        color: $primary;
        font-weight: 700;
        text-decoration: none!important;
        padding: 1rem 1.5rem;
        background-color: $white;
        font-size: 1.2rem;
        border: 2px solid $primary;
        border-radius: 2rem;
        transition: all .3s ease;

        &:hover {
            background-color: $primary;
            color: $white;
            text-decoration: none;
        }
    }
}
.home-slide-button {
    a {
        &:not(.x-btn){
            display: inline-block;
            color: $white;
            font-weight: 700;
            text-decoration: none;
            padding: 1rem 1.5rem;
            background-color: transparent;
            font-size: 1.2rem;
            border: 2px solid $white;
            border-radius: 2rem;
            transition: all .3s ease;
            margin: 1em 0;
            min-width: 180px;
            text-align: center;

            &:hover {
                background-color: $white;
                color: $black;
                text-decoration: none;
            }
        }
    }
}
.widget-close-button {
    cursor: pointer;
}

.modal {
    &:after {
        content: '';
        display: block;
        background-color: rgba($dark, .5);
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: -1;
    }

    i {
        cursor: pointer;
    }
}

.schedule__item {
    display: none;
    min-height: 20rem;
    transition: background .5s ease;
    animation: none;
    border-top: 1px solid $border-color;

    &:first-child {
        border-top: 0;
    }

    &--visible {
        display: block;
    }

    &__container {
        opacity: 1;
        transform-origin: bottom;
        transition: transform .8s cubic-bezier(.06, .61, .37, 1.26), opacity 1s ease;

        .icon-alert {
            width: 1.5rem;
            vertical-align: middle;
        }

        .btn {
            min-width: 16.5rem;
        }

        .btn--confirmed__text--subscribed:before {
            background-size: 1.8rem;
        }

        .btn--confirmed__text--unsubscribe:before {
            background-size: 1.2rem;
        }

        .category-block{
            font-size: 1.2rem;
            > span{
                &:not(:first-child){
                    margin-left: 0.6rem;
                }
            }
        }
    }

    &.not-revealed {
        background-color: $white;

        &:before,
        &:after {
            position: relative;
            display: block;
            margin: 0;
            background-image: linear-gradient(-45deg, $white, $light, $light, $white);
            background-size: 400% 400%;
            animation: gradientPlaceholder 4s ease infinite;
            content: '';

            @include media-breakpoint-up(lg) {
                margin: 0 10%;
            }
        }

        &:before {
            width: 100%;
            height: 6rem;

            @include media-breakpoint-up(lg) {
                width: 65%;
            }
        }

        &:after {
            margin-top: 2rem;
            width: calc(100% - 30px);
            height: 12rem;
            animation-direction: reverse;

            @include media-breakpoint-up(lg) {
                width: 90%;
            }
        }

        .schedule__item__container {
            opacity: 0;
            transform: scale(.95);
        }
    }
}

.live-show-indicator {
    display: none;

    &[data-live-show="true"] {
        display: flex;
    }
}

.product__expand-description {
    transition: height .7s ease;

    &--not-expanded {
        height: 17rem;
    }
}

.rating {
    &__dot {
        width: 1rem;
        height: 1rem;
        border-radius: 100%;
        margin-left: .5rem;
    }
}

.welcome--participant__location {
    @include media-breakpoint-up(lg) {
        padding-left: 3rem;
    }

    &.has-pin-icon:before {
        display: inline-block;
        @include media-breakpoint-up(lg) {
            position: absolute;
            top: .1rem;
            left: 0;
        }
    }
}

.floating-agenda {
    background-color: rgba($dark, .3);
    opacity: 0;
    visibility: hidden;

    &__panel {
        margin: 3rem;
        max-width: 60rem;
        border-radius: 1rem;
        transform: translate(2rem, 2rem);

        @include media-breakpoint-up(md) {
            margin: 5rem;
        }

        &__events {
            max-height: 70vh;
            overflow: auto;

            @include media-breakpoint-up(md) {
                max-height: 60rem;
            }
        }

        &__background {
            border-radius: 50%;
            transform-origin: bottom right;
            transform: translate(50%, 50%) scale(0);
        }

        &__content {
            opacity: 0;
            transform-origin: bottom;
            transform: translateY(10px) scale(.97);
        }
    }

    &__filters {
        top: 0;
    }

    &__day-heading {
        border-bottom: 1px solid $border-color;
    }

    &__list {
        li {
            border-top: 1px solid $border-color;

            &:first-child {
                border-top: none;
            }
        }
    }

    .filters__heading {
        top: 0;
    }
}

iframe.speaker-slide {
    width: 787px;
    height: 466px;
    @include media-breakpoint-down(md) {
        width: 100%;
        height: 100%;
    }
}

.no-result {
    display: none;

    &--visible {
        display: block;
    }
}

.jssocials-shares {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .jssocials-share {
        margin-right: 0;

        .jssocials-share-link {
            span.jssocials-share-label {
                display: none;
            }
        }
    }
}

.w-xs-100 {
    @include media-breakpoint-down(sm) {
        width: 100%;
    }
}

.close-button {
    cursor: pointer;
}

.hide-block {
    opacity: 0;
}

.heading {
    &--white {
        h1 {
            font-size: 4.5rem;
            color: #2e283d !important;
            font-weight: 700 !important;
            line-height: 1.2;
            @include media-breakpoint-down(sm) {
                font-size: calc(1.575rem + 3.9vw);
            }

            strong {
                font-weight: 700 !important;
                background: linear-gradient(165deg, #ed1c24 38%, #e2189c);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                @include media-breakpoint-down(sm) {
                    display: inline-block;
                }
            }
        }

        p {
            margin-top: 1rem !important;
            color: #2e283d !important;
            font-size: 1.8rem;
            font-weight: 300;
            line-height: 1.2;
        }
    }
}


.profile-progress {
    &.progress {
        height: 2.5em;
        background: #bbb;
        .progress-bar {
            height: 2.5em;
        }
    }
}

.pimcore_area_entry {
    > .pimcore_area_buttons.top{
        width: 430px;
    }
}

.medium-icon-size{
    width: 130px;
}

.small-icon-size{
    width: 70px;
}
.navbar__side-menu__content__search-content__nav-tabs{
    .nav-item{
        .nav-link{
            letter-spacing: 0.8px;
            .badge{
                padding: .25em .42em;
            }
        }
    }
}
.partners-news-image{
    @include media-breakpoint-down(sm) {
        max-width: 200px;
    }
}

.border{
    &-top{
        &-1{
            border-top: 1px solid;
        }
        &-2{
            border-top: 2px solid;
        }
        &-3{
            border-top: 3px solid;
        }
        &-4{
            border-top: 4px solid;
        }
        &-5{
            border-top: 5px solid;
        }
        &-6{
            border-top: 6px solid;
        }
        &-7{
            border-top: 7px solid;
        }
        &-8{
            border-top: 8px solid;
        }
        &-9{
            border-top: 9px solid;
        }
        &-10{
            border-top: 10px solid;
        }
    }
    &-bottom{
        &-1{
            border-bottom: 1px solid;
        }
        &-2{
            border-bottom: 2px solid;
        }
        &-3{
            border-bottom: 3px solid;
        }
        &-4{
            border-bottom: 4px solid;
        }
        &-5{
            border-bottom: 5px solid;
        }
        &-6{
            border-bottom: 6px solid;
        }
        &-7{
            border-bottom: 7px solid;
        }
        &-8{
            border-bottom: 8px solid;
        }
        &-9{
            border-bottom: 9px solid;
        }
        &-10{
            border-bottom: 10px solid;
        }
    }
    &-left{
        &-1{
            border-left: 1px solid;
        }
        &-2{
            border-left: 2px solid;
        }
        &-3{
            border-left: 3px solid;
        }
        &-4{
            border-left: 4px solid;
        }
        &-5{
            border-left: 5px solid;
        }
        &-6{
            border-left: 6px solid;
        }
        &-7{
            border-left: 7px solid;
        }
        &-8{
            border-left: 8px solid;
        }
        &-9{
            border-left: 9px solid;
        }
        &-10{
            border-left: 10px solid;
        }
    }
    &-right{
        &-1{
            border-right: 1px solid;
        }
        &-2{
            border-right: 2px solid;
        }
        &-3{
            border-right: 3px solid;
        }
        &-4{
            border-right: 4px solid;
        }
        &-5{
            border-right: 5px solid;
        }
        &-6{
            border-right: 6px solid;
        }
        &-7{
            border-right: 7px solid;
        }
        &-8{
            border-right: 8px solid;
        }
        &-9{
            border-right: 9px solid;
        }
        &-10{
            border-right: 10px solid;
        }
    }
}

.pt{
    &-6{
        padding-top: 3.5rem!important;
    }
    &-7{
        padding-top: 4rem!important;
    }
    &-8{
        padding-top: 4.5rem!important;
    }
    &-9{
        padding-top: 5rem!important;
    }
    &-10{
        padding-top: 5.5rem!important;
    }
}
.pb{
    &-6{
        padding-bottom: 3.5rem!important;
    }
    &-7{
        padding-bottom: 4rem!important;
    }
    &-8{
        padding-bottom: 4.5rem!important;
    }
    &-9{
        padding-bottom: 5rem!important;
    }
    &-10{
        padding-bottom: 5.5rem!important;
    }
}
.pl{
    &-6{
        padding-left: 3.5rem!important;
    }
    &-7{
        padding-left: 4rem!important;
    }
    &-8{
        padding-left: 4.5rem!important;
    }
    &-9{
        padding-left: 5rem!important;
    }
    &-10{
        padding-left: 5.5rem!important;
    }
}
.pr{
    &-6{
        padding-right: 3.5rem!important;
    }
    &-7{
        padding-right: 4rem!important;
    }
    &-8{
        padding-right: 4.5rem!important;
    }
    &-9{
        padding-right: 5rem!important;
    }
    &-10{
        padding-right: 5.5rem!important;
    }
}

.mt{
    &-6{
        padding-top: 3.5rem!important;
    }
    &-7{
        padding-top: 4rem!important;
    }
    &-8{
        padding-top: 4.5rem!important;
    }
    &-9{
        padding-top: 5rem!important;
    }
    &-10{
        padding-top: 5.5rem!important;
    }
}
.mb{
    &-6{
        padding-bottom: 3.5rem!important;
    }
    &-7{
        padding-bottom: 4rem!important;
    }
    &-8{
        padding-bottom: 4.5rem!important;
    }
    &-9{
        padding-bottom: 5rem!important;
    }
    &-10{
        padding-bottom: 5.5rem!important;
    }
    &-11{
        padding-bottom: 9.5rem!important;
    }
}
.ml{
    &-6{
        padding-left: 3.5rem!important;
    }
    &-7{
        padding-left: 4rem!important;
    }
    &-8{
        padding-left: 4.5rem!important;
    }
    &-9{
        padding-left: 5rem!important;
    }
    &-10{
        padding-left: 5.5rem!important;
    }
}
.mr{
    &-6{
        padding-right: 3.5rem!important;
    }
    &-7{
        padding-right: 4rem!important;
    }
    &-8{
        padding-right: 4.5rem!important;
    }
    &-9{
        padding-right: 5rem!important;
    }
    &-10{
        padding-right: 5.5rem!important;
    }
}



html#facebook{
    body{
        div{
            width: 100%;
        }
    }
}

html.SandboxRoot{
    body{
        max-height: 259px;
        overflow-y: scroll;
    }

}

.text-decoration-underline{
    text-decoration: underline!important;
}

.text-truncated{
    display: flex;
    flex-direction: column;
    a{
        margin-top: 1rem;
    }
}

body{
    a{
        color: $primary;
        font-weight: bold;
        text-decoration: none;
        &:hover{
            color: $primary;
        }
    }
}

.alert.alert-success{
    margin: 0 -15px;
}

.col-lg-7{
    .area-video-container{
        iframe{
            min-height: 450px;
            @media(max-width: 1610px) {
                min-height: 280px;
            }
            @media(max-width: 990px) {
                min-height: unset;
                max-height: 200px;
            }
            @media(max-width: 767px) {
                min-height: unset;
                max-height: 280px;
            }
            @media(max-width: 415px) {
                max-height: 215px;
            }
            @media(max-width: 375px) {
                max-height: 190px;
            }
            @media(max-width: 350px) {
                max-height: 165px;
            }
        }
    }
}

.flickr-feed{
    .embed-responsive-item{
        p{
            margin-bottom: 0;
        }
        a{
            img{
                width: 100%!important;
                height: 100%!important;
            }
        }
    }
    iframe{
        width: 100%!important;
        height: 100%!important;
    }
}

#main-partners{
    .filters-heading{
        width: calc(100% + 20px);
        margin-left: -20px !important;
        border-bottom: solid 1px $color-border;
        text-align: center;
    }
    #tag-filter{
        max-width: 1440px;
        width: 100%;
        gap: 1rem;
        position: relative;
        scroll-timeline-name: --tag-filter;
        scroll-timeline-axis: x;
        &::after{
            content: '';
            position: fixed;
            bottom: 0;
            right: 0;
            height: 81px;
            width: 40px;
            background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #fff);
            pointer-events: none;
            @supports (animation-timeline: --tag-filter) {
                animation: fade-out 1s forwards ease-in-out;
                animation-duration: .5s;
                animation-timeline: --tag-filter;
                animation-range-start: exit 90%;
            }
        }
        @keyframes fade-out {
            0% {
                opacity: 1;
            }
            100% {
                opacity: 0;
            }
        }

        @media (min-width: 768px){
            gap: 1.5rem;
            justify-content: center;
            flex-wrap: wrap;
            &::after{
                display: none;
            }
        }
    }
}

// Reference page

.reference-item .card__image__text {
    background: linear-gradient(to bottom,  #eb1c25 0%,#692487 100%);
    min-height: 33%;
}

.embed-responsive-3by4:before {
    padding-top: 125%;
}