main.video{
  .top-items{
    opacity: 0;
    transition: 1s ease;
  }
  body.bg-polygon-ready &,
  body.bg-polygon-error &{
    .top-items{
      opacity: 1;
    }
  }
  body.bg-polygon-error & {
    .bg-polygon{
      opacity: 0;
    }
    .top-items {
      .item-title,
      .item-content,
      .item-speaker {
        color: #000;
      }

      .item-speaker::before {
        background-image: url("../vectors/ico-user-academy.svg");
      }
    }
  }
}