/* import and theming bootstrap */
/* import and theming bootstrap */
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs,
.col-xs-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #0386A3 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #025d71 !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #0386A3 !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before, .page-single__welcome .embed-responsive.page-single-main-image::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow, .navbar__side-menu__content__search-content__nav-tabs .nav-link.active {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #0386A3 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #024858 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

/*$enable-responsive-font-sizes: true;*/
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2, .page-single__welcome h2:not(.display-1):not(.display-2):not(.display-3):not(.display-4), #simple-page h2:not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  font-size: 3.2rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3, .page-single__welcome h3:not(.display-1):not(.display-2):not(.display-3):not(.display-4):not(.text-dark):not(.text-black) {
  font-size: 2.6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4, .page-single__welcome h4:not(.display-1):not(.display-2):not(.display-3):not(.display-4),
.page-single__welcome h5:not(.display-1):not(.display-2):not(.display-3):not(.display-4),
.page-single__welcome h6:not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #0386A3;
  --secondary: #344AF0;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #F5F5F5;
  --dark: #2E283D;
  --accent: #E2189C;
  --black: #222222;
  --green: #69C881;
  --smau-agrifood: #0386A3;
  --smau-digital-trends: #29B473;
  --smau-ict: #f28e1e;
  --smau-industry: #4891CD;
  --smau-international: #0F9EA5;
  --smau-mobile: #F6921E;
  --smau-open-innovations: #684cc8;
  --smau-trade-tourism: #146E96;
  --smau-health: #ffd500;
  --smau-open-inovation: #006bdc;
  --smau-food-retail: #be4c49;
  --smau-smart-manufacturing: #bedb65;
  --smau-energy-environment: #62cced;
  --smau-smart-mobility: #2c388f;
  --smau-e-health: #2ca377;
  --smau-alternative: #ea899a;
  --smau-energy: #adff2f;
  --smau-mobility: #c3b8f9;
  --smau-hr: #ffdead;
  --smau-sustainability: #89f5a0;
  --smau-artificial-intelligence: #173e87;
  --smau-emerging-technologies: #ff7bac;
  --breakpoint-xxs: 0;
  --breakpoint-xs: 320px;
  --breakpoint-sm: 413px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1610px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(34, 34, 34, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #0386A3;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #024858;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2, .page-single__welcome h2:not(.display-1):not(.display-2):not(.display-3):not(.display-4), #simple-page h2:not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  font-size: 3.2rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3, .page-single__welcome h3:not(.display-1):not(.display-2):not(.display-3):not(.display-4):not(.text-dark):not(.text-black) {
  font-size: 2.6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4, .page-single__welcome h4:not(.display-1):not(.display-2):not(.display-3):not(.display-4),
.page-single__welcome h5:not(.display-1):not(.display-2):not(.display-3):not(.display-4),
.page-single__welcome h6:not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 413px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }
  @media (min-width: 1610px) {
    .container {
      max-width: 1440px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs,
.col-xs-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 320px) {
  .col-xs {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xs-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xs-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xs-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xs-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xs-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xs-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xs-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xs-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xs-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xs-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xs-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xs-first {
    order: -1; }
  .order-xs-last {
    order: 13; }
  .order-xs-0 {
    order: 0; }
  .order-xs-1 {
    order: 1; }
  .order-xs-2 {
    order: 2; }
  .order-xs-3 {
    order: 3; }
  .order-xs-4 {
    order: 4; }
  .order-xs-5 {
    order: 5; }
  .order-xs-6 {
    order: 6; }
  .order-xs-7 {
    order: 7; }
  .order-xs-8 {
    order: 8; }
  .order-xs-9 {
    order: 9; }
  .order-xs-10 {
    order: 10; }
  .order-xs-11 {
    order: 11; }
  .order-xs-12 {
    order: 12; }
  .offset-xs-0 {
    margin-left: 0; }
  .offset-xs-1 {
    margin-left: 8.33333%; }
  .offset-xs-2 {
    margin-left: 16.66667%; }
  .offset-xs-3 {
    margin-left: 25%; }
  .offset-xs-4 {
    margin-left: 33.33333%; }
  .offset-xs-5 {
    margin-left: 41.66667%; }
  .offset-xs-6 {
    margin-left: 50%; }
  .offset-xs-7 {
    margin-left: 58.33333%; }
  .offset-xs-8 {
    margin-left: 66.66667%; }
  .offset-xs-9 {
    margin-left: 75%; }
  .offset-xs-10 {
    margin-left: 83.33333%; }
  .offset-xs-11 {
    margin-left: 91.66667%; } }

@media (min-width: 413px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1610px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 13; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; }
  .order-xxl-11 {
    order: 11; }
  .order-xxl-12 {
    order: 12; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8dde5; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7cc0cf; }

.table-hover .table-primary:hover {
  background-color: #a5d4de; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #a5d4de; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #c6ccfb; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #95a1f7; }

.table-hover .table-secondary:hover {
  background-color: #aeb7f9; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #aeb7f9; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fcfcfc; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fafafa; }

.table-hover .table-light:hover {
  background-color: #efefef; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #efefef; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c4c3c9; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #928f9a; }

.table-hover .table-dark:hover {
  background-color: #b7b6bd; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b7b6bd; }

.table-accent,
.table-accent > th,
.table-accent > td {
  background-color: #f7bee3; }

.table-accent th,
.table-accent td,
.table-accent thead th,
.table-accent tbody + tbody {
  border-color: #f087cc; }

.table-hover .table-accent:hover {
  background-color: #f4a7d9; }
  .table-hover .table-accent:hover > td,
  .table-hover .table-accent:hover > th {
    background-color: #f4a7d9; }

.table-black,
.table-black > th,
.table-black > td {
  background-color: #c1c1c1; }

.table-black th,
.table-black td,
.table-black thead th,
.table-black tbody + tbody {
  border-color: #8c8c8c; }

.table-hover .table-black:hover {
  background-color: #b4b4b4; }
  .table-hover .table-black:hover > td,
  .table-hover .table-black:hover > th {
    background-color: #b4b4b4; }

.table-green,
.table-green > th,
.table-green > td {
  background-color: #d5f0dc; }

.table-green th,
.table-green td,
.table-green thead th,
.table-green tbody + tbody {
  border-color: #b1e2bd; }

.table-hover .table-green:hover {
  background-color: #c2e9cc; }
  .table-hover .table-green:hover > td,
  .table-hover .table-green:hover > th {
    background-color: #c2e9cc; }

.table-smau-agrifood,
.table-smau-agrifood > th,
.table-smau-agrifood > td {
  background-color: #b8dde5; }

.table-smau-agrifood th,
.table-smau-agrifood td,
.table-smau-agrifood thead th,
.table-smau-agrifood tbody + tbody {
  border-color: #7cc0cf; }

.table-hover .table-smau-agrifood:hover {
  background-color: #a5d4de; }
  .table-hover .table-smau-agrifood:hover > td,
  .table-hover .table-smau-agrifood:hover > th {
    background-color: #a5d4de; }

.table-smau-digital-trends,
.table-smau-digital-trends > th,
.table-smau-digital-trends > td {
  background-color: #c3ead8; }

.table-smau-digital-trends th,
.table-smau-digital-trends td,
.table-smau-digital-trends thead th,
.table-smau-digital-trends tbody + tbody {
  border-color: #90d8b6; }

.table-hover .table-smau-digital-trends:hover {
  background-color: #b0e3cc; }
  .table-hover .table-smau-digital-trends:hover > td,
  .table-hover .table-smau-digital-trends:hover > th {
    background-color: #b0e3cc; }

.table-smau-ict,
.table-smau-ict > th,
.table-smau-ict > td {
  background-color: #fbdfc0; }

.table-smau-ict th,
.table-smau-ict td,
.table-smau-ict thead th,
.table-smau-ict tbody + tbody {
  border-color: #f8c48a; }

.table-hover .table-smau-ict:hover {
  background-color: #f9d3a8; }
  .table-hover .table-smau-ict:hover > td,
  .table-hover .table-smau-ict:hover > th {
    background-color: #f9d3a8; }

.table-smau-industry,
.table-smau-industry > th,
.table-smau-industry > td {
  background-color: #cce0f1; }

.table-smau-industry th,
.table-smau-industry td,
.table-smau-industry thead th,
.table-smau-industry tbody + tbody {
  border-color: #a0c6e5; }

.table-hover .table-smau-industry:hover {
  background-color: #b8d4ec; }
  .table-hover .table-smau-industry:hover > td,
  .table-hover .table-smau-industry:hover > th {
    background-color: #b8d4ec; }

.table-smau-international,
.table-smau-international > th,
.table-smau-international > td {
  background-color: #bce4e6; }

.table-smau-international th,
.table-smau-international td,
.table-smau-international thead th,
.table-smau-international tbody + tbody {
  border-color: #82cdd0; }

.table-hover .table-smau-international:hover {
  background-color: #a9dddf; }
  .table-hover .table-smau-international:hover > td,
  .table-hover .table-smau-international:hover > th {
    background-color: #a9dddf; }

.table-smau-mobile,
.table-smau-mobile > th,
.table-smau-mobile > td {
  background-color: #fce0c0; }

.table-smau-mobile th,
.table-smau-mobile td,
.table-smau-mobile thead th,
.table-smau-mobile tbody + tbody {
  border-color: #fac68a; }

.table-hover .table-smau-mobile:hover {
  background-color: #fbd4a8; }
  .table-hover .table-smau-mobile:hover > td,
  .table-hover .table-smau-mobile:hover > th {
    background-color: #fbd4a8; }

.table-smau-open-innovations,
.table-smau-open-innovations > th,
.table-smau-open-innovations > td {
  background-color: #d5cdf0; }

.table-smau-open-innovations th,
.table-smau-open-innovations td,
.table-smau-open-innovations thead th,
.table-smau-open-innovations tbody + tbody {
  border-color: #b0a2e2; }

.table-hover .table-smau-open-innovations:hover {
  background-color: #c5b9ea; }
  .table-hover .table-smau-open-innovations:hover > td,
  .table-hover .table-smau-open-innovations:hover > th {
    background-color: #c5b9ea; }

.table-smau-trade-tourism,
.table-smau-trade-tourism > th,
.table-smau-trade-tourism > td {
  background-color: #bdd6e2; }

.table-smau-trade-tourism th,
.table-smau-trade-tourism td,
.table-smau-trade-tourism thead th,
.table-smau-trade-tourism tbody + tbody {
  border-color: #85b4c8; }

.table-hover .table-smau-trade-tourism:hover {
  background-color: #abcbda; }
  .table-hover .table-smau-trade-tourism:hover > td,
  .table-hover .table-smau-trade-tourism:hover > th {
    background-color: #abcbda; }

.table-smau-health,
.table-smau-health > th,
.table-smau-health > td {
  background-color: #fff3b8; }

.table-smau-health th,
.table-smau-health td,
.table-smau-health thead th,
.table-smau-health tbody + tbody {
  border-color: #ffe97a; }

.table-hover .table-smau-health:hover {
  background-color: #ffef9f; }
  .table-hover .table-smau-health:hover > td,
  .table-hover .table-smau-health:hover > th {
    background-color: #ffef9f; }

.table-smau-open-inovation,
.table-smau-open-inovation > th,
.table-smau-open-inovation > td {
  background-color: #b8d6f5; }

.table-smau-open-inovation th,
.table-smau-open-inovation td,
.table-smau-open-inovation thead th,
.table-smau-open-inovation tbody + tbody {
  border-color: #7ab2ed; }

.table-hover .table-smau-open-inovation:hover {
  background-color: #a2c9f2; }
  .table-hover .table-smau-open-inovation:hover > td,
  .table-hover .table-smau-open-inovation:hover > th {
    background-color: #a2c9f2; }

.table-smau-food-retail,
.table-smau-food-retail > th,
.table-smau-food-retail > td {
  background-color: #edcdcc; }

.table-smau-food-retail th,
.table-smau-food-retail td,
.table-smau-food-retail thead th,
.table-smau-food-retail tbody + tbody {
  border-color: #dda2a0; }

.table-hover .table-smau-food-retail:hover {
  background-color: #e6bbb9; }
  .table-hover .table-smau-food-retail:hover > td,
  .table-hover .table-smau-food-retail:hover > th {
    background-color: #e6bbb9; }

.table-smau-smart-manufacturing,
.table-smau-smart-manufacturing > th,
.table-smau-smart-manufacturing > td {
  background-color: #edf5d4; }

.table-smau-smart-manufacturing th,
.table-smau-smart-manufacturing td,
.table-smau-smart-manufacturing thead th,
.table-smau-smart-manufacturing tbody + tbody {
  border-color: #ddecaf; }

.table-hover .table-smau-smart-manufacturing:hover {
  background-color: #e4f0bf; }
  .table-hover .table-smau-smart-manufacturing:hover > td,
  .table-hover .table-smau-smart-manufacturing:hover > th {
    background-color: #e4f0bf; }

.table-smau-energy-environment,
.table-smau-energy-environment > th,
.table-smau-energy-environment > td {
  background-color: #d3f1fa; }

.table-smau-energy-environment th,
.table-smau-energy-environment td,
.table-smau-energy-environment thead th,
.table-smau-energy-environment tbody + tbody {
  border-color: #ade4f6; }

.table-hover .table-smau-energy-environment:hover {
  background-color: #bceaf7; }
  .table-hover .table-smau-energy-environment:hover > td,
  .table-hover .table-smau-energy-environment:hover > th {
    background-color: #bceaf7; }

.table-smau-smart-mobility,
.table-smau-smart-mobility > th,
.table-smau-smart-mobility > td {
  background-color: #c4c7e0; }

.table-smau-smart-mobility th,
.table-smau-smart-mobility td,
.table-smau-smart-mobility thead th,
.table-smau-smart-mobility tbody + tbody {
  border-color: #9198c5; }

.table-hover .table-smau-smart-mobility:hover {
  background-color: #b3b7d7; }
  .table-hover .table-smau-smart-mobility:hover > td,
  .table-hover .table-smau-smart-mobility:hover > th {
    background-color: #b3b7d7; }

.table-smau-e-health,
.table-smau-e-health > th,
.table-smau-e-health > td {
  background-color: #c4e5d9; }

.table-smau-e-health th,
.table-smau-e-health td,
.table-smau-e-health thead th,
.table-smau-e-health tbody + tbody {
  border-color: #91cfb8; }

.table-hover .table-smau-e-health:hover {
  background-color: #b2ddce; }
  .table-hover .table-smau-e-health:hover > td,
  .table-hover .table-smau-e-health:hover > th {
    background-color: #b2ddce; }

.table-smau-alternative,
.table-smau-alternative > th,
.table-smau-alternative > td {
  background-color: #f9dee3; }

.table-smau-alternative th,
.table-smau-alternative td,
.table-smau-alternative thead th,
.table-smau-alternative tbody + tbody {
  border-color: #f4c2ca; }

.table-hover .table-smau-alternative:hover {
  background-color: #f5c8d1; }
  .table-hover .table-smau-alternative:hover > td,
  .table-hover .table-smau-alternative:hover > th {
    background-color: #f5c8d1; }

.table-smau-energy,
.table-smau-energy > th,
.table-smau-energy > td {
  background-color: #e8ffc5; }

.table-smau-energy th,
.table-smau-energy td,
.table-smau-energy thead th,
.table-smau-energy tbody + tbody {
  border-color: #d4ff93; }

.table-hover .table-smau-energy:hover {
  background-color: #deffac; }
  .table-hover .table-smau-energy:hover > td,
  .table-hover .table-smau-energy:hover > th {
    background-color: #deffac; }

.table-smau-mobility,
.table-smau-mobility > th,
.table-smau-mobility > td {
  background-color: #eeebfd; }

.table-smau-mobility th,
.table-smau-mobility td,
.table-smau-mobility thead th,
.table-smau-mobility tbody + tbody {
  border-color: #e0dafc; }

.table-hover .table-smau-mobility:hover {
  background-color: #dad4fb; }
  .table-hover .table-smau-mobility:hover > td,
  .table-hover .table-smau-mobility:hover > th {
    background-color: #dad4fb; }

.table-smau-hr,
.table-smau-hr > th,
.table-smau-hr > td {
  background-color: #fff6e8; }

.table-smau-hr th,
.table-smau-hr td,
.table-smau-hr thead th,
.table-smau-hr tbody + tbody {
  border-color: #ffeed4; }

.table-hover .table-smau-hr:hover {
  background-color: #ffeccf; }
  .table-hover .table-smau-hr:hover > td,
  .table-hover .table-smau-hr:hover > th {
    background-color: #ffeccf; }

.table-smau-sustainability,
.table-smau-sustainability > th,
.table-smau-sustainability > td {
  background-color: #defce4; }

.table-smau-sustainability th,
.table-smau-sustainability td,
.table-smau-sustainability thead th,
.table-smau-sustainability tbody + tbody {
  border-color: #c2face; }

.table-hover .table-smau-sustainability:hover {
  background-color: #c7fad1; }
  .table-hover .table-smau-sustainability:hover > td,
  .table-hover .table-smau-sustainability:hover > th {
    background-color: #c7fad1; }

.table-smau-artificial-intelligence,
.table-smau-artificial-intelligence > th,
.table-smau-artificial-intelligence > td {
  background-color: #bec9dd; }

.table-smau-artificial-intelligence th,
.table-smau-artificial-intelligence td,
.table-smau-artificial-intelligence thead th,
.table-smau-artificial-intelligence tbody + tbody {
  border-color: #869bc1; }

.table-hover .table-smau-artificial-intelligence:hover {
  background-color: #adbbd4; }
  .table-hover .table-smau-artificial-intelligence:hover > td,
  .table-hover .table-smau-artificial-intelligence:hover > th {
    background-color: #adbbd4; }

.table-smau-emerging-technologies,
.table-smau-emerging-technologies > th,
.table-smau-emerging-technologies > td {
  background-color: #ffdae8; }

.table-smau-emerging-technologies th,
.table-smau-emerging-technologies td,
.table-smau-emerging-technologies thead th,
.table-smau-emerging-technologies tbody + tbody {
  border-color: #ffbad4; }

.table-hover .table-smau-emerging-technologies:hover {
  background-color: #ffc1d8; }
  .table-hover .table-smau-emerging-technologies:hover > td,
  .table-hover .table-smau-emerging-technologies:hover > th {
    background-color: #ffc1d8; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 319.98px) {
  .table-responsive-xs {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xs > .table-bordered {
      border: 0; } }

@media (max-width: 412.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

@media (max-width: 1609.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xxl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #2ad5fb;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(3, 134, 163, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 413px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(3, 134, 163, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #0386A3;
  border-color: #0386A3; }
  .btn-primary:hover {
    color: #fff;
    background-color: #02677d;
    border-color: #025d71; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(41, 152, 177, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #0386A3;
    border-color: #0386A3; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #025d71;
    border-color: #025364; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(41, 152, 177, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #344AF0;
  border-color: #344AF0; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #112bec;
    border-color: #1129e0; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 101, 242, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #344AF0;
    border-color: #344AF0; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #1129e0;
    border-color: #1027d5; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 101, 242, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #F5F5F5;
  border-color: #F5F5F5; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e2e2;
    border-color: gainsboro; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(213, 214, 214, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #F5F5F5;
    border-color: #F5F5F5; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: gainsboro;
    border-color: #d5d5d5; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(213, 214, 214, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #2E283D;
  border-color: #2E283D; }
  .btn-dark:hover {
    color: #fff;
    background-color: #1d1926;
    border-color: #17141e; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(77, 72, 90, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #2E283D;
    border-color: #2E283D; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #17141e;
    border-color: #110f16; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(77, 72, 90, 0.5); }

.btn-accent {
  color: #fff;
  background-color: #E2189C;
  border-color: #E2189C; }
  .btn-accent:hover {
    color: #fff;
    background-color: #bf1484;
    border-color: #b4137c; }
  .btn-accent:focus, .btn-accent.focus {
    box-shadow: 0 0 0 0.2rem rgba(230, 59, 171, 0.5); }
  .btn-accent.disabled, .btn-accent:disabled {
    color: #fff;
    background-color: #E2189C;
    border-color: #E2189C; }
  .btn-accent:not(:disabled):not(.disabled):active, .btn-accent:not(:disabled):not(.disabled).active,
  .show > .btn-accent.dropdown-toggle {
    color: #fff;
    background-color: #b4137c;
    border-color: #a81274; }
    .btn-accent:not(:disabled):not(.disabled):active:focus, .btn-accent:not(:disabled):not(.disabled).active:focus,
    .show > .btn-accent.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(230, 59, 171, 0.5); }

.btn-black {
  color: #fff;
  background-color: #222222;
  border-color: #222222; }
  .btn-black:hover {
    color: #fff;
    background-color: #0f0f0f;
    border-color: #090909; }
  .btn-black:focus, .btn-black.focus {
    box-shadow: 0 0 0 0.2rem rgba(67, 67, 67, 0.5); }
  .btn-black.disabled, .btn-black:disabled {
    color: #fff;
    background-color: #222222;
    border-color: #222222; }
  .btn-black:not(:disabled):not(.disabled):active, .btn-black:not(:disabled):not(.disabled).active,
  .show > .btn-black.dropdown-toggle {
    color: #fff;
    background-color: #090909;
    border-color: #020202; }
    .btn-black:not(:disabled):not(.disabled):active:focus, .btn-black:not(:disabled):not(.disabled).active:focus,
    .show > .btn-black.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(67, 67, 67, 0.5); }

.btn-green {
  color: #212529;
  background-color: #69C881;
  border-color: #69C881; }
  .btn-green:hover {
    color: #fff;
    background-color: #4dbe69;
    border-color: #44ba62; }
  .btn-green:focus, .btn-green.focus {
    box-shadow: 0 0 0 0.2rem rgba(94, 176, 116, 0.5); }
  .btn-green.disabled, .btn-green:disabled {
    color: #212529;
    background-color: #69C881;
    border-color: #69C881; }
  .btn-green:not(:disabled):not(.disabled):active, .btn-green:not(:disabled):not(.disabled).active,
  .show > .btn-green.dropdown-toggle {
    color: #fff;
    background-color: #44ba62;
    border-color: #41b15d; }
    .btn-green:not(:disabled):not(.disabled):active:focus, .btn-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(94, 176, 116, 0.5); }

.btn-smau-agrifood {
  color: #fff;
  background-color: #0386A3;
  border-color: #0386A3; }
  .btn-smau-agrifood:hover {
    color: #fff;
    background-color: #02677d;
    border-color: #025d71; }
  .btn-smau-agrifood:focus, .btn-smau-agrifood.focus {
    box-shadow: 0 0 0 0.2rem rgba(41, 152, 177, 0.5); }
  .btn-smau-agrifood.disabled, .btn-smau-agrifood:disabled {
    color: #fff;
    background-color: #0386A3;
    border-color: #0386A3; }
  .btn-smau-agrifood:not(:disabled):not(.disabled):active, .btn-smau-agrifood:not(:disabled):not(.disabled).active,
  .show > .btn-smau-agrifood.dropdown-toggle {
    color: #fff;
    background-color: #025d71;
    border-color: #025364; }
    .btn-smau-agrifood:not(:disabled):not(.disabled):active:focus, .btn-smau-agrifood:not(:disabled):not(.disabled).active:focus,
    .show > .btn-smau-agrifood.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(41, 152, 177, 0.5); }

.btn-smau-digital-trends {
  color: #fff;
  background-color: #29B473;
  border-color: #29B473; }
  .btn-smau-digital-trends:hover {
    color: #fff;
    background-color: #22955f;
    border-color: #208a58; }
  .btn-smau-digital-trends:focus, .btn-smau-digital-trends.focus {
    box-shadow: 0 0 0 0.2rem rgba(73, 191, 136, 0.5); }
  .btn-smau-digital-trends.disabled, .btn-smau-digital-trends:disabled {
    color: #fff;
    background-color: #29B473;
    border-color: #29B473; }
  .btn-smau-digital-trends:not(:disabled):not(.disabled):active, .btn-smau-digital-trends:not(:disabled):not(.disabled).active,
  .show > .btn-smau-digital-trends.dropdown-toggle {
    color: #fff;
    background-color: #208a58;
    border-color: #1d8052; }
    .btn-smau-digital-trends:not(:disabled):not(.disabled):active:focus, .btn-smau-digital-trends:not(:disabled):not(.disabled).active:focus,
    .show > .btn-smau-digital-trends.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(73, 191, 136, 0.5); }

.btn-smau-ict {
  color: #212529;
  background-color: #f28e1e;
  border-color: #f28e1e; }
  .btn-smau-ict:hover {
    color: #fff;
    background-color: #dd7b0d;
    border-color: #d1740c; }
  .btn-smau-ict:focus, .btn-smau-ict.focus {
    box-shadow: 0 0 0 0.2rem rgba(211, 126, 32, 0.5); }
  .btn-smau-ict.disabled, .btn-smau-ict:disabled {
    color: #212529;
    background-color: #f28e1e;
    border-color: #f28e1e; }
  .btn-smau-ict:not(:disabled):not(.disabled):active, .btn-smau-ict:not(:disabled):not(.disabled).active,
  .show > .btn-smau-ict.dropdown-toggle {
    color: #fff;
    background-color: #d1740c;
    border-color: #c56d0b; }
    .btn-smau-ict:not(:disabled):not(.disabled):active:focus, .btn-smau-ict:not(:disabled):not(.disabled).active:focus,
    .show > .btn-smau-ict.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(211, 126, 32, 0.5); }

.btn-smau-industry {
  color: #fff;
  background-color: #4891CD;
  border-color: #4891CD; }
  .btn-smau-industry:hover {
    color: #fff;
    background-color: #337ebc;
    border-color: #3077b2; }
  .btn-smau-industry:focus, .btn-smau-industry.focus {
    box-shadow: 0 0 0 0.2rem rgba(99, 162, 213, 0.5); }
  .btn-smau-industry.disabled, .btn-smau-industry:disabled {
    color: #fff;
    background-color: #4891CD;
    border-color: #4891CD; }
  .btn-smau-industry:not(:disabled):not(.disabled):active, .btn-smau-industry:not(:disabled):not(.disabled).active,
  .show > .btn-smau-industry.dropdown-toggle {
    color: #fff;
    background-color: #3077b2;
    border-color: #2e71a7; }
    .btn-smau-industry:not(:disabled):not(.disabled):active:focus, .btn-smau-industry:not(:disabled):not(.disabled).active:focus,
    .show > .btn-smau-industry.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(99, 162, 213, 0.5); }

.btn-smau-international {
  color: #fff;
  background-color: #0F9EA5;
  border-color: #0F9EA5; }
  .btn-smau-international:hover {
    color: #fff;
    background-color: #0c7c82;
    border-color: #0b7176; }
  .btn-smau-international:focus, .btn-smau-international.focus {
    box-shadow: 0 0 0 0.2rem rgba(51, 173, 179, 0.5); }
  .btn-smau-international.disabled, .btn-smau-international:disabled {
    color: #fff;
    background-color: #0F9EA5;
    border-color: #0F9EA5; }
  .btn-smau-international:not(:disabled):not(.disabled):active, .btn-smau-international:not(:disabled):not(.disabled).active,
  .show > .btn-smau-international.dropdown-toggle {
    color: #fff;
    background-color: #0b7176;
    border-color: #0a666b; }
    .btn-smau-international:not(:disabled):not(.disabled):active:focus, .btn-smau-international:not(:disabled):not(.disabled).active:focus,
    .show > .btn-smau-international.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(51, 173, 179, 0.5); }

.btn-smau-mobile {
  color: #212529;
  background-color: #F6921E;
  border-color: #F6921E; }
  .btn-smau-mobile:hover {
    color: #fff;
    background-color: #e57f09;
    border-color: #d87809; }
  .btn-smau-mobile:focus, .btn-smau-mobile.focus {
    box-shadow: 0 0 0 0.2rem rgba(214, 130, 32, 0.5); }
  .btn-smau-mobile.disabled, .btn-smau-mobile:disabled {
    color: #212529;
    background-color: #F6921E;
    border-color: #F6921E; }
  .btn-smau-mobile:not(:disabled):not(.disabled):active, .btn-smau-mobile:not(:disabled):not(.disabled).active,
  .show > .btn-smau-mobile.dropdown-toggle {
    color: #fff;
    background-color: #d87809;
    border-color: #cc7108; }
    .btn-smau-mobile:not(:disabled):not(.disabled):active:focus, .btn-smau-mobile:not(:disabled):not(.disabled).active:focus,
    .show > .btn-smau-mobile.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(214, 130, 32, 0.5); }

.btn-smau-open-innovations {
  color: #fff;
  background-color: #684cc8;
  border-color: #684cc8; }
  .btn-smau-open-innovations:hover {
    color: #fff;
    background-color: #5438b6;
    border-color: #5035ac; }
  .btn-smau-open-innovations:focus, .btn-smau-open-innovations.focus {
    box-shadow: 0 0 0 0.2rem rgba(127, 103, 208, 0.5); }
  .btn-smau-open-innovations.disabled, .btn-smau-open-innovations:disabled {
    color: #fff;
    background-color: #684cc8;
    border-color: #684cc8; }
  .btn-smau-open-innovations:not(:disabled):not(.disabled):active, .btn-smau-open-innovations:not(:disabled):not(.disabled).active,
  .show > .btn-smau-open-innovations.dropdown-toggle {
    color: #fff;
    background-color: #5035ac;
    border-color: #4b32a2; }
    .btn-smau-open-innovations:not(:disabled):not(.disabled):active:focus, .btn-smau-open-innovations:not(:disabled):not(.disabled).active:focus,
    .show > .btn-smau-open-innovations.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(127, 103, 208, 0.5); }

.btn-smau-trade-tourism {
  color: #fff;
  background-color: #146E96;
  border-color: #146E96; }
  .btn-smau-trade-tourism:hover {
    color: #fff;
    background-color: #105574;
    border-color: #0e4d69; }
  .btn-smau-trade-tourism:focus, .btn-smau-trade-tourism.focus {
    box-shadow: 0 0 0 0.2rem rgba(55, 132, 166, 0.5); }
  .btn-smau-trade-tourism.disabled, .btn-smau-trade-tourism:disabled {
    color: #fff;
    background-color: #146E96;
    border-color: #146E96; }
  .btn-smau-trade-tourism:not(:disabled):not(.disabled):active, .btn-smau-trade-tourism:not(:disabled):not(.disabled).active,
  .show > .btn-smau-trade-tourism.dropdown-toggle {
    color: #fff;
    background-color: #0e4d69;
    border-color: #0d455e; }
    .btn-smau-trade-tourism:not(:disabled):not(.disabled):active:focus, .btn-smau-trade-tourism:not(:disabled):not(.disabled).active:focus,
    .show > .btn-smau-trade-tourism.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(55, 132, 166, 0.5); }

.btn-smau-health {
  color: #212529;
  background-color: #ffd500;
  border-color: #ffd500; }
  .btn-smau-health:hover {
    color: #212529;
    background-color: #d9b500;
    border-color: #ccaa00; }
  .btn-smau-health:focus, .btn-smau-health.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 187, 6, 0.5); }
  .btn-smau-health.disabled, .btn-smau-health:disabled {
    color: #212529;
    background-color: #ffd500;
    border-color: #ffd500; }
  .btn-smau-health:not(:disabled):not(.disabled):active, .btn-smau-health:not(:disabled):not(.disabled).active,
  .show > .btn-smau-health.dropdown-toggle {
    color: #212529;
    background-color: #ccaa00;
    border-color: #bfa000; }
    .btn-smau-health:not(:disabled):not(.disabled):active:focus, .btn-smau-health:not(:disabled):not(.disabled).active:focus,
    .show > .btn-smau-health.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 187, 6, 0.5); }

.btn-smau-open-inovation {
  color: #fff;
  background-color: #006bdc;
  border-color: #006bdc; }
  .btn-smau-open-inovation:hover {
    color: #fff;
    background-color: #0058b6;
    border-color: #0052a9; }
  .btn-smau-open-inovation:focus, .btn-smau-open-inovation.focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 129, 225, 0.5); }
  .btn-smau-open-inovation.disabled, .btn-smau-open-inovation:disabled {
    color: #fff;
    background-color: #006bdc;
    border-color: #006bdc; }
  .btn-smau-open-inovation:not(:disabled):not(.disabled):active, .btn-smau-open-inovation:not(:disabled):not(.disabled).active,
  .show > .btn-smau-open-inovation.dropdown-toggle {
    color: #fff;
    background-color: #0052a9;
    border-color: #004c9c; }
    .btn-smau-open-inovation:not(:disabled):not(.disabled):active:focus, .btn-smau-open-inovation:not(:disabled):not(.disabled).active:focus,
    .show > .btn-smau-open-inovation.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 129, 225, 0.5); }

.btn-smau-food-retail {
  color: #fff;
  background-color: #be4c49;
  border-color: #be4c49; }
  .btn-smau-food-retail:hover {
    color: #fff;
    background-color: #a63e3b;
    border-color: #9c3a38; }
  .btn-smau-food-retail:focus, .btn-smau-food-retail.focus {
    box-shadow: 0 0 0 0.2rem rgba(200, 103, 100, 0.5); }
  .btn-smau-food-retail.disabled, .btn-smau-food-retail:disabled {
    color: #fff;
    background-color: #be4c49;
    border-color: #be4c49; }
  .btn-smau-food-retail:not(:disabled):not(.disabled):active, .btn-smau-food-retail:not(:disabled):not(.disabled).active,
  .show > .btn-smau-food-retail.dropdown-toggle {
    color: #fff;
    background-color: #9c3a38;
    border-color: #933734; }
    .btn-smau-food-retail:not(:disabled):not(.disabled):active:focus, .btn-smau-food-retail:not(:disabled):not(.disabled).active:focus,
    .show > .btn-smau-food-retail.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(200, 103, 100, 0.5); }

.btn-smau-smart-manufacturing {
  color: #212529;
  background-color: #bedb65;
  border-color: #bedb65; }
  .btn-smau-smart-manufacturing:hover {
    color: #212529;
    background-color: #b1d446;
    border-color: #add13c; }
  .btn-smau-smart-manufacturing:focus, .btn-smau-smart-manufacturing.focus {
    box-shadow: 0 0 0 0.2rem rgba(166, 192, 92, 0.5); }
  .btn-smau-smart-manufacturing.disabled, .btn-smau-smart-manufacturing:disabled {
    color: #212529;
    background-color: #bedb65;
    border-color: #bedb65; }
  .btn-smau-smart-manufacturing:not(:disabled):not(.disabled):active, .btn-smau-smart-manufacturing:not(:disabled):not(.disabled).active,
  .show > .btn-smau-smart-manufacturing.dropdown-toggle {
    color: #212529;
    background-color: #add13c;
    border-color: #a8cf31; }
    .btn-smau-smart-manufacturing:not(:disabled):not(.disabled):active:focus, .btn-smau-smart-manufacturing:not(:disabled):not(.disabled).active:focus,
    .show > .btn-smau-smart-manufacturing.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(166, 192, 92, 0.5); }

.btn-smau-energy-environment {
  color: #212529;
  background-color: #62cced;
  border-color: #62cced; }
  .btn-smau-energy-environment:hover {
    color: #212529;
    background-color: #40c1e9;
    border-color: #34bde8; }
  .btn-smau-energy-environment:focus, .btn-smau-energy-environment.focus {
    box-shadow: 0 0 0 0.2rem rgba(88, 179, 208, 0.5); }
  .btn-smau-energy-environment.disabled, .btn-smau-energy-environment:disabled {
    color: #212529;
    background-color: #62cced;
    border-color: #62cced; }
  .btn-smau-energy-environment:not(:disabled):not(.disabled):active, .btn-smau-energy-environment:not(:disabled):not(.disabled).active,
  .show > .btn-smau-energy-environment.dropdown-toggle {
    color: #212529;
    background-color: #34bde8;
    border-color: #29b9e6; }
    .btn-smau-energy-environment:not(:disabled):not(.disabled):active:focus, .btn-smau-energy-environment:not(:disabled):not(.disabled).active:focus,
    .show > .btn-smau-energy-environment.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(88, 179, 208, 0.5); }

.btn-smau-smart-mobility {
  color: #fff;
  background-color: #2c388f;
  border-color: #2c388f; }
  .btn-smau-smart-mobility:hover {
    color: #fff;
    background-color: #232d72;
    border-color: #202968; }
  .btn-smau-smart-mobility:focus, .btn-smau-smart-mobility.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 86, 160, 0.5); }
  .btn-smau-smart-mobility.disabled, .btn-smau-smart-mobility:disabled {
    color: #fff;
    background-color: #2c388f;
    border-color: #2c388f; }
  .btn-smau-smart-mobility:not(:disabled):not(.disabled):active, .btn-smau-smart-mobility:not(:disabled):not(.disabled).active,
  .show > .btn-smau-smart-mobility.dropdown-toggle {
    color: #fff;
    background-color: #202968;
    border-color: #1d255e; }
    .btn-smau-smart-mobility:not(:disabled):not(.disabled):active:focus, .btn-smau-smart-mobility:not(:disabled):not(.disabled).active:focus,
    .show > .btn-smau-smart-mobility.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(76, 86, 160, 0.5); }

.btn-smau-e-health {
  color: #fff;
  background-color: #2ca377;
  border-color: #2ca377; }
  .btn-smau-e-health:hover {
    color: #fff;
    background-color: #248561;
    border-color: #217b5a; }
  .btn-smau-e-health:focus, .btn-smau-e-health.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 177, 139, 0.5); }
  .btn-smau-e-health.disabled, .btn-smau-e-health:disabled {
    color: #fff;
    background-color: #2ca377;
    border-color: #2ca377; }
  .btn-smau-e-health:not(:disabled):not(.disabled):active, .btn-smau-e-health:not(:disabled):not(.disabled).active,
  .show > .btn-smau-e-health.dropdown-toggle {
    color: #fff;
    background-color: #217b5a;
    border-color: #1e7152; }
    .btn-smau-e-health:not(:disabled):not(.disabled):active:focus, .btn-smau-e-health:not(:disabled):not(.disabled).active:focus,
    .show > .btn-smau-e-health.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(76, 177, 139, 0.5); }

.btn-smau-alternative {
  color: #212529;
  background-color: #ea899a;
  border-color: #ea899a; }
  .btn-smau-alternative:hover {
    color: #fff;
    background-color: #e4697e;
    border-color: #e25e75; }
  .btn-smau-alternative:focus, .btn-smau-alternative.focus {
    box-shadow: 0 0 0 0.2rem rgba(204, 122, 137, 0.5); }
  .btn-smau-alternative.disabled, .btn-smau-alternative:disabled {
    color: #212529;
    background-color: #ea899a;
    border-color: #ea899a; }
  .btn-smau-alternative:not(:disabled):not(.disabled):active, .btn-smau-alternative:not(:disabled):not(.disabled).active,
  .show > .btn-smau-alternative.dropdown-toggle {
    color: #fff;
    background-color: #e25e75;
    border-color: #e0536c; }
    .btn-smau-alternative:not(:disabled):not(.disabled):active:focus, .btn-smau-alternative:not(:disabled):not(.disabled).active:focus,
    .show > .btn-smau-alternative.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(204, 122, 137, 0.5); }

.btn-smau-energy {
  color: #212529;
  background-color: #adff2f;
  border-color: #adff2f; }
  .btn-smau-energy:hover {
    color: #212529;
    background-color: #9eff09;
    border-color: #98fb00; }
  .btn-smau-energy:focus, .btn-smau-energy.focus {
    box-shadow: 0 0 0 0.2rem rgba(152, 222, 46, 0.5); }
  .btn-smau-energy.disabled, .btn-smau-energy:disabled {
    color: #212529;
    background-color: #adff2f;
    border-color: #adff2f; }
  .btn-smau-energy:not(:disabled):not(.disabled):active, .btn-smau-energy:not(:disabled):not(.disabled).active,
  .show > .btn-smau-energy.dropdown-toggle {
    color: #212529;
    background-color: #98fb00;
    border-color: #90ee00; }
    .btn-smau-energy:not(:disabled):not(.disabled):active:focus, .btn-smau-energy:not(:disabled):not(.disabled).active:focus,
    .show > .btn-smau-energy.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(152, 222, 46, 0.5); }

.btn-smau-mobility {
  color: #212529;
  background-color: #c3b8f9;
  border-color: #c3b8f9; }
  .btn-smau-mobility:hover {
    color: #212529;
    background-color: #a595f6;
    border-color: #9b89f5; }
  .btn-smau-mobility:focus, .btn-smau-mobility.focus {
    box-shadow: 0 0 0 0.2rem rgba(171, 162, 218, 0.5); }
  .btn-smau-mobility.disabled, .btn-smau-mobility:disabled {
    color: #212529;
    background-color: #c3b8f9;
    border-color: #c3b8f9; }
  .btn-smau-mobility:not(:disabled):not(.disabled):active, .btn-smau-mobility:not(:disabled):not(.disabled).active,
  .show > .btn-smau-mobility.dropdown-toggle {
    color: #212529;
    background-color: #9b89f5;
    border-color: #917df4; }
    .btn-smau-mobility:not(:disabled):not(.disabled):active:focus, .btn-smau-mobility:not(:disabled):not(.disabled).active:focus,
    .show > .btn-smau-mobility.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(171, 162, 218, 0.5); }

.btn-smau-hr {
  color: #212529;
  background-color: #ffdead;
  border-color: #ffdead; }
  .btn-smau-hr:hover {
    color: #212529;
    background-color: #ffcf87;
    border-color: #ffc97a; }
  .btn-smau-hr:focus, .btn-smau-hr.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 194, 153, 0.5); }
  .btn-smau-hr.disabled, .btn-smau-hr:disabled {
    color: #212529;
    background-color: #ffdead;
    border-color: #ffdead; }
  .btn-smau-hr:not(:disabled):not(.disabled):active, .btn-smau-hr:not(:disabled):not(.disabled).active,
  .show > .btn-smau-hr.dropdown-toggle {
    color: #212529;
    background-color: #ffc97a;
    border-color: #ffc46d; }
    .btn-smau-hr:not(:disabled):not(.disabled):active:focus, .btn-smau-hr:not(:disabled):not(.disabled).active:focus,
    .show > .btn-smau-hr.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 194, 153, 0.5); }

.btn-smau-sustainability {
  color: #212529;
  background-color: #89f5a0;
  border-color: #89f5a0; }
  .btn-smau-sustainability:hover {
    color: #212529;
    background-color: #66f284;
    border-color: #5af17a; }
  .btn-smau-sustainability:focus, .btn-smau-sustainability.focus {
    box-shadow: 0 0 0 0.2rem rgba(121, 214, 142, 0.5); }
  .btn-smau-sustainability.disabled, .btn-smau-sustainability:disabled {
    color: #212529;
    background-color: #89f5a0;
    border-color: #89f5a0; }
  .btn-smau-sustainability:not(:disabled):not(.disabled):active, .btn-smau-sustainability:not(:disabled):not(.disabled).active,
  .show > .btn-smau-sustainability.dropdown-toggle {
    color: #212529;
    background-color: #5af17a;
    border-color: #4ef071; }
    .btn-smau-sustainability:not(:disabled):not(.disabled):active:focus, .btn-smau-sustainability:not(:disabled):not(.disabled).active:focus,
    .show > .btn-smau-sustainability.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(121, 214, 142, 0.5); }

.btn-smau-artificial-intelligence {
  color: #fff;
  background-color: #173e87;
  border-color: #173e87; }
  .btn-smau-artificial-intelligence:hover {
    color: #fff;
    background-color: #112f66;
    border-color: #102a5b; }
  .btn-smau-artificial-intelligence:focus, .btn-smau-artificial-intelligence.focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 91, 153, 0.5); }
  .btn-smau-artificial-intelligence.disabled, .btn-smau-artificial-intelligence:disabled {
    color: #fff;
    background-color: #173e87;
    border-color: #173e87; }
  .btn-smau-artificial-intelligence:not(:disabled):not(.disabled):active, .btn-smau-artificial-intelligence:not(:disabled):not(.disabled).active,
  .show > .btn-smau-artificial-intelligence.dropdown-toggle {
    color: #fff;
    background-color: #102a5b;
    border-color: #0e2551; }
    .btn-smau-artificial-intelligence:not(:disabled):not(.disabled):active:focus, .btn-smau-artificial-intelligence:not(:disabled):not(.disabled).active:focus,
    .show > .btn-smau-artificial-intelligence.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 91, 153, 0.5); }

.btn-smau-emerging-technologies {
  color: #212529;
  background-color: #ff7bac;
  border-color: #ff7bac; }
  .btn-smau-emerging-technologies:hover {
    color: #fff;
    background-color: #ff5594;
    border-color: #ff488c; }
  .btn-smau-emerging-technologies:focus, .btn-smau-emerging-technologies.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 110, 152, 0.5); }
  .btn-smau-emerging-technologies.disabled, .btn-smau-emerging-technologies:disabled {
    color: #212529;
    background-color: #ff7bac;
    border-color: #ff7bac; }
  .btn-smau-emerging-technologies:not(:disabled):not(.disabled):active, .btn-smau-emerging-technologies:not(:disabled):not(.disabled).active,
  .show > .btn-smau-emerging-technologies.dropdown-toggle {
    color: #fff;
    background-color: #ff488c;
    border-color: #ff3b84; }
    .btn-smau-emerging-technologies:not(:disabled):not(.disabled):active:focus, .btn-smau-emerging-technologies:not(:disabled):not(.disabled).active:focus,
    .show > .btn-smau-emerging-technologies.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 110, 152, 0.5); }

.btn-outline-primary {
  color: #0386A3;
  border-color: #0386A3; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #0386A3;
    border-color: #0386A3; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(3, 134, 163, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #0386A3;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #0386A3;
    border-color: #0386A3; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(3, 134, 163, 0.5); }

.btn-outline-secondary {
  color: #344AF0;
  border-color: #344AF0; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #344AF0;
    border-color: #344AF0; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 74, 240, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #344AF0;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #344AF0;
    border-color: #344AF0; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 74, 240, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #F5F5F5;
  border-color: #F5F5F5; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #F5F5F5;
    border-color: #F5F5F5; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 245, 245, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #F5F5F5;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #F5F5F5;
    border-color: #F5F5F5; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(245, 245, 245, 0.5); }

.btn-outline-dark {
  color: #2E283D;
  border-color: #2E283D; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #2E283D;
    border-color: #2E283D; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(46, 40, 61, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #2E283D;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #2E283D;
    border-color: #2E283D; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(46, 40, 61, 0.5); }

.btn-outline-accent {
  color: #E2189C;
  border-color: #E2189C; }
  .btn-outline-accent:hover {
    color: #fff;
    background-color: #E2189C;
    border-color: #E2189C; }
  .btn-outline-accent:focus, .btn-outline-accent.focus {
    box-shadow: 0 0 0 0.2rem rgba(226, 24, 156, 0.5); }
  .btn-outline-accent.disabled, .btn-outline-accent:disabled {
    color: #E2189C;
    background-color: transparent; }
  .btn-outline-accent:not(:disabled):not(.disabled):active, .btn-outline-accent:not(:disabled):not(.disabled).active,
  .show > .btn-outline-accent.dropdown-toggle {
    color: #fff;
    background-color: #E2189C;
    border-color: #E2189C; }
    .btn-outline-accent:not(:disabled):not(.disabled):active:focus, .btn-outline-accent:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-accent.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(226, 24, 156, 0.5); }

.btn-outline-black {
  color: #222222;
  border-color: #222222; }
  .btn-outline-black:hover {
    color: #fff;
    background-color: #222222;
    border-color: #222222; }
  .btn-outline-black:focus, .btn-outline-black.focus {
    box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5); }
  .btn-outline-black.disabled, .btn-outline-black:disabled {
    color: #222222;
    background-color: transparent; }
  .btn-outline-black:not(:disabled):not(.disabled):active, .btn-outline-black:not(:disabled):not(.disabled).active,
  .show > .btn-outline-black.dropdown-toggle {
    color: #fff;
    background-color: #222222;
    border-color: #222222; }
    .btn-outline-black:not(:disabled):not(.disabled):active:focus, .btn-outline-black:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-black.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5); }

.btn-outline-green {
  color: #69C881;
  border-color: #69C881; }
  .btn-outline-green:hover {
    color: #212529;
    background-color: #69C881;
    border-color: #69C881; }
  .btn-outline-green:focus, .btn-outline-green.focus {
    box-shadow: 0 0 0 0.2rem rgba(105, 200, 129, 0.5); }
  .btn-outline-green.disabled, .btn-outline-green:disabled {
    color: #69C881;
    background-color: transparent; }
  .btn-outline-green:not(:disabled):not(.disabled):active, .btn-outline-green:not(:disabled):not(.disabled).active,
  .show > .btn-outline-green.dropdown-toggle {
    color: #212529;
    background-color: #69C881;
    border-color: #69C881; }
    .btn-outline-green:not(:disabled):not(.disabled):active:focus, .btn-outline-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(105, 200, 129, 0.5); }

.btn-outline-smau-agrifood {
  color: #0386A3;
  border-color: #0386A3; }
  .btn-outline-smau-agrifood:hover {
    color: #fff;
    background-color: #0386A3;
    border-color: #0386A3; }
  .btn-outline-smau-agrifood:focus, .btn-outline-smau-agrifood.focus {
    box-shadow: 0 0 0 0.2rem rgba(3, 134, 163, 0.5); }
  .btn-outline-smau-agrifood.disabled, .btn-outline-smau-agrifood:disabled {
    color: #0386A3;
    background-color: transparent; }
  .btn-outline-smau-agrifood:not(:disabled):not(.disabled):active, .btn-outline-smau-agrifood:not(:disabled):not(.disabled).active,
  .show > .btn-outline-smau-agrifood.dropdown-toggle {
    color: #fff;
    background-color: #0386A3;
    border-color: #0386A3; }
    .btn-outline-smau-agrifood:not(:disabled):not(.disabled):active:focus, .btn-outline-smau-agrifood:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-smau-agrifood.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(3, 134, 163, 0.5); }

.btn-outline-smau-digital-trends {
  color: #29B473;
  border-color: #29B473; }
  .btn-outline-smau-digital-trends:hover {
    color: #fff;
    background-color: #29B473;
    border-color: #29B473; }
  .btn-outline-smau-digital-trends:focus, .btn-outline-smau-digital-trends.focus {
    box-shadow: 0 0 0 0.2rem rgba(41, 180, 115, 0.5); }
  .btn-outline-smau-digital-trends.disabled, .btn-outline-smau-digital-trends:disabled {
    color: #29B473;
    background-color: transparent; }
  .btn-outline-smau-digital-trends:not(:disabled):not(.disabled):active, .btn-outline-smau-digital-trends:not(:disabled):not(.disabled).active,
  .show > .btn-outline-smau-digital-trends.dropdown-toggle {
    color: #fff;
    background-color: #29B473;
    border-color: #29B473; }
    .btn-outline-smau-digital-trends:not(:disabled):not(.disabled):active:focus, .btn-outline-smau-digital-trends:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-smau-digital-trends.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(41, 180, 115, 0.5); }

.btn-outline-smau-ict {
  color: #f28e1e;
  border-color: #f28e1e; }
  .btn-outline-smau-ict:hover {
    color: #212529;
    background-color: #f28e1e;
    border-color: #f28e1e; }
  .btn-outline-smau-ict:focus, .btn-outline-smau-ict.focus {
    box-shadow: 0 0 0 0.2rem rgba(242, 142, 30, 0.5); }
  .btn-outline-smau-ict.disabled, .btn-outline-smau-ict:disabled {
    color: #f28e1e;
    background-color: transparent; }
  .btn-outline-smau-ict:not(:disabled):not(.disabled):active, .btn-outline-smau-ict:not(:disabled):not(.disabled).active,
  .show > .btn-outline-smau-ict.dropdown-toggle {
    color: #212529;
    background-color: #f28e1e;
    border-color: #f28e1e; }
    .btn-outline-smau-ict:not(:disabled):not(.disabled):active:focus, .btn-outline-smau-ict:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-smau-ict.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(242, 142, 30, 0.5); }

.btn-outline-smau-industry {
  color: #4891CD;
  border-color: #4891CD; }
  .btn-outline-smau-industry:hover {
    color: #fff;
    background-color: #4891CD;
    border-color: #4891CD; }
  .btn-outline-smau-industry:focus, .btn-outline-smau-industry.focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 145, 205, 0.5); }
  .btn-outline-smau-industry.disabled, .btn-outline-smau-industry:disabled {
    color: #4891CD;
    background-color: transparent; }
  .btn-outline-smau-industry:not(:disabled):not(.disabled):active, .btn-outline-smau-industry:not(:disabled):not(.disabled).active,
  .show > .btn-outline-smau-industry.dropdown-toggle {
    color: #fff;
    background-color: #4891CD;
    border-color: #4891CD; }
    .btn-outline-smau-industry:not(:disabled):not(.disabled):active:focus, .btn-outline-smau-industry:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-smau-industry.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 145, 205, 0.5); }

.btn-outline-smau-international {
  color: #0F9EA5;
  border-color: #0F9EA5; }
  .btn-outline-smau-international:hover {
    color: #fff;
    background-color: #0F9EA5;
    border-color: #0F9EA5; }
  .btn-outline-smau-international:focus, .btn-outline-smau-international.focus {
    box-shadow: 0 0 0 0.2rem rgba(15, 158, 165, 0.5); }
  .btn-outline-smau-international.disabled, .btn-outline-smau-international:disabled {
    color: #0F9EA5;
    background-color: transparent; }
  .btn-outline-smau-international:not(:disabled):not(.disabled):active, .btn-outline-smau-international:not(:disabled):not(.disabled).active,
  .show > .btn-outline-smau-international.dropdown-toggle {
    color: #fff;
    background-color: #0F9EA5;
    border-color: #0F9EA5; }
    .btn-outline-smau-international:not(:disabled):not(.disabled):active:focus, .btn-outline-smau-international:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-smau-international.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(15, 158, 165, 0.5); }

.btn-outline-smau-mobile {
  color: #F6921E;
  border-color: #F6921E; }
  .btn-outline-smau-mobile:hover {
    color: #212529;
    background-color: #F6921E;
    border-color: #F6921E; }
  .btn-outline-smau-mobile:focus, .btn-outline-smau-mobile.focus {
    box-shadow: 0 0 0 0.2rem rgba(246, 146, 30, 0.5); }
  .btn-outline-smau-mobile.disabled, .btn-outline-smau-mobile:disabled {
    color: #F6921E;
    background-color: transparent; }
  .btn-outline-smau-mobile:not(:disabled):not(.disabled):active, .btn-outline-smau-mobile:not(:disabled):not(.disabled).active,
  .show > .btn-outline-smau-mobile.dropdown-toggle {
    color: #212529;
    background-color: #F6921E;
    border-color: #F6921E; }
    .btn-outline-smau-mobile:not(:disabled):not(.disabled):active:focus, .btn-outline-smau-mobile:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-smau-mobile.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(246, 146, 30, 0.5); }

.btn-outline-smau-open-innovations {
  color: #684cc8;
  border-color: #684cc8; }
  .btn-outline-smau-open-innovations:hover {
    color: #fff;
    background-color: #684cc8;
    border-color: #684cc8; }
  .btn-outline-smau-open-innovations:focus, .btn-outline-smau-open-innovations.focus {
    box-shadow: 0 0 0 0.2rem rgba(104, 76, 200, 0.5); }
  .btn-outline-smau-open-innovations.disabled, .btn-outline-smau-open-innovations:disabled {
    color: #684cc8;
    background-color: transparent; }
  .btn-outline-smau-open-innovations:not(:disabled):not(.disabled):active, .btn-outline-smau-open-innovations:not(:disabled):not(.disabled).active,
  .show > .btn-outline-smau-open-innovations.dropdown-toggle {
    color: #fff;
    background-color: #684cc8;
    border-color: #684cc8; }
    .btn-outline-smau-open-innovations:not(:disabled):not(.disabled):active:focus, .btn-outline-smau-open-innovations:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-smau-open-innovations.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(104, 76, 200, 0.5); }

.btn-outline-smau-trade-tourism {
  color: #146E96;
  border-color: #146E96; }
  .btn-outline-smau-trade-tourism:hover {
    color: #fff;
    background-color: #146E96;
    border-color: #146E96; }
  .btn-outline-smau-trade-tourism:focus, .btn-outline-smau-trade-tourism.focus {
    box-shadow: 0 0 0 0.2rem rgba(20, 110, 150, 0.5); }
  .btn-outline-smau-trade-tourism.disabled, .btn-outline-smau-trade-tourism:disabled {
    color: #146E96;
    background-color: transparent; }
  .btn-outline-smau-trade-tourism:not(:disabled):not(.disabled):active, .btn-outline-smau-trade-tourism:not(:disabled):not(.disabled).active,
  .show > .btn-outline-smau-trade-tourism.dropdown-toggle {
    color: #fff;
    background-color: #146E96;
    border-color: #146E96; }
    .btn-outline-smau-trade-tourism:not(:disabled):not(.disabled):active:focus, .btn-outline-smau-trade-tourism:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-smau-trade-tourism.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(20, 110, 150, 0.5); }

.btn-outline-smau-health {
  color: #ffd500;
  border-color: #ffd500; }
  .btn-outline-smau-health:hover {
    color: #212529;
    background-color: #ffd500;
    border-color: #ffd500; }
  .btn-outline-smau-health:focus, .btn-outline-smau-health.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 213, 0, 0.5); }
  .btn-outline-smau-health.disabled, .btn-outline-smau-health:disabled {
    color: #ffd500;
    background-color: transparent; }
  .btn-outline-smau-health:not(:disabled):not(.disabled):active, .btn-outline-smau-health:not(:disabled):not(.disabled).active,
  .show > .btn-outline-smau-health.dropdown-toggle {
    color: #212529;
    background-color: #ffd500;
    border-color: #ffd500; }
    .btn-outline-smau-health:not(:disabled):not(.disabled):active:focus, .btn-outline-smau-health:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-smau-health.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 213, 0, 0.5); }

.btn-outline-smau-open-inovation {
  color: #006bdc;
  border-color: #006bdc; }
  .btn-outline-smau-open-inovation:hover {
    color: #fff;
    background-color: #006bdc;
    border-color: #006bdc; }
  .btn-outline-smau-open-inovation:focus, .btn-outline-smau-open-inovation.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 107, 220, 0.5); }
  .btn-outline-smau-open-inovation.disabled, .btn-outline-smau-open-inovation:disabled {
    color: #006bdc;
    background-color: transparent; }
  .btn-outline-smau-open-inovation:not(:disabled):not(.disabled):active, .btn-outline-smau-open-inovation:not(:disabled):not(.disabled).active,
  .show > .btn-outline-smau-open-inovation.dropdown-toggle {
    color: #fff;
    background-color: #006bdc;
    border-color: #006bdc; }
    .btn-outline-smau-open-inovation:not(:disabled):not(.disabled):active:focus, .btn-outline-smau-open-inovation:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-smau-open-inovation.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 107, 220, 0.5); }

.btn-outline-smau-food-retail {
  color: #be4c49;
  border-color: #be4c49; }
  .btn-outline-smau-food-retail:hover {
    color: #fff;
    background-color: #be4c49;
    border-color: #be4c49; }
  .btn-outline-smau-food-retail:focus, .btn-outline-smau-food-retail.focus {
    box-shadow: 0 0 0 0.2rem rgba(190, 76, 73, 0.5); }
  .btn-outline-smau-food-retail.disabled, .btn-outline-smau-food-retail:disabled {
    color: #be4c49;
    background-color: transparent; }
  .btn-outline-smau-food-retail:not(:disabled):not(.disabled):active, .btn-outline-smau-food-retail:not(:disabled):not(.disabled).active,
  .show > .btn-outline-smau-food-retail.dropdown-toggle {
    color: #fff;
    background-color: #be4c49;
    border-color: #be4c49; }
    .btn-outline-smau-food-retail:not(:disabled):not(.disabled):active:focus, .btn-outline-smau-food-retail:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-smau-food-retail.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(190, 76, 73, 0.5); }

.btn-outline-smau-smart-manufacturing {
  color: #bedb65;
  border-color: #bedb65; }
  .btn-outline-smau-smart-manufacturing:hover {
    color: #212529;
    background-color: #bedb65;
    border-color: #bedb65; }
  .btn-outline-smau-smart-manufacturing:focus, .btn-outline-smau-smart-manufacturing.focus {
    box-shadow: 0 0 0 0.2rem rgba(190, 219, 101, 0.5); }
  .btn-outline-smau-smart-manufacturing.disabled, .btn-outline-smau-smart-manufacturing:disabled {
    color: #bedb65;
    background-color: transparent; }
  .btn-outline-smau-smart-manufacturing:not(:disabled):not(.disabled):active, .btn-outline-smau-smart-manufacturing:not(:disabled):not(.disabled).active,
  .show > .btn-outline-smau-smart-manufacturing.dropdown-toggle {
    color: #212529;
    background-color: #bedb65;
    border-color: #bedb65; }
    .btn-outline-smau-smart-manufacturing:not(:disabled):not(.disabled):active:focus, .btn-outline-smau-smart-manufacturing:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-smau-smart-manufacturing.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(190, 219, 101, 0.5); }

.btn-outline-smau-energy-environment {
  color: #62cced;
  border-color: #62cced; }
  .btn-outline-smau-energy-environment:hover {
    color: #212529;
    background-color: #62cced;
    border-color: #62cced; }
  .btn-outline-smau-energy-environment:focus, .btn-outline-smau-energy-environment.focus {
    box-shadow: 0 0 0 0.2rem rgba(98, 204, 237, 0.5); }
  .btn-outline-smau-energy-environment.disabled, .btn-outline-smau-energy-environment:disabled {
    color: #62cced;
    background-color: transparent; }
  .btn-outline-smau-energy-environment:not(:disabled):not(.disabled):active, .btn-outline-smau-energy-environment:not(:disabled):not(.disabled).active,
  .show > .btn-outline-smau-energy-environment.dropdown-toggle {
    color: #212529;
    background-color: #62cced;
    border-color: #62cced; }
    .btn-outline-smau-energy-environment:not(:disabled):not(.disabled):active:focus, .btn-outline-smau-energy-environment:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-smau-energy-environment.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(98, 204, 237, 0.5); }

.btn-outline-smau-smart-mobility {
  color: #2c388f;
  border-color: #2c388f; }
  .btn-outline-smau-smart-mobility:hover {
    color: #fff;
    background-color: #2c388f;
    border-color: #2c388f; }
  .btn-outline-smau-smart-mobility:focus, .btn-outline-smau-smart-mobility.focus {
    box-shadow: 0 0 0 0.2rem rgba(44, 56, 143, 0.5); }
  .btn-outline-smau-smart-mobility.disabled, .btn-outline-smau-smart-mobility:disabled {
    color: #2c388f;
    background-color: transparent; }
  .btn-outline-smau-smart-mobility:not(:disabled):not(.disabled):active, .btn-outline-smau-smart-mobility:not(:disabled):not(.disabled).active,
  .show > .btn-outline-smau-smart-mobility.dropdown-toggle {
    color: #fff;
    background-color: #2c388f;
    border-color: #2c388f; }
    .btn-outline-smau-smart-mobility:not(:disabled):not(.disabled):active:focus, .btn-outline-smau-smart-mobility:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-smau-smart-mobility.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(44, 56, 143, 0.5); }

.btn-outline-smau-e-health {
  color: #2ca377;
  border-color: #2ca377; }
  .btn-outline-smau-e-health:hover {
    color: #fff;
    background-color: #2ca377;
    border-color: #2ca377; }
  .btn-outline-smau-e-health:focus, .btn-outline-smau-e-health.focus {
    box-shadow: 0 0 0 0.2rem rgba(44, 163, 119, 0.5); }
  .btn-outline-smau-e-health.disabled, .btn-outline-smau-e-health:disabled {
    color: #2ca377;
    background-color: transparent; }
  .btn-outline-smau-e-health:not(:disabled):not(.disabled):active, .btn-outline-smau-e-health:not(:disabled):not(.disabled).active,
  .show > .btn-outline-smau-e-health.dropdown-toggle {
    color: #fff;
    background-color: #2ca377;
    border-color: #2ca377; }
    .btn-outline-smau-e-health:not(:disabled):not(.disabled):active:focus, .btn-outline-smau-e-health:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-smau-e-health.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(44, 163, 119, 0.5); }

.btn-outline-smau-alternative {
  color: #ea899a;
  border-color: #ea899a; }
  .btn-outline-smau-alternative:hover {
    color: #212529;
    background-color: #ea899a;
    border-color: #ea899a; }
  .btn-outline-smau-alternative:focus, .btn-outline-smau-alternative.focus {
    box-shadow: 0 0 0 0.2rem rgba(234, 137, 154, 0.5); }
  .btn-outline-smau-alternative.disabled, .btn-outline-smau-alternative:disabled {
    color: #ea899a;
    background-color: transparent; }
  .btn-outline-smau-alternative:not(:disabled):not(.disabled):active, .btn-outline-smau-alternative:not(:disabled):not(.disabled).active,
  .show > .btn-outline-smau-alternative.dropdown-toggle {
    color: #212529;
    background-color: #ea899a;
    border-color: #ea899a; }
    .btn-outline-smau-alternative:not(:disabled):not(.disabled):active:focus, .btn-outline-smau-alternative:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-smau-alternative.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(234, 137, 154, 0.5); }

.btn-outline-smau-energy {
  color: #adff2f;
  border-color: #adff2f; }
  .btn-outline-smau-energy:hover {
    color: #212529;
    background-color: #adff2f;
    border-color: #adff2f; }
  .btn-outline-smau-energy:focus, .btn-outline-smau-energy.focus {
    box-shadow: 0 0 0 0.2rem rgba(173, 255, 47, 0.5); }
  .btn-outline-smau-energy.disabled, .btn-outline-smau-energy:disabled {
    color: #adff2f;
    background-color: transparent; }
  .btn-outline-smau-energy:not(:disabled):not(.disabled):active, .btn-outline-smau-energy:not(:disabled):not(.disabled).active,
  .show > .btn-outline-smau-energy.dropdown-toggle {
    color: #212529;
    background-color: #adff2f;
    border-color: #adff2f; }
    .btn-outline-smau-energy:not(:disabled):not(.disabled):active:focus, .btn-outline-smau-energy:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-smau-energy.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(173, 255, 47, 0.5); }

.btn-outline-smau-mobility {
  color: #c3b8f9;
  border-color: #c3b8f9; }
  .btn-outline-smau-mobility:hover {
    color: #212529;
    background-color: #c3b8f9;
    border-color: #c3b8f9; }
  .btn-outline-smau-mobility:focus, .btn-outline-smau-mobility.focus {
    box-shadow: 0 0 0 0.2rem rgba(195, 184, 249, 0.5); }
  .btn-outline-smau-mobility.disabled, .btn-outline-smau-mobility:disabled {
    color: #c3b8f9;
    background-color: transparent; }
  .btn-outline-smau-mobility:not(:disabled):not(.disabled):active, .btn-outline-smau-mobility:not(:disabled):not(.disabled).active,
  .show > .btn-outline-smau-mobility.dropdown-toggle {
    color: #212529;
    background-color: #c3b8f9;
    border-color: #c3b8f9; }
    .btn-outline-smau-mobility:not(:disabled):not(.disabled):active:focus, .btn-outline-smau-mobility:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-smau-mobility.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(195, 184, 249, 0.5); }

.btn-outline-smau-hr {
  color: #ffdead;
  border-color: #ffdead; }
  .btn-outline-smau-hr:hover {
    color: #212529;
    background-color: #ffdead;
    border-color: #ffdead; }
  .btn-outline-smau-hr:focus, .btn-outline-smau-hr.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 222, 173, 0.5); }
  .btn-outline-smau-hr.disabled, .btn-outline-smau-hr:disabled {
    color: #ffdead;
    background-color: transparent; }
  .btn-outline-smau-hr:not(:disabled):not(.disabled):active, .btn-outline-smau-hr:not(:disabled):not(.disabled).active,
  .show > .btn-outline-smau-hr.dropdown-toggle {
    color: #212529;
    background-color: #ffdead;
    border-color: #ffdead; }
    .btn-outline-smau-hr:not(:disabled):not(.disabled):active:focus, .btn-outline-smau-hr:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-smau-hr.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 222, 173, 0.5); }

.btn-outline-smau-sustainability {
  color: #89f5a0;
  border-color: #89f5a0; }
  .btn-outline-smau-sustainability:hover {
    color: #212529;
    background-color: #89f5a0;
    border-color: #89f5a0; }
  .btn-outline-smau-sustainability:focus, .btn-outline-smau-sustainability.focus {
    box-shadow: 0 0 0 0.2rem rgba(137, 245, 160, 0.5); }
  .btn-outline-smau-sustainability.disabled, .btn-outline-smau-sustainability:disabled {
    color: #89f5a0;
    background-color: transparent; }
  .btn-outline-smau-sustainability:not(:disabled):not(.disabled):active, .btn-outline-smau-sustainability:not(:disabled):not(.disabled).active,
  .show > .btn-outline-smau-sustainability.dropdown-toggle {
    color: #212529;
    background-color: #89f5a0;
    border-color: #89f5a0; }
    .btn-outline-smau-sustainability:not(:disabled):not(.disabled):active:focus, .btn-outline-smau-sustainability:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-smau-sustainability.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(137, 245, 160, 0.5); }

.btn-outline-smau-artificial-intelligence {
  color: #173e87;
  border-color: #173e87; }
  .btn-outline-smau-artificial-intelligence:hover {
    color: #fff;
    background-color: #173e87;
    border-color: #173e87; }
  .btn-outline-smau-artificial-intelligence:focus, .btn-outline-smau-artificial-intelligence.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 62, 135, 0.5); }
  .btn-outline-smau-artificial-intelligence.disabled, .btn-outline-smau-artificial-intelligence:disabled {
    color: #173e87;
    background-color: transparent; }
  .btn-outline-smau-artificial-intelligence:not(:disabled):not(.disabled):active, .btn-outline-smau-artificial-intelligence:not(:disabled):not(.disabled).active,
  .show > .btn-outline-smau-artificial-intelligence.dropdown-toggle {
    color: #fff;
    background-color: #173e87;
    border-color: #173e87; }
    .btn-outline-smau-artificial-intelligence:not(:disabled):not(.disabled):active:focus, .btn-outline-smau-artificial-intelligence:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-smau-artificial-intelligence.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 62, 135, 0.5); }

.btn-outline-smau-emerging-technologies {
  color: #ff7bac;
  border-color: #ff7bac; }
  .btn-outline-smau-emerging-technologies:hover {
    color: #212529;
    background-color: #ff7bac;
    border-color: #ff7bac; }
  .btn-outline-smau-emerging-technologies:focus, .btn-outline-smau-emerging-technologies.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 123, 172, 0.5); }
  .btn-outline-smau-emerging-technologies.disabled, .btn-outline-smau-emerging-technologies:disabled {
    color: #ff7bac;
    background-color: transparent; }
  .btn-outline-smau-emerging-technologies:not(:disabled):not(.disabled):active, .btn-outline-smau-emerging-technologies:not(:disabled):not(.disabled).active,
  .show > .btn-outline-smau-emerging-technologies.dropdown-toggle {
    color: #212529;
    background-color: #ff7bac;
    border-color: #ff7bac; }
    .btn-outline-smau-emerging-technologies:not(:disabled):not(.disabled):active:focus, .btn-outline-smau-emerging-technologies:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-smau-emerging-technologies.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 123, 172, 0.5); }

.btn-link {
  font-weight: 400;
  color: #0386A3;
  text-decoration: none; }
  .btn-link:hover {
    color: #024858;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 320px) {
  .dropdown-menu-xs-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xs-right {
    right: 0;
    left: auto; } }

@media (min-width: 413px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

@media (min-width: 1610px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #0386A3; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #0386A3;
    background-color: #0386A3; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(3, 134, 163, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #2ad5fb; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #5cdffc;
    border-color: #5cdffc; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #0386A3;
  background-color: #0386A3; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(3, 134, 163, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(3, 134, 163, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(3, 134, 163, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(3, 134, 163, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #2ad5fb;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(3, 134, 163, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #2ad5fb;
    box-shadow: 0 0 0 0.2rem rgba(3, 134, 163, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(3, 134, 163, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(3, 134, 163, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(3, 134, 163, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #0386A3;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #5cdffc; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #0386A3;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #5cdffc; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #0386A3;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #5cdffc; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0386A3; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 319.98px) {
  .navbar-expand-xs > .container,
  .navbar-expand-xs > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 320px) {
  .navbar-expand-xs {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xs .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xs .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xs .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xs > .container,
    .navbar-expand-xs > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xs .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xs .navbar-toggler {
      display: none; } }

@media (max-width: 412.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 413px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

@media (max-width: 1609.98px) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1610px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl > .container,
    .navbar-expand-xxl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 413px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 413px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 413px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #0386A3;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #024858;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(3, 134, 163, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #0386A3;
  border-color: #0386A3; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #0386A3; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #025d71; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(3, 134, 163, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #344AF0; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #1129e0; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 74, 240, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #F5F5F5; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: gainsboro; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(245, 245, 245, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #2E283D; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #17141e; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(46, 40, 61, 0.5); }

.badge-accent {
  color: #fff;
  background-color: #E2189C; }
  a.badge-accent:hover, a.badge-accent:focus {
    color: #fff;
    background-color: #b4137c; }
  a.badge-accent:focus, a.badge-accent.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(226, 24, 156, 0.5); }

.badge-black {
  color: #fff;
  background-color: #222222; }
  a.badge-black:hover, a.badge-black:focus {
    color: #fff;
    background-color: #090909; }
  a.badge-black:focus, a.badge-black.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5); }

.badge-green {
  color: #212529;
  background-color: #69C881; }
  a.badge-green:hover, a.badge-green:focus {
    color: #212529;
    background-color: #44ba62; }
  a.badge-green:focus, a.badge-green.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(105, 200, 129, 0.5); }

.badge-smau-agrifood {
  color: #fff;
  background-color: #0386A3; }
  a.badge-smau-agrifood:hover, a.badge-smau-agrifood:focus {
    color: #fff;
    background-color: #025d71; }
  a.badge-smau-agrifood:focus, a.badge-smau-agrifood.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(3, 134, 163, 0.5); }

.badge-smau-digital-trends {
  color: #fff;
  background-color: #29B473; }
  a.badge-smau-digital-trends:hover, a.badge-smau-digital-trends:focus {
    color: #fff;
    background-color: #208a58; }
  a.badge-smau-digital-trends:focus, a.badge-smau-digital-trends.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(41, 180, 115, 0.5); }

.badge-smau-ict {
  color: #212529;
  background-color: #f28e1e; }
  a.badge-smau-ict:hover, a.badge-smau-ict:focus {
    color: #212529;
    background-color: #d1740c; }
  a.badge-smau-ict:focus, a.badge-smau-ict.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(242, 142, 30, 0.5); }

.badge-smau-industry {
  color: #fff;
  background-color: #4891CD; }
  a.badge-smau-industry:hover, a.badge-smau-industry:focus {
    color: #fff;
    background-color: #3077b2; }
  a.badge-smau-industry:focus, a.badge-smau-industry.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(72, 145, 205, 0.5); }

.badge-smau-international {
  color: #fff;
  background-color: #0F9EA5; }
  a.badge-smau-international:hover, a.badge-smau-international:focus {
    color: #fff;
    background-color: #0b7176; }
  a.badge-smau-international:focus, a.badge-smau-international.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(15, 158, 165, 0.5); }

.badge-smau-mobile {
  color: #212529;
  background-color: #F6921E; }
  a.badge-smau-mobile:hover, a.badge-smau-mobile:focus {
    color: #212529;
    background-color: #d87809; }
  a.badge-smau-mobile:focus, a.badge-smau-mobile.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(246, 146, 30, 0.5); }

.badge-smau-open-innovations {
  color: #fff;
  background-color: #684cc8; }
  a.badge-smau-open-innovations:hover, a.badge-smau-open-innovations:focus {
    color: #fff;
    background-color: #5035ac; }
  a.badge-smau-open-innovations:focus, a.badge-smau-open-innovations.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(104, 76, 200, 0.5); }

.badge-smau-trade-tourism {
  color: #fff;
  background-color: #146E96; }
  a.badge-smau-trade-tourism:hover, a.badge-smau-trade-tourism:focus {
    color: #fff;
    background-color: #0e4d69; }
  a.badge-smau-trade-tourism:focus, a.badge-smau-trade-tourism.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(20, 110, 150, 0.5); }

.badge-smau-health {
  color: #212529;
  background-color: #ffd500; }
  a.badge-smau-health:hover, a.badge-smau-health:focus {
    color: #212529;
    background-color: #ccaa00; }
  a.badge-smau-health:focus, a.badge-smau-health.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 213, 0, 0.5); }

.badge-smau-open-inovation {
  color: #fff;
  background-color: #006bdc; }
  a.badge-smau-open-inovation:hover, a.badge-smau-open-inovation:focus {
    color: #fff;
    background-color: #0052a9; }
  a.badge-smau-open-inovation:focus, a.badge-smau-open-inovation.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 107, 220, 0.5); }

.badge-smau-food-retail {
  color: #fff;
  background-color: #be4c49; }
  a.badge-smau-food-retail:hover, a.badge-smau-food-retail:focus {
    color: #fff;
    background-color: #9c3a38; }
  a.badge-smau-food-retail:focus, a.badge-smau-food-retail.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(190, 76, 73, 0.5); }

.badge-smau-smart-manufacturing {
  color: #212529;
  background-color: #bedb65; }
  a.badge-smau-smart-manufacturing:hover, a.badge-smau-smart-manufacturing:focus {
    color: #212529;
    background-color: #add13c; }
  a.badge-smau-smart-manufacturing:focus, a.badge-smau-smart-manufacturing.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(190, 219, 101, 0.5); }

.badge-smau-energy-environment {
  color: #212529;
  background-color: #62cced; }
  a.badge-smau-energy-environment:hover, a.badge-smau-energy-environment:focus {
    color: #212529;
    background-color: #34bde8; }
  a.badge-smau-energy-environment:focus, a.badge-smau-energy-environment.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(98, 204, 237, 0.5); }

.badge-smau-smart-mobility {
  color: #fff;
  background-color: #2c388f; }
  a.badge-smau-smart-mobility:hover, a.badge-smau-smart-mobility:focus {
    color: #fff;
    background-color: #202968; }
  a.badge-smau-smart-mobility:focus, a.badge-smau-smart-mobility.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(44, 56, 143, 0.5); }

.badge-smau-e-health {
  color: #fff;
  background-color: #2ca377; }
  a.badge-smau-e-health:hover, a.badge-smau-e-health:focus {
    color: #fff;
    background-color: #217b5a; }
  a.badge-smau-e-health:focus, a.badge-smau-e-health.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(44, 163, 119, 0.5); }

.badge-smau-alternative {
  color: #212529;
  background-color: #ea899a; }
  a.badge-smau-alternative:hover, a.badge-smau-alternative:focus {
    color: #212529;
    background-color: #e25e75; }
  a.badge-smau-alternative:focus, a.badge-smau-alternative.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(234, 137, 154, 0.5); }

.badge-smau-energy {
  color: #212529;
  background-color: #adff2f; }
  a.badge-smau-energy:hover, a.badge-smau-energy:focus {
    color: #212529;
    background-color: #98fb00; }
  a.badge-smau-energy:focus, a.badge-smau-energy.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(173, 255, 47, 0.5); }

.badge-smau-mobility {
  color: #212529;
  background-color: #c3b8f9; }
  a.badge-smau-mobility:hover, a.badge-smau-mobility:focus {
    color: #212529;
    background-color: #9b89f5; }
  a.badge-smau-mobility:focus, a.badge-smau-mobility.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(195, 184, 249, 0.5); }

.badge-smau-hr {
  color: #212529;
  background-color: #ffdead; }
  a.badge-smau-hr:hover, a.badge-smau-hr:focus {
    color: #212529;
    background-color: #ffc97a; }
  a.badge-smau-hr:focus, a.badge-smau-hr.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 222, 173, 0.5); }

.badge-smau-sustainability {
  color: #212529;
  background-color: #89f5a0; }
  a.badge-smau-sustainability:hover, a.badge-smau-sustainability:focus {
    color: #212529;
    background-color: #5af17a; }
  a.badge-smau-sustainability:focus, a.badge-smau-sustainability.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(137, 245, 160, 0.5); }

.badge-smau-artificial-intelligence {
  color: #fff;
  background-color: #173e87; }
  a.badge-smau-artificial-intelligence:hover, a.badge-smau-artificial-intelligence:focus {
    color: #fff;
    background-color: #102a5b; }
  a.badge-smau-artificial-intelligence:focus, a.badge-smau-artificial-intelligence.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 62, 135, 0.5); }

.badge-smau-emerging-technologies {
  color: #212529;
  background-color: #ff7bac; }
  a.badge-smau-emerging-technologies:hover, a.badge-smau-emerging-technologies:focus {
    color: #212529;
    background-color: #ff488c; }
  a.badge-smau-emerging-technologies:focus, a.badge-smau-emerging-technologies.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 123, 172, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 413px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #125665;
  background-color: #cde7ed;
  border-color: #b8dde5; }
  .alert-primary hr {
    border-top-color: #a5d4de; }
  .alert-primary .alert-link {
    color: #0a313a; }

.alert-secondary {
  color: #2b378d;
  background-color: #d6dbfc;
  border-color: #c6ccfb; }
  .alert-secondary hr {
    border-top-color: #aeb7f9; }
  .alert-secondary .alert-link {
    color: #1f2866; }

.alert-success {
  color: #256734;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #184121; }

.alert-info {
  color: #1c6570;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #124047; }

.alert-warning {
  color: #957514;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #68520e; }

.alert-danger {
  color: #832c34;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #5d1f25; }

.alert-light {
  color: #909090;
  background-color: #fdfdfd;
  border-color: #fcfcfc; }
  .alert-light hr {
    border-top-color: #efefef; }
  .alert-light .alert-link {
    color: #777777; }

.alert-dark {
  color: #282530;
  background-color: #d5d4d8;
  border-color: #c4c3c9; }
  .alert-dark hr {
    border-top-color: #b7b6bd; }
  .alert-dark .alert-link {
    color: #100f13; }

.alert-accent {
  color: #861d61;
  background-color: #f9d1eb;
  border-color: #f7bee3; }
  .alert-accent hr {
    border-top-color: #f4a7d9; }
  .alert-accent .alert-link {
    color: #5c1443; }

.alert-black {
  color: #222222;
  background-color: lightgray;
  border-color: #c1c1c1; }
  .alert-black hr {
    border-top-color: #b4b4b4; }
  .alert-black .alert-link {
    color: #090909; }

.alert-green {
  color: #477853;
  background-color: #e1f4e6;
  border-color: #d5f0dc; }
  .alert-green hr {
    border-top-color: #c2e9cc; }
  .alert-green .alert-link {
    color: #34583d; }

.alert-smau-agrifood {
  color: #125665;
  background-color: #cde7ed;
  border-color: #b8dde5; }
  .alert-smau-agrifood hr {
    border-top-color: #a5d4de; }
  .alert-smau-agrifood .alert-link {
    color: #0a313a; }

.alert-smau-digital-trends {
  color: #266e4c;
  background-color: #d4f0e3;
  border-color: #c3ead8; }
  .alert-smau-digital-trends hr {
    border-top-color: #b0e3cc; }
  .alert-smau-digital-trends .alert-link {
    color: #194832; }

.alert-smau-ict {
  color: #8e5a20;
  background-color: #fce8d2;
  border-color: #fbdfc0; }
  .alert-smau-ict hr {
    border-top-color: #f9d3a8; }
  .alert-smau-ict .alert-link {
    color: #644017; }

.alert-smau-industry {
  color: #365c7b;
  background-color: #dae9f5;
  border-color: #cce0f1; }
  .alert-smau-industry hr {
    border-top-color: #b8d4ec; }
  .alert-smau-industry .alert-link {
    color: #264158; }

.alert-smau-international {
  color: #186266;
  background-color: #cfeced;
  border-color: #bce4e6; }
  .alert-smau-international hr {
    border-top-color: #a9dddf; }
  .alert-smau-international .alert-link {
    color: #0e3a3d; }

.alert-smau-mobile {
  color: #905c20;
  background-color: #fde9d2;
  border-color: #fce0c0; }
  .alert-smau-mobile hr {
    border-top-color: #fbd4a8; }
  .alert-smau-mobile .alert-link {
    color: #664117; }

.alert-smau-open-innovations {
  color: #463878;
  background-color: #e1dbf4;
  border-color: #d5cdf0; }
  .alert-smau-open-innovations hr {
    border-top-color: #c5b9ea; }
  .alert-smau-open-innovations .alert-link {
    color: #322855; }

.alert-smau-trade-tourism {
  color: #1b4a5e;
  background-color: #d0e2ea;
  border-color: #bdd6e2; }
  .alert-smau-trade-tourism hr {
    border-top-color: #abcbda; }
  .alert-smau-trade-tourism .alert-link {
    color: #102b36; }

.alert-smau-health {
  color: #957f10;
  background-color: #fff7cc;
  border-color: #fff3b8; }
  .alert-smau-health hr {
    border-top-color: #ffef9f; }
  .alert-smau-health .alert-link {
    color: #67580b; }

.alert-smau-open-inovation {
  color: #104883;
  background-color: #cce1f8;
  border-color: #b8d6f5; }
  .alert-smau-open-inovation hr {
    border-top-color: #a2c9f2; }
  .alert-smau-open-inovation .alert-link {
    color: #0a2f56; }

.alert-smau-food-retail {
  color: #733836;
  background-color: #f2dbdb;
  border-color: #edcdcc; }
  .alert-smau-food-retail hr {
    border-top-color: #e6bbb9; }
  .alert-smau-food-retail .alert-link {
    color: #502726; }

.alert-smau-smart-manufacturing {
  color: #738245;
  background-color: #f2f8e0;
  border-color: #edf5d4; }
  .alert-smau-smart-manufacturing hr {
    border-top-color: #e4f0bf; }
  .alert-smau-smart-manufacturing .alert-link {
    color: #566133; }

.alert-smau-energy-environment {
  color: #437a8c;
  background-color: #e0f5fb;
  border-color: #d3f1fa; }
  .alert-smau-energy-environment hr {
    border-top-color: #bceaf7; }
  .alert-smau-energy-environment .alert-link {
    color: #325c6a; }

.alert-smau-smart-mobility {
  color: #272d5b;
  background-color: #d5d7e9;
  border-color: #c4c7e0; }
  .alert-smau-smart-mobility hr {
    border-top-color: #b3b7d7; }
  .alert-smau-smart-mobility .alert-link {
    color: #181b37; }

.alert-smau-e-health {
  color: #27654e;
  background-color: #d5ede4;
  border-color: #c4e5d9; }
  .alert-smau-e-health hr {
    border-top-color: #b2ddce; }
  .alert-smau-e-health .alert-link {
    color: #194032; }

.alert-smau-alternative {
  color: #8a5860;
  background-color: #fbe7eb;
  border-color: #f9dee3; }
  .alert-smau-alternative hr {
    border-top-color: #f5c8d1; }
  .alert-smau-alternative .alert-link {
    color: #6b444a; }

.alert-smau-energy {
  color: #6a9529;
  background-color: #efffd5;
  border-color: #e8ffc5; }
  .alert-smau-energy hr {
    border-top-color: #deffac; }
  .alert-smau-energy .alert-link {
    color: #4e6d1e; }

.alert-smau-mobility {
  color: #767092;
  background-color: #f3f1fe;
  border-color: #eeebfd; }
  .alert-smau-mobility hr {
    border-top-color: #dad4fb; }
  .alert-smau-mobility .alert-link {
    color: #5e5a75; }

.alert-smau-hr {
  color: #95846a;
  background-color: #fff8ef;
  border-color: #fff6e8; }
  .alert-smau-hr hr {
    border-top-color: #ffeccf; }
  .alert-smau-hr .alert-link {
    color: #776a55; }

.alert-smau-sustainability {
  color: #589064;
  background-color: #e7fdec;
  border-color: #defce4; }
  .alert-smau-sustainability hr {
    border-top-color: #c7fad1; }
  .alert-smau-sustainability .alert-link {
    color: #45704e; }

.alert-smau-artificial-intelligence {
  color: #1c3157;
  background-color: #d1d8e7;
  border-color: #bec9dd; }
  .alert-smau-artificial-intelligence hr {
    border-top-color: #adbbd4; }
  .alert-smau-artificial-intelligence .alert-link {
    color: #101b30; }

.alert-smau-emerging-technologies {
  color: #95506a;
  background-color: #ffe5ee;
  border-color: #ffdae8; }
  .alert-smau-emerging-technologies hr {
    border-top-color: #ffc1d8; }
  .alert-smau-emerging-technologies .alert-link {
    color: #743e52; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0386A3;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #0386A3;
    border-color: #0386A3; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }

@media (min-width: 320px) {
  .list-group-horizontal-xs {
    flex-direction: row; }
    .list-group-horizontal-xs .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xs .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xs .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 413px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1610px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xxl .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xxl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #125665;
  background-color: #b8dde5; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #125665;
    background-color: #a5d4de; }
  .list-group-item-primary.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #125665;
    border-color: #125665; }

.list-group-item-secondary {
  color: #2b378d;
  background-color: #c6ccfb; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #2b378d;
    background-color: #aeb7f9; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #2b378d;
    border-color: #2b378d; }

.list-group-item-success {
  color: #256734;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #256734;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #256734;
    border-color: #256734; }

.list-group-item-info {
  color: #1c6570;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #1c6570;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #1c6570;
    border-color: #1c6570; }

.list-group-item-warning {
  color: #957514;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #957514;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #957514;
    border-color: #957514; }

.list-group-item-danger {
  color: #832c34;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #832c34;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #832c34;
    border-color: #832c34; }

.list-group-item-light {
  color: #909090;
  background-color: #fcfcfc; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #909090;
    background-color: #efefef; }
  .list-group-item-light.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #909090;
    border-color: #909090; }

.list-group-item-dark {
  color: #282530;
  background-color: #c4c3c9; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #282530;
    background-color: #b7b6bd; }
  .list-group-item-dark.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #282530;
    border-color: #282530; }

.list-group-item-accent {
  color: #861d61;
  background-color: #f7bee3; }
  .list-group-item-accent.list-group-item-action:hover, .list-group-item-accent.list-group-item-action:focus {
    color: #861d61;
    background-color: #f4a7d9; }
  .list-group-item-accent.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #861d61;
    border-color: #861d61; }

.list-group-item-black {
  color: #222222;
  background-color: #c1c1c1; }
  .list-group-item-black.list-group-item-action:hover, .list-group-item-black.list-group-item-action:focus {
    color: #222222;
    background-color: #b4b4b4; }
  .list-group-item-black.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #222222;
    border-color: #222222; }

.list-group-item-green {
  color: #477853;
  background-color: #d5f0dc; }
  .list-group-item-green.list-group-item-action:hover, .list-group-item-green.list-group-item-action:focus {
    color: #477853;
    background-color: #c2e9cc; }
  .list-group-item-green.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #477853;
    border-color: #477853; }

.list-group-item-smau-agrifood {
  color: #125665;
  background-color: #b8dde5; }
  .list-group-item-smau-agrifood.list-group-item-action:hover, .list-group-item-smau-agrifood.list-group-item-action:focus {
    color: #125665;
    background-color: #a5d4de; }
  .list-group-item-smau-agrifood.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #125665;
    border-color: #125665; }

.list-group-item-smau-digital-trends {
  color: #266e4c;
  background-color: #c3ead8; }
  .list-group-item-smau-digital-trends.list-group-item-action:hover, .list-group-item-smau-digital-trends.list-group-item-action:focus {
    color: #266e4c;
    background-color: #b0e3cc; }
  .list-group-item-smau-digital-trends.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #266e4c;
    border-color: #266e4c; }

.list-group-item-smau-ict {
  color: #8e5a20;
  background-color: #fbdfc0; }
  .list-group-item-smau-ict.list-group-item-action:hover, .list-group-item-smau-ict.list-group-item-action:focus {
    color: #8e5a20;
    background-color: #f9d3a8; }
  .list-group-item-smau-ict.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #8e5a20;
    border-color: #8e5a20; }

.list-group-item-smau-industry {
  color: #365c7b;
  background-color: #cce0f1; }
  .list-group-item-smau-industry.list-group-item-action:hover, .list-group-item-smau-industry.list-group-item-action:focus {
    color: #365c7b;
    background-color: #b8d4ec; }
  .list-group-item-smau-industry.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #365c7b;
    border-color: #365c7b; }

.list-group-item-smau-international {
  color: #186266;
  background-color: #bce4e6; }
  .list-group-item-smau-international.list-group-item-action:hover, .list-group-item-smau-international.list-group-item-action:focus {
    color: #186266;
    background-color: #a9dddf; }
  .list-group-item-smau-international.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #186266;
    border-color: #186266; }

.list-group-item-smau-mobile {
  color: #905c20;
  background-color: #fce0c0; }
  .list-group-item-smau-mobile.list-group-item-action:hover, .list-group-item-smau-mobile.list-group-item-action:focus {
    color: #905c20;
    background-color: #fbd4a8; }
  .list-group-item-smau-mobile.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #905c20;
    border-color: #905c20; }

.list-group-item-smau-open-innovations {
  color: #463878;
  background-color: #d5cdf0; }
  .list-group-item-smau-open-innovations.list-group-item-action:hover, .list-group-item-smau-open-innovations.list-group-item-action:focus {
    color: #463878;
    background-color: #c5b9ea; }
  .list-group-item-smau-open-innovations.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #463878;
    border-color: #463878; }

.list-group-item-smau-trade-tourism {
  color: #1b4a5e;
  background-color: #bdd6e2; }
  .list-group-item-smau-trade-tourism.list-group-item-action:hover, .list-group-item-smau-trade-tourism.list-group-item-action:focus {
    color: #1b4a5e;
    background-color: #abcbda; }
  .list-group-item-smau-trade-tourism.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #1b4a5e;
    border-color: #1b4a5e; }

.list-group-item-smau-health {
  color: #957f10;
  background-color: #fff3b8; }
  .list-group-item-smau-health.list-group-item-action:hover, .list-group-item-smau-health.list-group-item-action:focus {
    color: #957f10;
    background-color: #ffef9f; }
  .list-group-item-smau-health.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #957f10;
    border-color: #957f10; }

.list-group-item-smau-open-inovation {
  color: #104883;
  background-color: #b8d6f5; }
  .list-group-item-smau-open-inovation.list-group-item-action:hover, .list-group-item-smau-open-inovation.list-group-item-action:focus {
    color: #104883;
    background-color: #a2c9f2; }
  .list-group-item-smau-open-inovation.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #104883;
    border-color: #104883; }

.list-group-item-smau-food-retail {
  color: #733836;
  background-color: #edcdcc; }
  .list-group-item-smau-food-retail.list-group-item-action:hover, .list-group-item-smau-food-retail.list-group-item-action:focus {
    color: #733836;
    background-color: #e6bbb9; }
  .list-group-item-smau-food-retail.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #733836;
    border-color: #733836; }

.list-group-item-smau-smart-manufacturing {
  color: #738245;
  background-color: #edf5d4; }
  .list-group-item-smau-smart-manufacturing.list-group-item-action:hover, .list-group-item-smau-smart-manufacturing.list-group-item-action:focus {
    color: #738245;
    background-color: #e4f0bf; }
  .list-group-item-smau-smart-manufacturing.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #738245;
    border-color: #738245; }

.list-group-item-smau-energy-environment {
  color: #437a8c;
  background-color: #d3f1fa; }
  .list-group-item-smau-energy-environment.list-group-item-action:hover, .list-group-item-smau-energy-environment.list-group-item-action:focus {
    color: #437a8c;
    background-color: #bceaf7; }
  .list-group-item-smau-energy-environment.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #437a8c;
    border-color: #437a8c; }

.list-group-item-smau-smart-mobility {
  color: #272d5b;
  background-color: #c4c7e0; }
  .list-group-item-smau-smart-mobility.list-group-item-action:hover, .list-group-item-smau-smart-mobility.list-group-item-action:focus {
    color: #272d5b;
    background-color: #b3b7d7; }
  .list-group-item-smau-smart-mobility.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #272d5b;
    border-color: #272d5b; }

.list-group-item-smau-e-health {
  color: #27654e;
  background-color: #c4e5d9; }
  .list-group-item-smau-e-health.list-group-item-action:hover, .list-group-item-smau-e-health.list-group-item-action:focus {
    color: #27654e;
    background-color: #b2ddce; }
  .list-group-item-smau-e-health.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #27654e;
    border-color: #27654e; }

.list-group-item-smau-alternative {
  color: #8a5860;
  background-color: #f9dee3; }
  .list-group-item-smau-alternative.list-group-item-action:hover, .list-group-item-smau-alternative.list-group-item-action:focus {
    color: #8a5860;
    background-color: #f5c8d1; }
  .list-group-item-smau-alternative.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #8a5860;
    border-color: #8a5860; }

.list-group-item-smau-energy {
  color: #6a9529;
  background-color: #e8ffc5; }
  .list-group-item-smau-energy.list-group-item-action:hover, .list-group-item-smau-energy.list-group-item-action:focus {
    color: #6a9529;
    background-color: #deffac; }
  .list-group-item-smau-energy.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #6a9529;
    border-color: #6a9529; }

.list-group-item-smau-mobility {
  color: #767092;
  background-color: #eeebfd; }
  .list-group-item-smau-mobility.list-group-item-action:hover, .list-group-item-smau-mobility.list-group-item-action:focus {
    color: #767092;
    background-color: #dad4fb; }
  .list-group-item-smau-mobility.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #767092;
    border-color: #767092; }

.list-group-item-smau-hr {
  color: #95846a;
  background-color: #fff6e8; }
  .list-group-item-smau-hr.list-group-item-action:hover, .list-group-item-smau-hr.list-group-item-action:focus {
    color: #95846a;
    background-color: #ffeccf; }
  .list-group-item-smau-hr.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #95846a;
    border-color: #95846a; }

.list-group-item-smau-sustainability {
  color: #589064;
  background-color: #defce4; }
  .list-group-item-smau-sustainability.list-group-item-action:hover, .list-group-item-smau-sustainability.list-group-item-action:focus {
    color: #589064;
    background-color: #c7fad1; }
  .list-group-item-smau-sustainability.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #589064;
    border-color: #589064; }

.list-group-item-smau-artificial-intelligence {
  color: #1c3157;
  background-color: #bec9dd; }
  .list-group-item-smau-artificial-intelligence.list-group-item-action:hover, .list-group-item-smau-artificial-intelligence.list-group-item-action:focus {
    color: #1c3157;
    background-color: #adbbd4; }
  .list-group-item-smau-artificial-intelligence.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #1c3157;
    border-color: #1c3157; }

.list-group-item-smau-emerging-technologies {
  color: #95506a;
  background-color: #ffdae8; }
  .list-group-item-smau-emerging-technologies.list-group-item-action:hover, .list-group-item-smau-emerging-technologies.list-group-item-action:focus {
    color: #95506a;
    background-color: #ffc1d8; }
  .list-group-item-smau-emerging-technologies.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #95506a;
    border-color: #95506a; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 413px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #0386A3 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #025d71 !important; }

.bg-secondary {
  background-color: #344AF0 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #1129e0 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #F5F5F5 !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: gainsboro !important; }

.bg-dark {
  background-color: #2E283D !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #17141e !important; }

.bg-accent {
  background-color: #E2189C !important; }

a.bg-accent:hover, a.bg-accent:focus,
button.bg-accent:hover,
button.bg-accent:focus {
  background-color: #b4137c !important; }

.bg-black {
  background-color: #222222 !important; }

a.bg-black:hover, a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: #090909 !important; }

.bg-green {
  background-color: #69C881 !important; }

a.bg-green:hover, a.bg-green:focus,
button.bg-green:hover,
button.bg-green:focus {
  background-color: #44ba62 !important; }

.bg-smau-agrifood {
  background-color: #0386A3 !important; }

a.bg-smau-agrifood:hover, a.bg-smau-agrifood:focus,
button.bg-smau-agrifood:hover,
button.bg-smau-agrifood:focus {
  background-color: #025d71 !important; }

.bg-smau-digital-trends {
  background-color: #29B473 !important; }

a.bg-smau-digital-trends:hover, a.bg-smau-digital-trends:focus,
button.bg-smau-digital-trends:hover,
button.bg-smau-digital-trends:focus {
  background-color: #208a58 !important; }

.bg-smau-ict {
  background-color: #f28e1e !important; }

a.bg-smau-ict:hover, a.bg-smau-ict:focus,
button.bg-smau-ict:hover,
button.bg-smau-ict:focus {
  background-color: #d1740c !important; }

.bg-smau-industry {
  background-color: #4891CD !important; }

a.bg-smau-industry:hover, a.bg-smau-industry:focus,
button.bg-smau-industry:hover,
button.bg-smau-industry:focus {
  background-color: #3077b2 !important; }

.bg-smau-international {
  background-color: #0F9EA5 !important; }

a.bg-smau-international:hover, a.bg-smau-international:focus,
button.bg-smau-international:hover,
button.bg-smau-international:focus {
  background-color: #0b7176 !important; }

.bg-smau-mobile {
  background-color: #F6921E !important; }

a.bg-smau-mobile:hover, a.bg-smau-mobile:focus,
button.bg-smau-mobile:hover,
button.bg-smau-mobile:focus {
  background-color: #d87809 !important; }

.bg-smau-open-innovations {
  background-color: #684cc8 !important; }

a.bg-smau-open-innovations:hover, a.bg-smau-open-innovations:focus,
button.bg-smau-open-innovations:hover,
button.bg-smau-open-innovations:focus {
  background-color: #5035ac !important; }

.bg-smau-trade-tourism {
  background-color: #146E96 !important; }

a.bg-smau-trade-tourism:hover, a.bg-smau-trade-tourism:focus,
button.bg-smau-trade-tourism:hover,
button.bg-smau-trade-tourism:focus {
  background-color: #0e4d69 !important; }

.bg-smau-health {
  background-color: #ffd500 !important; }

a.bg-smau-health:hover, a.bg-smau-health:focus,
button.bg-smau-health:hover,
button.bg-smau-health:focus {
  background-color: #ccaa00 !important; }

.bg-smau-open-inovation {
  background-color: #006bdc !important; }

a.bg-smau-open-inovation:hover, a.bg-smau-open-inovation:focus,
button.bg-smau-open-inovation:hover,
button.bg-smau-open-inovation:focus {
  background-color: #0052a9 !important; }

.bg-smau-food-retail {
  background-color: #be4c49 !important; }

a.bg-smau-food-retail:hover, a.bg-smau-food-retail:focus,
button.bg-smau-food-retail:hover,
button.bg-smau-food-retail:focus {
  background-color: #9c3a38 !important; }

.bg-smau-smart-manufacturing {
  background-color: #bedb65 !important; }

a.bg-smau-smart-manufacturing:hover, a.bg-smau-smart-manufacturing:focus,
button.bg-smau-smart-manufacturing:hover,
button.bg-smau-smart-manufacturing:focus {
  background-color: #add13c !important; }

.bg-smau-energy-environment {
  background-color: #62cced !important; }

a.bg-smau-energy-environment:hover, a.bg-smau-energy-environment:focus,
button.bg-smau-energy-environment:hover,
button.bg-smau-energy-environment:focus {
  background-color: #34bde8 !important; }

.bg-smau-smart-mobility {
  background-color: #2c388f !important; }

a.bg-smau-smart-mobility:hover, a.bg-smau-smart-mobility:focus,
button.bg-smau-smart-mobility:hover,
button.bg-smau-smart-mobility:focus {
  background-color: #202968 !important; }

.bg-smau-e-health {
  background-color: #2ca377 !important; }

a.bg-smau-e-health:hover, a.bg-smau-e-health:focus,
button.bg-smau-e-health:hover,
button.bg-smau-e-health:focus {
  background-color: #217b5a !important; }

.bg-smau-alternative {
  background-color: #ea899a !important; }

a.bg-smau-alternative:hover, a.bg-smau-alternative:focus,
button.bg-smau-alternative:hover,
button.bg-smau-alternative:focus {
  background-color: #e25e75 !important; }

.bg-smau-energy {
  background-color: #adff2f !important; }

a.bg-smau-energy:hover, a.bg-smau-energy:focus,
button.bg-smau-energy:hover,
button.bg-smau-energy:focus {
  background-color: #98fb00 !important; }

.bg-smau-mobility {
  background-color: #c3b8f9 !important; }

a.bg-smau-mobility:hover, a.bg-smau-mobility:focus,
button.bg-smau-mobility:hover,
button.bg-smau-mobility:focus {
  background-color: #9b89f5 !important; }

.bg-smau-hr {
  background-color: #ffdead !important; }

a.bg-smau-hr:hover, a.bg-smau-hr:focus,
button.bg-smau-hr:hover,
button.bg-smau-hr:focus {
  background-color: #ffc97a !important; }

.bg-smau-sustainability {
  background-color: #89f5a0 !important; }

a.bg-smau-sustainability:hover, a.bg-smau-sustainability:focus,
button.bg-smau-sustainability:hover,
button.bg-smau-sustainability:focus {
  background-color: #5af17a !important; }

.bg-smau-artificial-intelligence {
  background-color: #173e87 !important; }

a.bg-smau-artificial-intelligence:hover, a.bg-smau-artificial-intelligence:focus,
button.bg-smau-artificial-intelligence:hover,
button.bg-smau-artificial-intelligence:focus {
  background-color: #102a5b !important; }

.bg-smau-emerging-technologies {
  background-color: #ff7bac !important; }

a.bg-smau-emerging-technologies:hover, a.bg-smau-emerging-technologies:focus,
button.bg-smau-emerging-technologies:hover,
button.bg-smau-emerging-technologies:focus {
  background-color: #ff488c !important; }

.bg-white {
  background-color: #FFFFFF !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #0386A3 !important; }

.border-secondary {
  border-color: #344AF0 !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #F5F5F5 !important; }

.border-dark {
  border-color: #2E283D !important; }

.border-accent {
  border-color: #E2189C !important; }

.border-black {
  border-color: #222222 !important; }

.border-green {
  border-color: #69C881 !important; }

.border-smau-agrifood {
  border-color: #0386A3 !important; }

.border-smau-digital-trends {
  border-color: #29B473 !important; }

.border-smau-ict {
  border-color: #f28e1e !important; }

.border-smau-industry {
  border-color: #4891CD !important; }

.border-smau-international {
  border-color: #0F9EA5 !important; }

.border-smau-mobile {
  border-color: #F6921E !important; }

.border-smau-open-innovations {
  border-color: #684cc8 !important; }

.border-smau-trade-tourism {
  border-color: #146E96 !important; }

.border-smau-health {
  border-color: #ffd500 !important; }

.border-smau-open-inovation {
  border-color: #006bdc !important; }

.border-smau-food-retail {
  border-color: #be4c49 !important; }

.border-smau-smart-manufacturing {
  border-color: #bedb65 !important; }

.border-smau-energy-environment {
  border-color: #62cced !important; }

.border-smau-smart-mobility {
  border-color: #2c388f !important; }

.border-smau-e-health {
  border-color: #2ca377 !important; }

.border-smau-alternative {
  border-color: #ea899a !important; }

.border-smau-energy {
  border-color: #adff2f !important; }

.border-smau-mobility {
  border-color: #c3b8f9 !important; }

.border-smau-hr {
  border-color: #ffdead !important; }

.border-smau-sustainability {
  border-color: #89f5a0 !important; }

.border-smau-artificial-intelligence {
  border-color: #173e87 !important; }

.border-smau-emerging-technologies {
  border-color: #ff7bac !important; }

.border-white {
  border-color: #FFFFFF !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 320px) {
  .d-xs-none {
    display: none !important; }
  .d-xs-inline {
    display: inline !important; }
  .d-xs-inline-block {
    display: inline-block !important; }
  .d-xs-block {
    display: block !important; }
  .d-xs-table {
    display: table !important; }
  .d-xs-table-row {
    display: table-row !important; }
  .d-xs-table-cell {
    display: table-cell !important; }
  .d-xs-flex {
    display: flex !important; }
  .d-xs-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 413px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1610px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before, .page-single__welcome .embed-responsive.page-single-main-image::before {
  padding-top: 100%; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-3by4::before {
  padding-top: 133.33333%; }

.embed-responsive-1by1::before, .page-single__welcome .embed-responsive.page-single-main-image::before {
  padding-top: 100%; }

.embed-responsive-3by2::before {
  padding-top: 66.66667%; }

.embed-responsive-2by3::before {
  padding-top: 150%; }

.embed-responsive-6by7::before {
  padding-top: 116.66667%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 320px) {
  .flex-xs-row {
    flex-direction: row !important; }
  .flex-xs-column {
    flex-direction: column !important; }
  .flex-xs-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xs-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xs-wrap {
    flex-wrap: wrap !important; }
  .flex-xs-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xs-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xs-fill {
    flex: 1 1 auto !important; }
  .flex-xs-grow-0 {
    flex-grow: 0 !important; }
  .flex-xs-grow-1 {
    flex-grow: 1 !important; }
  .flex-xs-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xs-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xs-start {
    justify-content: flex-start !important; }
  .justify-content-xs-end {
    justify-content: flex-end !important; }
  .justify-content-xs-center {
    justify-content: center !important; }
  .justify-content-xs-between {
    justify-content: space-between !important; }
  .justify-content-xs-around {
    justify-content: space-around !important; }
  .align-items-xs-start {
    align-items: flex-start !important; }
  .align-items-xs-end {
    align-items: flex-end !important; }
  .align-items-xs-center {
    align-items: center !important; }
  .align-items-xs-baseline {
    align-items: baseline !important; }
  .align-items-xs-stretch {
    align-items: stretch !important; }
  .align-content-xs-start {
    align-content: flex-start !important; }
  .align-content-xs-end {
    align-content: flex-end !important; }
  .align-content-xs-center {
    align-content: center !important; }
  .align-content-xs-between {
    align-content: space-between !important; }
  .align-content-xs-around {
    align-content: space-around !important; }
  .align-content-xs-stretch {
    align-content: stretch !important; }
  .align-self-xs-auto {
    align-self: auto !important; }
  .align-self-xs-start {
    align-self: flex-start !important; }
  .align-self-xs-end {
    align-self: flex-end !important; }
  .align-self-xs-center {
    align-self: center !important; }
  .align-self-xs-baseline {
    align-self: baseline !important; }
  .align-self-xs-stretch {
    align-self: stretch !important; } }

@media (min-width: 413px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1610px) {
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 320px) {
  .float-xs-left {
    float: left !important; }
  .float-xs-right {
    float: right !important; }
  .float-xs-none {
    float: none !important; } }

@media (min-width: 413px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

@media (min-width: 1610px) {
  .float-xxl-left {
    float: left !important; }
  .float-xxl-right {
    float: right !important; }
  .float-xxl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow, .navbar__side-menu__content__search-content__nav-tabs .nav-link.active {
  box-shadow: 0 0.5rem 0.8rem rgba(34, 34, 34, 0.12) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 320px) {
  .m-xs-0 {
    margin: 0 !important; }
  .mt-xs-0,
  .my-xs-0 {
    margin-top: 0 !important; }
  .mr-xs-0,
  .mx-xs-0 {
    margin-right: 0 !important; }
  .mb-xs-0,
  .my-xs-0 {
    margin-bottom: 0 !important; }
  .ml-xs-0,
  .mx-xs-0 {
    margin-left: 0 !important; }
  .m-xs-1 {
    margin: 0.25rem !important; }
  .mt-xs-1,
  .my-xs-1 {
    margin-top: 0.25rem !important; }
  .mr-xs-1,
  .mx-xs-1 {
    margin-right: 0.25rem !important; }
  .mb-xs-1,
  .my-xs-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xs-1,
  .mx-xs-1 {
    margin-left: 0.25rem !important; }
  .m-xs-2 {
    margin: 0.5rem !important; }
  .mt-xs-2,
  .my-xs-2 {
    margin-top: 0.5rem !important; }
  .mr-xs-2,
  .mx-xs-2 {
    margin-right: 0.5rem !important; }
  .mb-xs-2,
  .my-xs-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xs-2,
  .mx-xs-2 {
    margin-left: 0.5rem !important; }
  .m-xs-3 {
    margin: 1rem !important; }
  .mt-xs-3,
  .my-xs-3 {
    margin-top: 1rem !important; }
  .mr-xs-3,
  .mx-xs-3 {
    margin-right: 1rem !important; }
  .mb-xs-3,
  .my-xs-3 {
    margin-bottom: 1rem !important; }
  .ml-xs-3,
  .mx-xs-3 {
    margin-left: 1rem !important; }
  .m-xs-4 {
    margin: 1.5rem !important; }
  .mt-xs-4,
  .my-xs-4 {
    margin-top: 1.5rem !important; }
  .mr-xs-4,
  .mx-xs-4 {
    margin-right: 1.5rem !important; }
  .mb-xs-4,
  .my-xs-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xs-4,
  .mx-xs-4 {
    margin-left: 1.5rem !important; }
  .m-xs-5 {
    margin: 3rem !important; }
  .mt-xs-5,
  .my-xs-5 {
    margin-top: 3rem !important; }
  .mr-xs-5,
  .mx-xs-5 {
    margin-right: 3rem !important; }
  .mb-xs-5,
  .my-xs-5 {
    margin-bottom: 3rem !important; }
  .ml-xs-5,
  .mx-xs-5 {
    margin-left: 3rem !important; }
  .p-xs-0 {
    padding: 0 !important; }
  .pt-xs-0,
  .py-xs-0 {
    padding-top: 0 !important; }
  .pr-xs-0,
  .px-xs-0 {
    padding-right: 0 !important; }
  .pb-xs-0,
  .py-xs-0 {
    padding-bottom: 0 !important; }
  .pl-xs-0,
  .px-xs-0 {
    padding-left: 0 !important; }
  .p-xs-1 {
    padding: 0.25rem !important; }
  .pt-xs-1,
  .py-xs-1 {
    padding-top: 0.25rem !important; }
  .pr-xs-1,
  .px-xs-1 {
    padding-right: 0.25rem !important; }
  .pb-xs-1,
  .py-xs-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xs-1,
  .px-xs-1 {
    padding-left: 0.25rem !important; }
  .p-xs-2 {
    padding: 0.5rem !important; }
  .pt-xs-2,
  .py-xs-2 {
    padding-top: 0.5rem !important; }
  .pr-xs-2,
  .px-xs-2 {
    padding-right: 0.5rem !important; }
  .pb-xs-2,
  .py-xs-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xs-2,
  .px-xs-2 {
    padding-left: 0.5rem !important; }
  .p-xs-3 {
    padding: 1rem !important; }
  .pt-xs-3,
  .py-xs-3 {
    padding-top: 1rem !important; }
  .pr-xs-3,
  .px-xs-3 {
    padding-right: 1rem !important; }
  .pb-xs-3,
  .py-xs-3 {
    padding-bottom: 1rem !important; }
  .pl-xs-3,
  .px-xs-3 {
    padding-left: 1rem !important; }
  .p-xs-4 {
    padding: 1.5rem !important; }
  .pt-xs-4,
  .py-xs-4 {
    padding-top: 1.5rem !important; }
  .pr-xs-4,
  .px-xs-4 {
    padding-right: 1.5rem !important; }
  .pb-xs-4,
  .py-xs-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xs-4,
  .px-xs-4 {
    padding-left: 1.5rem !important; }
  .p-xs-5 {
    padding: 3rem !important; }
  .pt-xs-5,
  .py-xs-5 {
    padding-top: 3rem !important; }
  .pr-xs-5,
  .px-xs-5 {
    padding-right: 3rem !important; }
  .pb-xs-5,
  .py-xs-5 {
    padding-bottom: 3rem !important; }
  .pl-xs-5,
  .px-xs-5 {
    padding-left: 3rem !important; }
  .m-xs-n1 {
    margin: -0.25rem !important; }
  .mt-xs-n1,
  .my-xs-n1 {
    margin-top: -0.25rem !important; }
  .mr-xs-n1,
  .mx-xs-n1 {
    margin-right: -0.25rem !important; }
  .mb-xs-n1,
  .my-xs-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xs-n1,
  .mx-xs-n1 {
    margin-left: -0.25rem !important; }
  .m-xs-n2 {
    margin: -0.5rem !important; }
  .mt-xs-n2,
  .my-xs-n2 {
    margin-top: -0.5rem !important; }
  .mr-xs-n2,
  .mx-xs-n2 {
    margin-right: -0.5rem !important; }
  .mb-xs-n2,
  .my-xs-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xs-n2,
  .mx-xs-n2 {
    margin-left: -0.5rem !important; }
  .m-xs-n3 {
    margin: -1rem !important; }
  .mt-xs-n3,
  .my-xs-n3 {
    margin-top: -1rem !important; }
  .mr-xs-n3,
  .mx-xs-n3 {
    margin-right: -1rem !important; }
  .mb-xs-n3,
  .my-xs-n3 {
    margin-bottom: -1rem !important; }
  .ml-xs-n3,
  .mx-xs-n3 {
    margin-left: -1rem !important; }
  .m-xs-n4 {
    margin: -1.5rem !important; }
  .mt-xs-n4,
  .my-xs-n4 {
    margin-top: -1.5rem !important; }
  .mr-xs-n4,
  .mx-xs-n4 {
    margin-right: -1.5rem !important; }
  .mb-xs-n4,
  .my-xs-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xs-n4,
  .mx-xs-n4 {
    margin-left: -1.5rem !important; }
  .m-xs-n5 {
    margin: -3rem !important; }
  .mt-xs-n5,
  .my-xs-n5 {
    margin-top: -3rem !important; }
  .mr-xs-n5,
  .mx-xs-n5 {
    margin-right: -3rem !important; }
  .mb-xs-n5,
  .my-xs-n5 {
    margin-bottom: -3rem !important; }
  .ml-xs-n5,
  .mx-xs-n5 {
    margin-left: -3rem !important; }
  .m-xs-auto {
    margin: auto !important; }
  .mt-xs-auto,
  .my-xs-auto {
    margin-top: auto !important; }
  .mr-xs-auto,
  .mx-xs-auto {
    margin-right: auto !important; }
  .mb-xs-auto,
  .my-xs-auto {
    margin-bottom: auto !important; }
  .ml-xs-auto,
  .mx-xs-auto {
    margin-left: auto !important; } }

@media (min-width: 413px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

@media (min-width: 1610px) {
  .m-xxl-0 {
    margin: 0 !important; }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important; }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important; }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important; }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important; }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important; }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important; }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important; }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important; }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important; }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important; }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important; }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important; }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important; }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important; }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important; }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important; }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important; }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important; }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important; }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important; }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important; }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important; }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important; }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important; }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important; }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important; }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important; }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important; }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important; }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important; }
  .m-xxl-n1 {
    margin: -0.25rem !important; }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important; }
  .m-xxl-n2 {
    margin: -0.5rem !important; }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important; }
  .m-xxl-n3 {
    margin: -1rem !important; }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important; }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important; }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important; }
  .m-xxl-n4 {
    margin: -1.5rem !important; }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important; }
  .m-xxl-n5 {
    margin: -3rem !important; }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important; }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important; }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important; }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important; }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 320px) {
  .text-xs-left {
    text-align: left !important; }
  .text-xs-right {
    text-align: right !important; }
  .text-xs-center {
    text-align: center !important; } }

@media (min-width: 413px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1610px) {
  .text-xxl-left {
    text-align: left !important; }
  .text-xxl-right {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #FFFFFF !important; }

.text-primary {
  color: #0386A3 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #024858 !important; }

.text-secondary {
  color: #344AF0 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #0f25c9 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #F5F5F5 !important; }

a.text-light:hover, a.text-light:focus {
  color: #cfcfcf !important; }

.text-dark {
  color: #2E283D !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #0b0a0f !important; }

.text-accent {
  color: #E2189C !important; }

a.text-accent:hover, a.text-accent:focus {
  color: #9d116c !important; }

.text-black {
  color: #222222 !important; }

a.text-black:hover, a.text-black:focus {
  color: black !important; }

.text-green {
  color: #69C881 !important; }

a.text-green:hover, a.text-green:focus {
  color: #3da758 !important; }

.text-smau-agrifood {
  color: #0386A3 !important; }

a.text-smau-agrifood:hover, a.text-smau-agrifood:focus {
  color: #024858 !important; }

.text-smau-digital-trends {
  color: #29B473 !important; }

a.text-smau-digital-trends:hover, a.text-smau-digital-trends:focus {
  color: #1b764b !important; }

.text-smau-ict {
  color: #f28e1e !important; }

a.text-smau-ict:hover, a.text-smau-ict:focus {
  color: #b9670b !important; }

.text-smau-industry {
  color: #4891CD !important; }

a.text-smau-industry:hover, a.text-smau-industry:focus {
  color: #2b6a9d !important; }

.text-smau-international {
  color: #0F9EA5 !important; }

a.text-smau-international:hover, a.text-smau-international:focus {
  color: #095b5f !important; }

.text-smau-mobile {
  color: #F6921E !important; }

a.text-smau-mobile:hover, a.text-smau-mobile:focus {
  color: #c06b08 !important; }

.text-smau-open-innovations {
  color: #684cc8 !important; }

a.text-smau-open-innovations:hover, a.text-smau-open-innovations:focus {
  color: #472f99 !important; }

.text-smau-trade-tourism {
  color: #146E96 !important; }

a.text-smau-trade-tourism:hover, a.text-smau-trade-tourism:focus {
  color: #0b3d53 !important; }

.text-smau-health {
  color: #ffd500 !important; }

a.text-smau-health:hover, a.text-smau-health:focus {
  color: #b39500 !important; }

.text-smau-open-inovation {
  color: #006bdc !important; }

a.text-smau-open-inovation:hover, a.text-smau-open-inovation:focus {
  color: #004690 !important; }

.text-smau-food-retail {
  color: #be4c49 !important; }

a.text-smau-food-retail:hover, a.text-smau-food-retail:focus {
  color: #893331 !important; }

.text-smau-smart-manufacturing {
  color: #bedb65 !important; }

a.text-smau-smart-manufacturing:hover, a.text-smau-smart-manufacturing:focus {
  color: #a0c52e !important; }

.text-smau-energy-environment {
  color: #62cced !important; }

a.text-smau-energy-environment:hover, a.text-smau-energy-environment:focus {
  color: #1db6e5 !important; }

.text-smau-smart-mobility {
  color: #2c388f !important; }

a.text-smau-smart-mobility:hover, a.text-smau-smart-mobility:focus {
  color: #1a2155 !important; }

.text-smau-e-health {
  color: #2ca377 !important; }

a.text-smau-e-health:hover, a.text-smau-e-health:focus {
  color: #1c674b !important; }

.text-smau-alternative {
  color: #ea899a !important; }

a.text-smau-alternative:hover, a.text-smau-alternative:focus {
  color: #de4862 !important; }

.text-smau-energy {
  color: #adff2f !important; }

a.text-smau-energy:hover, a.text-smau-energy:focus {
  color: #89e200 !important; }

.text-smau-mobility {
  color: #c3b8f9 !important; }

a.text-smau-mobility:hover, a.text-smau-mobility:focus {
  color: #8771f3 !important; }

.text-smau-hr {
  color: #ffdead !important; }

a.text-smau-hr:hover, a.text-smau-hr:focus {
  color: #ffbf61 !important; }

.text-smau-sustainability {
  color: #89f5a0 !important; }

a.text-smau-sustainability:hover, a.text-smau-sustainability:focus {
  color: #42ef67 !important; }

.text-smau-artificial-intelligence {
  color: #173e87 !important; }

a.text-smau-artificial-intelligence:hover, a.text-smau-artificial-intelligence:focus {
  color: #0c2046 !important; }

.text-smau-emerging-technologies {
  color: #ff7bac !important; }

a.text-smau-emerging-technologies:hover, a.text-smau-emerging-technologies:focus {
  color: #ff2f7c !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(34, 34, 34, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #222222; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #FFFFFF !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

/* common */
@keyframes gradientPlaceholder {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

@keyframes svg-animation {
  0% {
    transform: rotateZ(0deg); }
  100% {
    transform: rotateZ(360deg); } }

@keyframes circle-animation {
  0%,
  25% {
    stroke-dashoffset: 280;
    transform: rotate(0); }
  50%,
  75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg); }
  100% {
    stroke-dashoffset: 280;
    transform: rotate(360deg); } }

@keyframes pulse {
  0% {
    opacity: 1; }
  80% {
    opacity: .3; }
  100% {
    opacity: 1; } }

@keyframes notificationProgress {
  from {
    transform: scaleX(1); }
  to {
    transform: scaleX(0); } }

@keyframes bounce {
  0% {
    transform: translateY(0); }
  50% {
    transform: translateY(-30%); }
  85% {
    transform: translateY(5%); }
  100% {
    transform: translateY(0%); } }

/* components */
button:hover, button:active, button:focus {
  outline: none; }

.btn--rounded {
  border-radius: 3rem; }

.btn--share:before, .btn--subscribe:before {
  display: inline-block;
  margin-right: 1rem;
  background-repeat: no-repeat;
  content: ''; }

@media (min-width: 992px) {
  .btn--share, .btn--subscribe {
    min-width: 20rem; } }

.btn--share:before {
  width: 1.5rem;
  height: 1.5rem;
  background-size: 1.5rem;
  background-position: center;
  background-image: url("../vectors/icon-share.svg"); }

.btn--subscribe:before {
  width: 2rem;
  height: 2rem;
  background-size: 2rem;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../vectors/icon-ticket.svg");
  content: ''; }

.btn--subscribe--dark:before {
  background-image: url("../vectors/icon-ticket-dark.svg"); }

.btn--opened:hover {
  background-color: #69C881;
  color: #FFFFFF;
  border-color: #69C881 !important; }

.btn--subscribed {
  background-color: #69C881;
  transition: background .3s ease; }
  .btn--subscribed__text {
    transition: all .4s ease; }
    .btn--subscribed__text:before {
      position: relative;
      display: inline-block;
      margin-right: 1rem;
      background-size: 2rem;
      background-repeat: no-repeat;
      background-position: center;
      vertical-align: middle;
      content: ''; }
    .btn--subscribed__text--subscribed:before {
      top: -2px;
      width: 2rem;
      height: 2rem;
      background-image: url("../vectors/icon-check.svg"); }
    .btn--subscribed__text--unsubscribe {
      opacity: 0;
      transform: translate(-50%, 100%); }
      .btn--subscribed__text--unsubscribe:before {
        width: 1.2rem;
        height: 1.2rem;
        background-image: url("../vectors/icon-close-dark.svg"); }
  .btn--subscribed:hover {
    background-color: rgba(46, 40, 61, 0.07); }
    .btn--subscribed:hover .btn--confirmed__text--subscribed {
      opacity: 0;
      transform: translateY(-100%); }
    .btn--subscribed:hover .btn--confirmed__text--unsubscribe {
      opacity: 1;
      transform: translate(-50%, -50%); }

.btn--bookmark:before {
  display: inline-block;
  width: 2.2rem;
  height: 3rem;
  margin-right: 1.5rem;
  background-size: 2.2rem;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../vectors/icon-bookmark-add.svg");
  vertical-align: middle;
  content: ''; }

.btn-primary--gradient {
  background-image: linear-gradient(165deg, #0386A3 8%, #E2189C 100%);
  border-radius: 29.5px;
  transition: background .4s ease; }
  .btn-primary--gradient:hover {
    background-image: linear-gradient(120deg, #0386A3 15%, #E2189C 100%); }

.btn-navbar-login {
  margin-right: -2rem;
  padding-left: 3.5rem;
  padding-right: 3.5rem;
  background-image: url("../vectors/icon-user-dark.svg");
  background-repeat: no-repeat;
  background-size: 2rem;
  background-position: center;
  color: #2E283D; }
  @media (min-width: 768px) {
    .btn-navbar-login {
      padding-left: 5.5rem;
      padding-right: 3rem;
      background-position: left 3rem center; } }
  .btn-navbar-login--eventbrite {
    padding: 0;
    width: 8.5rem;
    background-size: 6rem;
    background-image: url("../vectors/logo-eventbrite-color.svg"); }
    @media (min-width: 1200px) {
      .btn-navbar-login--eventbrite {
        width: 13rem;
        background-size: 8rem; } }
    @media (min-width: 768px) {
      .btn-navbar-login--eventbrite {
        background-position: left 1.1rem center; } }
    @media (min-width: 992px) {
      .btn-navbar-login--eventbrite {
        width: 10rem;
        background-position: center; } }
    .btn-navbar-login--eventbrite .login-eventbrite {
      font-size: 10px;
      text-align: center;
      display: block;
      margin-top: 13px;
      margin-right: 5px; }
  .btn-navbar-login:hover {
    text-decoration: none; }
    .btn-navbar-login:hover:after {
      background-color: rgba(46, 40, 61, 0.05); }
    .btn-navbar-login:hover .login-eventbrite {
      color: #000; }
  .btn-navbar-login:after {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-left: 1px solid rgba(46, 40, 61, 0.3);
    z-index: 0;
    transform: skew(10deg, 0deg);
    transition: background-color .3s ease;
    content: ''; }

.btn-agenda {
  width: 7rem;
  height: 7rem;
  cursor: pointer;
  background-color: #FFFFFF;
  box-shadow: 0 0.8rem 2.5rem rgba(46, 40, 61, 0.3);
  transition: background-color .3s ease; }
  .btn-agenda:hover {
    background-color: #fafafa; }
  .btn-agenda__icon {
    width: 2.5rem; }
    .btn-agenda__icon--close {
      width: 1.7rem;
      opacity: 0;
      transform: translateX(-50%) translateY(150%); }

.tag-filter__tag {
  white-space: nowrap;
  padding: .7rem 1.5rem;
  background-color: #FFFFFF;
  font-size: 1.2rem;
  border: 2px solid #D9D9D9;
  border-radius: 2rem;
  box-shadow: 0 1px 5px rgba(46, 40, 61, 0.08);
  transition: all .3s ease;
  /*@include media-breakpoint-up(xxl) {
        white-space: normal;
    }*/ }
  .tag-filter__tag:hover, .tag-filter__tag:active, .tag-filter__tag:focus {
    outline: none;
    box-shadow: 0 1px 5px rgba(46, 40, 61, 0.08); }
  @media (min-width: 768px) {
    .tag-filter__tag {
      padding: 1rem 1.5rem; } }
  .tag-filter__tag--active-last {
    cursor: default !important; }
  .tag-filter__tag:not(.tag-filter__tag--active) {
    background-color: #F5F5F5;
    border: 2px solid #F5F5F5;
    box-shadow: none;
    opacity: .5; }
    .tag-filter__tag:not(.tag-filter__tag--active):hover {
      box-shadow: none; }

.category-color-dot {
  width: 1rem;
  height: 1rem;
  border-radius: 100%; }

.btn--subscribed.welcome--participant__main-btn {
  max-width: 2em; }

.navbar__side-menu__btn-search-reset {
  display: block;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
  background-image: url("../vectors/icon-close.svg");
  opacity: 0;
  visibility: hidden; }

.breadcrumb {
  background: none;
  padding-left: 0; }
  .breadcrumb .breadcrumb-item.active {
    color: #fff;
    opacity: .8; }
    .breadcrumb .breadcrumb-item.active.text-black {
      color: #000;
      opacity: .8; }

.countdown__value {
  width: 100%;
  border-right: 1px solid #FFFFFF; }
  @media (min-width: 768px) {
    .countdown__value {
      max-width: 10rem; } }
  .countdown__value [class*=__] {
    min-height: 6rem; }

body #pc-cookie-notice {
  background-image: linear-gradient(16deg, #ed1c24 8%, #5a2392);
  padding: 12px 0 12px;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  @media (min-width: 992px) {
    body #pc-cookie-notice {
      display: flex;
      justify-content: space-between;
      align-items: center; } }
  body #pc-cookie-notice #pc-message {
    width: auto;
    float: left; }
    @media (max-width: 768px) {
      body #pc-cookie-notice #pc-message {
        float: none;
        padding: 0 25px 0 25px;
        text-align: center; } }
  body #pc-cookie-notice #pc-button {
    position: relative;
    float: right;
    margin-right: 13px;
    top: auto;
    right: auto; }
    @media (max-width: 768px) {
      body #pc-cookie-notice #pc-button {
        float: none;
        padding: 8px 25px 0 25px;
        text-align: center; } }
    body #pc-cookie-notice #pc-button button {
      padding: .5rem 5rem;
      background-color: transparent;
      font-size: 14px;
      color: #FFFFFF;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      border: 2px solid #FFFFFF;
      border-radius: 2rem;
      box-shadow: 0 1px 5px rgba(46, 40, 61, 0.08);
      transition: all .4s ease; }
      body #pc-cookie-notice #pc-button button:hover {
        background-color: #FFFFFF;
        color: #2E283D; }
        @supports (backdrop-filter: blur(10px)) {
          body #pc-cookie-notice #pc-button button:hover {
            background-color: rgba(255, 255, 255, 0.95);
            backdrop-filter: blur(10px); } }

@media (min-width: 1610px) {
  .card--partner {
    flex: 0 0 20%;
    max-width: 20%; } }

.card--partner__img {
  max-width: 14rem;
  max-height: 14rem; }

.card--partner .card__image--scale {
  max-width: 15rem; }
  .card--partner .card__image--scale img {
    max-height: 9rem; }
  @media (max-width: 991.98px) {
    .card--partner .card__image--scale img {
      max-height: 8rem; } }
  @media (min-width: 413px) {
    .card--partner .card__image--scale {
      max-width: 90%;
      margin: 0; }
      .card--partner .card__image--scale img {
        transition: transform .5s ease; }
      .card--partner .card__image--scale:hover img {
        transform: scale(0.9); } }

.card__image--scale {
  border-radius: 3px;
  transition: box-shadow .3s ease, transform .5s ease; }
  @media (pointer: coarse) {
    .card__image--scale:hover, .card__image--scale:active, .card__image--scale:focus {
      transform: scale(0.95); } }
  @media (min-width: 768px) {
    .card__image--scale:hover {
      transform: scale(1.05);
      box-shadow: 0 2px 5px rgba(46, 40, 61, 0.15), 0 10px 15px rgba(46, 40, 61, 0.25); }
      .card__image--scale:hover .card__image--scale__overlay__bg {
        transform: rotate(10deg) translateX(-90%) translateY(-15%) scale(2); } }
  .card__image--scale__overlay {
    width: 2.5rem; }
    .card__image--scale__overlay__bg {
      transform: rotate(7deg) translateX(-85%) translateY(-15%) scale(1.5);
      transform-origin: left;
      transition: transform .7s ease;
      z-index: -1; }

.card__image--has-icon:hover:after {
  transform: translate(45%, 75%); }

.card__image--has-icon:after {
  position: absolute;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  top: .7rem;
  left: .7rem;
  background-image: url("../vectors/icon-plus.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 2;
  transform-origin: center;
  transition: transform .7s ease;
  content: ''; }

.card__image__text:before {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 13%, rgba(0, 0, 0, 0.55) 100%);
  z-index: 0;
  content: ''; }

.card__body {
  padding-left: 2rem;
  padding-right: 2rem; }

.card__text {
  font-size: 1.2rem; }
  @media (min-width: 992px) {
    .card__text {
      font-size: 1.4rem; } }
  @media (min-width: 1610px) {
    .card__text {
      max-width: 30rem; } }

.card__footer__avatar img {
  width: 4rem;
  height: 4rem; }

@media (max-width: 1609.98px) {
  .card .display-3, .card .page-single__welcome h3:not(.display-1):not(.display-2):not(.display-3):not(.display-4):not(.text-dark):not(.text-black), .page-single__welcome .card h3:not(.display-1):not(.display-2):not(.display-3):not(.display-4):not(.text-dark):not(.text-black) {
    font-size: 2.2rem; } }

.partner__item {
  display: none; }
  .partner__item--visible {
    display: block; }

.thematic-area-list-image {
  height: 180px;
  object-fit: cover;
  border-radius: 1em; }

.default-search__input {
  padding: 1.5rem 7rem 1.5rem 6rem;
  background-color: #FFFFFF;
  font-weight: 600;
  background-image: url("../vectors/icon-search-dark.svg");
  background-repeat: no-repeat;
  background-size: 2rem;
  background-position: center left 2rem;
  box-shadow: 0 5px 10px 0 rgba(46, 40, 61, 0.03);
  border: 1px solid #D9D9D9;
  border-radius: 1.2rem;
  transition: box-shadow .6s ease; }
  @media (min-width: 768px) {
    .default-search__input {
      padding: 2rem 7rem 2rem 6rem;
      background-size: 2.6rem;
      border-radius: 1.7rem; } }
  .default-search__input:hover, .default-search__input:active, .default-search__input:focus {
    box-shadow: 0 7px 30px 0 rgba(46, 40, 61, 0.1);
    outline: none; }

.default-search__btn-reset {
  display: block;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40%;
  background-image: url("../vectors/icon-close-dark.svg");
  opacity: 0;
  transform: scale(0);
  transform-origin: center;
  transition: all .2s ease;
  pointer-events: none; }
  .default-search__btn-reset--revealed {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    pointer-events: all; }

.form__heading__description,
.ajax-form__heading__description {
  max-width: 40rem;
  margin: auto; }

.form__switcher__link,
.ajax-form__switcher__link {
  border-bottom: 4px solid transparent;
  transition: border .3s ease; }
  .form__switcher__link:hover,
  .ajax-form__switcher__link:hover {
    border-color: #F5F5F5; }
  .form__switcher__link--active,
  .ajax-form__switcher__link--active {
    border-color: #0386A3; }
    .form__switcher__link--active:hover,
    .ajax-form__switcher__link--active:hover {
      border-color: #0386A3; }

.form select,
.ajax-form select {
  appearance: none;
  padding: 1rem 4rem 1rem 1.5rem;
  background-color: #FFFFFF;
  background-image: url("../vectors/icon-caret.svg");
  background-repeat: no-repeat;
  background-size: 1rem;
  background-position: center right 1.5rem;
  border: 1px solid rgba(46, 40, 61, 0.2);
  font-weight: bold;
  box-shadow: 0 0.5rem 1rem rgba(46, 40, 61, 0.05);
  outline: none;
  cursor: pointer;
  transition: box-shadow .3s ease;
  font-size: 1.4rem;
  height: auto;
  max-width: 100%; }
  .form select:hover,
  .ajax-form select:hover {
    box-shadow: 0 0.7rem 1rem rgba(46, 40, 61, 0.08); }

.form label,
.ajax-form label {
  transition: .5s ease; }

.form textarea,
.ajax-form textarea {
  border: 1px solid rgba(46, 40, 61, 0.3);
  font-size: 1.4rem; }
  .form textarea:hover, .form textarea:active, .form textarea:focus,
  .ajax-form textarea:hover,
  .ajax-form textarea:active,
  .ajax-form textarea:focus {
    outline: none; }

.form .form__input,
.ajax-form .form__input {
  display: flex;
  flex-flow: column-reverse;
  margin-bottom: 1em;
  padding-left: 4rem; }
  .form .form__input--user:before, .form .form__input--email:before, .form .form__input--password:before, .form .form__input--company:before, .form .form__input--url:before, .form .form__input--linkedin:before, .form .form__input--facebook:before, .form .form__input--instagram:before, .form .form__input--youtube:before,
  .ajax-form .form__input--user:before,
  .ajax-form .form__input--email:before,
  .ajax-form .form__input--password:before,
  .ajax-form .form__input--company:before,
  .ajax-form .form__input--url:before,
  .ajax-form .form__input--linkedin:before,
  .ajax-form .form__input--facebook:before,
  .ajax-form .form__input--instagram:before,
  .ajax-form .form__input--youtube:before {
    position: absolute;
    display: block;
    width: 2rem;
    height: 2rem;
    bottom: 0;
    left: 0;
    margin-right: 1rem;
    background-image: url("../vectors/icon-mail-dark.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    transform: translateY(-50%);
    opacity: .8;
    content: ''; }
  .form .form__input--user:before,
  .ajax-form .form__input--user:before {
    background-image: url("../vectors/icon-user-dark.svg"); }
  .form .form__input--email:before,
  .ajax-form .form__input--email:before {
    background-image: url("../vectors/icon-mail-dark.svg"); }
  .form .form__input--password:before,
  .ajax-form .form__input--password:before {
    background-size: 100% 100%;
    background-position: center;
    background-image: url("../vectors/icon-key-dark.svg"); }
  .form .form__input--company:before,
  .ajax-form .form__input--company:before {
    background-size: 100% 100%;
    background-position: center;
    background-image: url("../vectors/icon-company-dark.svg"); }
  .form .form__input--url:before,
  .ajax-form .form__input--url:before {
    background-size: 100% 100%;
    background-position: center;
    background-image: url("../vectors/icon-world-dark.svg"); }
  .form .form__input--linkedin:before,
  .ajax-form .form__input--linkedin:before {
    background-size: 100% 100%;
    background-position: center;
    background-image: url("../vectors/icon-linkedin-dark.svg"); }
  .form .form__input--facebook:before,
  .ajax-form .form__input--facebook:before {
    background-size: 100% 100%;
    background-position: center;
    background-image: url("../vectors/icon-facebook-dark.svg"); }
  .form .form__input--instagram:before,
  .ajax-form .form__input--instagram:before {
    background-size: 100% 100%;
    background-position: center;
    background-image: url("../vectors/icon-instagram-dark.svg"); }
  .form .form__input--youtube:before,
  .ajax-form .form__input--youtube:before {
    background-size: 100% 100%;
    background-position: center;
    background-image: url("../vectors/icon-youtube-dark.svg"); }
  .form .form__input--submit,
  .ajax-form .form__input--submit {
    min-width: 20rem;
    padding: 3rem 2rem;
    background-image: url("../vectors/icon-arrow-right.svg");
    background-repeat: no-repeat;
    background-position: center right 1.7rem;
    background-size: 2rem;
    transition: background-position .4s ease; }
    .form .form__input--submit:hover,
    .ajax-form .form__input--submit:hover {
      background-position: center right 1.3rem; }

.form .form__input,
.form .formbuilder-row .form-group,
.ajax-form .form__input,
.ajax-form .formbuilder-row .form-group {
  display: flex;
  flex-flow: column-reverse;
  margin-bottom: 1em; }
  .form .form__input label,
  .form .formbuilder-row .form-group label,
  .ajax-form .form__input label,
  .ajax-form .formbuilder-row .form-group label {
    font-size: 1.2rem;
    font-weight: 700 !important;
    text-transform: uppercase !important; }
  .form .form__input label, .form .form__input input,
  .form .formbuilder-row .form-group label,
  .form .formbuilder-row .form-group input,
  .ajax-form .form__input label,
  .ajax-form .form__input input,
  .ajax-form .formbuilder-row .form-group label,
  .ajax-form .formbuilder-row .form-group input {
    transition: all .7s ease;
    touch-action: manipulation; }
  .form .form__input input,
  .form .formbuilder-row .form-group input,
  .ajax-form .form__input input,
  .ajax-form .formbuilder-row .form-group input {
    font-size: 1.2em;
    border: 0;
    border-bottom: 1px solid rgba(46, 40, 61, 0.3);
    -webkit-appearance: none;
    border-radius: 0;
    padding: 0;
    cursor: text;
    padding-bottom: .8rem; }
  .form .form__input input:focus,
  .form .formbuilder-row .form-group input:focus,
  .ajax-form .form__input input:focus,
  .ajax-form .formbuilder-row .form-group input:focus {
    outline: 0;
    box-shadow: none; }
  .form .form__input label,
  .form .formbuilder-row .form-group label,
  .ajax-form .form__input label,
  .ajax-form .formbuilder-row .form-group label {
    margin-bottom: 0;
    line-height: 2; }
  .form .form__input input:placeholder-shown + label,
  .form .formbuilder-row .form-group input:placeholder-shown + label,
  .ajax-form .form__input input:placeholder-shown + label,
  .ajax-form .formbuilder-row .form-group input:placeholder-shown + label {
    cursor: text;
    max-width: 66.66%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform-origin: left bottom;
    transform: translate(0, 2.6rem) scale(1.2); }
  .form .form__input ::-webkit-input-placeholder,
  .form .formbuilder-row .form-group ::-webkit-input-placeholder,
  .ajax-form .form__input ::-webkit-input-placeholder,
  .ajax-form .formbuilder-row .form-group ::-webkit-input-placeholder {
    opacity: 0;
    transition: inherit; }
  .form .form__input input:focus::-webkit-input-placeholder,
  .form .formbuilder-row .form-group input:focus::-webkit-input-placeholder,
  .ajax-form .form__input input:focus::-webkit-input-placeholder,
  .ajax-form .formbuilder-row .form-group input:focus::-webkit-input-placeholder {
    opacity: 0; }
  .form .form__input input:not(:placeholder-shown) + label,
  .form .form__input input:focus + label,
  .form .formbuilder-row .form-group input:not(:placeholder-shown) + label,
  .form .formbuilder-row .form-group input:focus + label,
  .ajax-form .form__input input:not(:placeholder-shown) + label,
  .ajax-form .form__input input:focus + label,
  .ajax-form .formbuilder-row .form-group input:not(:placeholder-shown) + label,
  .ajax-form .formbuilder-row .form-group input:focus + label {
    opacity: .6;
    transform: translate(0, 0) scale(1);
    transform-origin: left bottom;
    cursor: pointer; }
  .form .form__input button[type="submit"],
  .form .formbuilder-row .form-group button[type="submit"],
  .ajax-form .form__input button[type="submit"],
  .ajax-form .formbuilder-row .form-group button[type="submit"] {
    min-width: 20rem;
    padding: 1.5rem 2rem;
    background-image: url("../vectors/icon-arrow-right.svg");
    background-repeat: no-repeat;
    background-position: center right 1.7rem;
    background-size: 2rem;
    transition: background-position .4s ease;
    background-color: #344AF0;
    border-color: #344AF0;
    color: #fff !important;
    font-weight: 700 !important;
    text-transform: uppercase !important;
    border-radius: 3rem;
    letter-spacing: 2px;
    font-size: 1.2rem;
    max-width: 200px;
    margin: 4rem auto; }
    .form .form__input button[type="submit"]:hover,
    .form .formbuilder-row .form-group button[type="submit"]:hover,
    .ajax-form .form__input button[type="submit"]:hover,
    .ajax-form .formbuilder-row .form-group button[type="submit"]:hover {
      background-position: center right 1.3rem; }
  .form .form__input .privacy-checkbox + legend,
  .form .formbuilder-row .form-group .privacy-checkbox + legend,
  .ajax-form .form__input .privacy-checkbox + legend,
  .ajax-form .formbuilder-row .form-group .privacy-checkbox + legend {
    display: none; }
  .form .form__input .custom-radio .custom-control-label,
  .form .form__input .custom-checkbox .custom-control-label,
  .form .formbuilder-row .form-group .custom-radio .custom-control-label,
  .form .formbuilder-row .form-group .custom-checkbox .custom-control-label,
  .ajax-form .form__input .custom-radio .custom-control-label,
  .ajax-form .form__input .custom-checkbox .custom-control-label,
  .ajax-form .formbuilder-row .form-group .custom-radio .custom-control-label,
  .ajax-form .formbuilder-row .form-group .custom-checkbox .custom-control-label {
    opacity: 1; }
    .form .form__input .custom-radio .custom-control-label a,
    .form .form__input .custom-checkbox .custom-control-label a,
    .form .formbuilder-row .form-group .custom-radio .custom-control-label a,
    .form .formbuilder-row .form-group .custom-checkbox .custom-control-label a,
    .ajax-form .form__input .custom-radio .custom-control-label a,
    .ajax-form .form__input .custom-checkbox .custom-control-label a,
    .ajax-form .formbuilder-row .form-group .custom-radio .custom-control-label a,
    .ajax-form .formbuilder-row .form-group .custom-checkbox .custom-control-label a {
      color: #0386A3;
      opacity: 1;
      text-decoration: underline;
      text-transform: none; }
  .form .form__input small.text-muted,
  .form .formbuilder-row .form-group small.text-muted,
  .ajax-form .form__input small.text-muted,
  .ajax-form .formbuilder-row .form-group small.text-muted {
    order: -1;
    margin-top: 1rem; }

@-moz-document url-prefix() {
  .form input::placeholder,
  .ajax-form input::placeholder {
    color: white; } }

.form .help-text,
.ajax-form .help-text {
  font-size: 80%;
  font-weight: 400;
  color: #6c757d !important;
  margin-bottom: 2.2rem; }

.form-builder-wrapper {
  max-width: 1440px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (max-width: 991.98px) {
    .form-builder-wrapper {
      max-width: 960px; } }
  @media (max-width: 767.98px) {
    .form-builder-wrapper {
      max-width: 720px; } }
  @media (max-width: 412.98px) {
    .form-builder-wrapper {
      max-width: 540px; } }
  .form-builder-wrapper .ajax-form > .row {
    flex-direction: column; }
  .form-builder-wrapper .formbuilder-row.default {
    padding: 0; }
    .form-builder-wrapper .formbuilder-row.default label .invalid-feedback {
      display: block !important; }
      .form-builder-wrapper .formbuilder-row.default label .invalid-feedback .badge-danger {
        display: none; }
      .form-builder-wrapper .formbuilder-row.default label .invalid-feedback .form-error-message {
        font-size: 1.4rem;
        color: #0386A3;
        font-weight: 700 !important; }
    .form-builder-wrapper .formbuilder-row.default .form-control.is-invalid {
      background-image: none;
      border-color: rgba(46, 40, 61, 0.3); }
    .form-builder-wrapper .formbuilder-row.default .custom-control-input.is-invalid ~ .custom-control-label:before {
      border-color: rgba(46, 40, 61, 0.5); }
    .form-builder-wrapper .formbuilder-row.default .invalid-feedback {
      order: -2; }
      .form-builder-wrapper .formbuilder-row.default .invalid-feedback .badge-danger {
        display: none; }
      .form-builder-wrapper .formbuilder-row.default .invalid-feedback .form-error-message {
        font-size: 1.4rem;
        color: #0386A3;
        font-weight: 700 !important; }
  .form-builder-wrapper .formbuilder-row.default label.checkbox-custom.custom-control-label.required .invalid-feedback {
    display: none !important; }

.input-brand {
  position: relative;
  height: auto;
  top: -8px; }
  .input-brand label,
  .input-brand input {
    margin: 0;
    font-size: 1.2rem;
    letter-spacing: 0; }
  .input-brand input:placeholder-shown + label {
    transform: translateY(1.125rem) scale(1); }
  .input-brand input:not(:placeholder-shown) + label,
  .input-brand input:focus + label {
    opacity: 0;
    transform: translate(0, 0) scale(1);
    cursor: pointer; }

.login-social-row {
  margin-top: 3rem; }
  .login-social-row__btn {
    width: calc(33.333% - .5rem);
    background-color: #FFFFFF;
    transition: all .3s ease; }
    .login-social-row__btn:hover {
      box-shadow: 0 0.5rem 1rem rgba(46, 40, 61, 0.05); }
    .login-social-row__btn__icon {
      max-width: 2rem;
      max-height: 2rem;
      margin-right: 1rem; }
      .login-social-row__btn__icon--linkedin {
        top: -.3rem; }

#visitor_profile_edit_businessCategory,
label[aria-describedby="visitor_profile_edit_businessCategory_help"],
#visitor_profile_edit_businessCategory_help,
label[aria-describedby="visitor_profile_edit_businessIndustry_help"],
#visitor_profile_edit_businessIndustry_help,
#visitor_profile_edit_businessIndustryOther,
label[for="visitor_profile_edit_businessIndustryOther"],
#visitor_profile_edit_businessIndustryOther_help,
#visitor_profile_edit_businessIndustry {
  display: none; }

form ul {
  padding-left: 0; }
  form ul li {
    list-style-type: none;
    font-weight: bold; }

.option-label {
  position: relative;
  display: inline-block;
  text-align: left; }
  .option-label label {
    margin-left: -7px; }
  .option-label.first-el label {
    margin-left: 0; }
  .option-label:before {
    content: '';
    position: absolute;
    top: -22px;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    opacity: .9;
    border: 0 solid transparent;
    background: linear-gradient(to bottom, #f5f5f5 0, #f9f9f9 100%); }

.custom-control.custom-radio {
  padding-left: 0;
  margin-top: 1.5rem; }
  .custom-control.custom-radio label:before {
    display: none; }

.custom-control.custom-checkbox,
.form-check {
  position: relative;
  margin-top: 1.5rem;
  padding-left: 0; }
  .custom-control.custom-checkbox input[type="checkbox"],
  .form-check input[type="checkbox"] {
    position: absolute;
    z-index: 1;
    opacity: 0; }
    .custom-control.custom-checkbox input[type="checkbox"]:checked + label:before,
    .form-check input[type="checkbox"]:checked + label:before {
      background-color: #344AF0;
      border: 2px solid #344AF0; }
    .custom-control.custom-checkbox input[type="checkbox"]:checked + label:after,
    .form-check input[type="checkbox"]:checked + label:after {
      opacity: 1;
      z-index: 0; }
  .custom-control.custom-checkbox label,
  .form-check label {
    cursor: pointer;
    margin-left: 0 !important;
    position: relative;
    padding-left: 3.6rem; }
    .custom-control.custom-checkbox label:before, .custom-control.custom-checkbox label:after,
    .form-check label:before,
    .form-check label:after {
      position: absolute;
      display: block;
      cursor: pointer;
      content: ''; }
    .custom-control.custom-checkbox label:before,
    .form-check label:before {
      width: 1.8rem;
      height: 1.8rem;
      top: 11px;
      left: 0;
      margin-top: -.9rem;
      background-color: #F5F5F5;
      border: 2px solid rgba(46, 40, 61, 0.5);
      border-radius: 3px; }
    .custom-control.custom-checkbox label:after,
    .form-check label:after {
      width: 7px;
      height: 12px;
      top: 3px;
      left: 6px;
      border: solid white;
      border-width: 0 .25rem .25rem 0;
      transform: rotate(45deg);
      opacity: 0;
      z-index: -1;
      transition: background-color .3s ease; }
  .custom-control.custom-checkbox.hide-block,
  .form-check.hide-block {
    display: inline-block; }
    .custom-control.custom-checkbox.hide-block input[type=checkbox],
    .form-check.hide-block input[type=checkbox] {
      position: absolute;
      top: 0;
      left: 0;
      margin-left: 0; }

input[type="radio"] {
  position: absolute;
  z-index: 0;
  opacity: 1; }
  input[type="radio"] + label {
    padding-left: 4rem;
    cursor: pointer; }
  input[type="radio"]:checked:after {
    transform: scale(1); }
  input[type="radio"]:before, input[type="radio"]:after {
    position: absolute;
    display: block;
    content: '';
    visibility: visible; }
  input[type="radio"]:before {
    width: 1.8rem;
    height: 1.8rem;
    top: -1px;
    left: -1px;
    background-color: #FFFFFF;
    border: 2px solid rgba(46, 40, 61, 0.6);
    border-radius: 50%;
    z-index: 1;
    pointer-events: none; }
  input[type="radio"]:after {
    width: .8rem;
    height: .8rem;
    top: 4px;
    left: 4px;
    background-color: #344AF0;
    border-radius: 50%;
    z-index: 1;
    transform: scale(0);
    transition: transform .3s ease; }

.form-group .g-recaptcha {
  margin-top: 2.5rem; }

.loader {
  width: 4rem;
  height: 4rem;
  transition: all 1s ease;
  transform: translateX(-50%) scale(1); }
  .loader svg {
    max-width: 4rem;
    animation: 2s linear infinite svg-animation; }
  .loader circle {
    display: block;
    fill: transparent;
    /*        stroke: $primary;*/
    stroke-linecap: square;
    stroke-dasharray: 283;
    stroke-dashoffset: 280;
    stroke-width: 10px;
    transform-origin: 50% 50%;
    animation: 1.4s ease-in-out infinite both circle-animation; }
  .loader--hided {
    opacity: 0;
    z-index: -1;
    transform: translateX(-50%) scale(0.2); }

.navbar__side-menu__main-search-container .loader {
  top: 25rem; }

.modal:after {
  backdrop-filter: blur(3px); }

.modal-content {
  border-radius: 1.4rem; }

.progress-bar {
  background: #0386A3;
  background: linear-gradient(90deg, #344AF0 0%, #0386A3 20%, #e2189c 100%); }

.modal-header {
  border: none; }

.modal__face {
  border-radius: 1rem;
  max-width: 10rem; }
  @media (min-width: 768px) {
    .modal__face {
      max-width: none; } }

.modal-dialog {
  max-width: 76.8rem; }

.close {
  font-size: 3rem; }
  .close:active, .close:focus {
    outline: none;
    box-shadow: none; }

.floating-notification {
  width: 100%;
  max-width: 45rem;
  opacity: 0;
  visibility: hidden;
  transform: translateX(0) translateY(100%); }
  .floating-notification--revealed .floating-notification__progress__current {
    animation: notificationProgress 6s linear; }
  .floating-notification:before {
    display: block;
    position: absolute;
    top: 3rem;
    left: 1.5rem;
    width: 3rem;
    height: 3rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 2rem;
    content: ''; }
  .floating-notification__progress {
    width: 100%;
    height: .4rem;
    background-color: rgba(46, 40, 61, 0.1); }
    .floating-notification__progress__current {
      height: .4rem;
      background-color: #2E283D;
      transform-origin: left; }
  .floating-notification__body {
    padding-left: 6rem;
    padding-right: 2rem; }
  .floating-notification--success .floating-notification__progress__current {
    background-color: #69C881; }
  .floating-notification--success:before {
    background-image: url("../vectors/icon-check-color.svg"); }
  .floating-notification--warning .floating-notification__progress__current {
    background-color: orange; }
  .floating-notification--warning:before {
    background-image: url("../vectors/icon-alert-color.svg"); }
  .floating-notification--error .floating-notification__progress__current {
    background-color: #0386A3; }
  .floating-notification--error:before {
    background-image: url("../vectors/icon-error-color.svg"); }

.range-slider-container *::selection {
  background: transparent; }

.range-slider {
  position: relative;
  width: 100%;
  padding: 1rem 0;
  overflow: hidden; }
  .range-slider .steps-container__step__indicator {
    position: relative;
    padding: 3px 10px;
    font-size: 1rem;
    border-radius: 20px;
    box-shadow: 0 0.2rem 0.6rem rgba(46, 40, 61, 0.2);
    cursor: pointer;
    transition: all .4s ease;
    text-align: center;
    align-self: center; }
    @media (min-width: 768px) {
      .range-slider .steps-container__step__indicator {
        font-size: smaller; } }
    @media (max-width: 412.98px) {
      .range-slider .steps-container__step__indicator {
        padding: 3px;
        font-size: 0.9rem; } }
    .range-slider .steps-container__step__indicator:after {
      position: absolute;
      width: 0;
      height: 0;
      bottom: -.5rem;
      left: 50%;
      margin-left: -.6rem;
      border-style: solid;
      border-width: .5rem .6rem 0 .6rem;
      border-color: transparent;
      content: '';
      transition: border .4s ease; }
  .range-slider .steps-container__step--active .steps-container__step__indicator {
    color: #FFFFFF;
    background: #344AF0;
    animation: bounce .5s ease; }
    .range-slider .steps-container__step--active .steps-container__step__indicator:after {
      border-color: #344AF0 transparent transparent transparent; }
  .range-slider__bar {
    position: relative;
    width: 100%;
    height: 1.5rem;
    margin-top: 1.5rem;
    background: linear-gradient(90deg, #e2189c 0%, #0386A3 70%, #344AF0 100%);
    border-radius: 2px;
    box-shadow: 0 0.2rem 0.6rem rgba(46, 40, 61, 0.2);
    transform-origin: left;
    cursor: ew-resize;
    transform: scaleX(0); }

.slider {
  background-color: #2E283D; }
  .slider .swiper-pagination .swiper-pagination-bullet {
    margin-right: 1rem;
    background: #c7c1d6; }
    .slider .swiper-pagination .swiper-pagination-bullet:hover, .slider .swiper-pagination .swiper-pagination-bullet:active, .slider .swiper-pagination .swiper-pagination-bullet:focus {
      outline: none; }
    .slider .swiper-pagination .swiper-pagination-bullet-active {
      background: #FFFFFF; }
  .slider--welcome__pagination {
    width: 83.33333%;
    bottom: 10rem; }
    @media (min-width: 992px) {
      .slider--welcome__pagination {
        bottom: 4rem;
        left: 0; } }
    @media (min-width: 1610px) {
      .slider--welcome__pagination {
        bottom: 7%;
        left: 0; } }
  .slider__slide:before {
    position: relative;
    display: block;
    width: 100%;
    padding-top: 50rem;
    content: ''; }
    @media (min-width: 992px) {
      .slider__slide:before {
        padding-top: 47.36%; } }
    @media (min-width: 1113px) and (max-height: 768px) {
      .slider__slide:before {
        padding-top: 42%; } }
    @media (min-aspect-ratio: 16 / 9) and (min-width: 1700px) {
      .slider__slide:before {
        padding-top: 42%; } }
  .slider__slide:after {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(46, 40, 61, 0.6);
    z-index: 0;
    content: ''; }
  .slider__slide__content {
    padding-bottom: 9rem; }
    @media (min-aspect-ratio: 19 / 9), (min-width: 1800px) {
      .slider__slide__content {
        padding-bottom: 7%; } }
    @media (min-width: 1200px) {
      .slider__slide__content__title.display-1 {
        font-size: 6.5rem; } }
    .slider__slide__content__paragraph {
      max-width: 45rem; }

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next,
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  display: none;
  width: 4.5rem;
  height: 4.5rem;
  top: 50%;
  background-size: 4.5rem;
  background-position: center;
  opacity: .7;
  transition: opacity .3s ease, transform .3s ease; }
  @media (min-width: 992px) {
    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next,
    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      display: block; } }
  @media (pointer: coarse) {
    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next,
    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      display: none; } }
  .swiper-button-prev:hover,
  .swiper-container-rtl .swiper-button-next:hover,
  .swiper-button-next:hover,
  .swiper-container-rtl .swiper-button-prev:hover {
    opacity: 1; }
  .swiper-button-prev:active,
  .swiper-container-rtl .swiper-button-next:active,
  .swiper-button-next:active,
  .swiper-container-rtl .swiper-button-prev:active {
    transform: scale(0.8); }
  .swiper-button-prev:hover, .swiper-button-prev:active, .swiper-button-prev:focus,
  .swiper-container-rtl .swiper-button-next:hover,
  .swiper-container-rtl .swiper-button-next:active,
  .swiper-container-rtl .swiper-button-next:focus,
  .swiper-button-next:hover,
  .swiper-button-next:active,
  .swiper-button-next:focus,
  .swiper-container-rtl .swiper-button-prev:hover,
  .swiper-container-rtl .swiper-button-prev:active,
  .swiper-container-rtl .swiper-button-prev:focus {
    outline: none; }

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: 2rem;
  background-image: url("../vectors/arrow-right.svg");
  transform-origin: right; }

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: 2rem;
  background-image: url("../vectors/arrow-left.svg");
  transform-origin: left; }

#main-homepage .slider--logos__slide img,
#main-homepage .slider--faces__slide img {
  filter: grayscale(0);
  transition: filter .4s ease; }

.slider--logos img {
  max-width: 16rem;
  max-height: 6rem;
  min-height: 5em;
  object-fit: contain; }
  @media (max-width: 767.98px) {
    .slider--logos img {
      max-width: 100%; } }

.slider--special-events .swiper-slide,
.slider--live-shows .swiper-slide {
  background-color: #fff;
  transition: box-shadow .4s ease; }
  .slider--special-events .swiper-slide p,
  .slider--live-shows .swiper-slide p {
    font-size: 1.2em; }
    @media (min-width: 768px) {
      .slider--special-events .swiper-slide p,
      .slider--live-shows .swiper-slide p {
        font-size: 1.8rem; } }
    @media (min-width: 1200px) {
      .slider--special-events .swiper-slide p,
      .slider--live-shows .swiper-slide p {
        font-size: 1.5em; } }

.slider--roadshow {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 8.33333%; }
  @media (min-width: 768px) {
    .slider--roadshow {
      padding: 0; } }
  @media (min-width: 1440px) {
    .slider--roadshow {
      padding: 0; } }
  .slider--roadshow .swiper-pagination .swiper-pagination-bullet {
    background-color: #2E283D;
    opacity: .3; }
    .slider--roadshow .swiper-pagination .swiper-pagination-bullet-active {
      background-color: #0386A3;
      opacity: 1; }
  .slider--roadshow .swiper-slide:hover .embed-responsive-item img {
    transform: scale(1.07); }
  .slider--roadshow .swiper-slide:hover .swiper-slide__cta {
    opacity: 1;
    transform: translateY(0%); }
  .slider--roadshow .swiper-slide__cta {
    opacity: 0;
    transform: translateY(50%);
    transition: .5s all ease; }
  .slider--roadshow .swiper-slide .embed-responsive {
    max-width: 25rem;
    margin-left: auto;
    margin-right: auto;
    border-radius: 1.5rem;
    transform: translate3d(0, 0, 0); }
    @media (min-width: 768px) {
      .slider--roadshow .swiper-slide .embed-responsive {
        max-width: 20rem; } }
    .slider--roadshow .swiper-slide .embed-responsive-item:before {
      position: absolute;
      display: block;
      top: 1rem;
      left: 50%;
      width: 13rem;
      height: 4rem;
      margin-left: -6.5rem;
      background-image: url("../vectors/logo-smau.svg");
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      z-index: 2;
      content: ''; }
    .slider--roadshow .swiper-slide .embed-responsive-item img {
      transition: transform .8s ease; }

.slider--success-stories__img {
  max-width: 100%;
  max-height: 100%; }

.filters__heading--scrollable {
  position: relative; }
  .filters__heading--scrollable:after {
    position: absolute;
    display: block;
    width: 4rem;
    height: 6.5rem;
    top: 0;
    right: 0;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 100%);
    content: ''; }
  .filters__heading--scrollable .nav-tabs {
    display: block;
    white-space: nowrap;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden; }
    @media (min-width: 992px) {
      .filters__heading--scrollable .nav-tabs {
        display: flex;
        white-space: normal;
        overflow: initial; } }
    .filters__heading--scrollable .nav-tabs a {
      display: inline-block; }

.navbar__side-menu__content .tab-pane {
  padding: 0 .6rem;
  transform: translate3d(0, 0, 0); }

.navbar__side-menu__content__search-content__nav-tabs {
  position: sticky;
  display: block;
  width: 100%;
  top: -6.6rem;
  margin-top: -2.55rem;
  padding-right: 5rem;
  white-space: nowrap;
  overflow: auto;
  z-index: 2; }
  @media (min-width: 768px) {
    .navbar__side-menu__content__search-content__nav-tabs {
      top: -7rem;
      margin-top: -2.2rem;
      padding: 2.2rem 0; } }
  @media (max-width: 991.98px) {
    .navbar__side-menu__content__search-content__nav-tabs .embed-responsive {
      max-width: 14rem; } }
  .navbar__side-menu__content__search-content__nav-tabs .nav-item {
    display: inline-block;
    margin-left: 1rem; }
    .navbar__side-menu__content__search-content__nav-tabs .nav-item:first-child {
      margin-left: 0; }
  .navbar__side-menu__content__search-content__nav-tabs .nav-link {
    background-color: rgba(46, 40, 61, 0.1);
    font-size: 1.2rem;
    color: #FFFFFF;
    border: none;
    border-radius: 2rem;
    transition: shadow .3s ease; }
    .navbar__side-menu__content__search-content__nav-tabs .nav-link .badge {
      background-color: rgba(46, 40, 61, 0.2);
      color: #FFFFFF;
      text-align: center;
      border-radius: 2rem;
      transition: none; }
  .navbar__side-menu__content__search-content__nav-tabs .nav-link.active {
    color: #2E283D;
    background-color: #FFFFFF; }
    @supports (backdrop-filter: blur(20px)) {
      .navbar__side-menu__content__search-content__nav-tabs .nav-link.active {
        backdrop-filter: blur(20px);
        background-color: rgba(255, 255, 255, 0.8); } }
    .navbar__side-menu__content__search-content__nav-tabs .nav-link.active .badge {
      background-color: #2E283D;
      color: #FFFFFF; }

.display-1 .text-primary {
  background: linear-gradient(165deg, #0386A3 38%, #e2189c);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

@media (max-width: 767.98px) {
  .display-1 {
    font-size: calc(1.575rem + 5vw); } }

@media (max-width: 767.98px) {
  .display-2, .page-single__welcome h2:not(.display-1):not(.display-2):not(.display-3):not(.display-4), #simple-page h2:not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
    font-size: calc(1.445rem + 2.34vw); } }

@media (max-width: 767.98px) {
  .display-3, .page-single__welcome h3:not(.display-1):not(.display-2):not(.display-3):not(.display-4):not(.text-dark):not(.text-black) {
    font-size: calc(1.385rem + 1.62vw); } }

@media (max-width: 767.98px) {
  .display-4, .page-single__welcome h4:not(.display-1):not(.display-2):not(.display-3):not(.display-4),
  .page-single__welcome h5:not(.display-1):not(.display-2):not(.display-3):not(.display-4),
  .page-single__welcome h6:not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
    font-size: calc(1.305rem + .66vw); } }

.display-5 {
  font-size: 1.2rem; }

.display-initial {
  font-size: 1.4rem !important; }

.lh-0 {
  line-height: 0; }

.lh-1 {
  line-height: 1; }

.lh-2 {
  line-height: 1.2; }

.lh-3 {
  line-height: 1.4; }

.lh-4 {
  line-height: 1.6; }

.lh-5 {
  line-height: 2; }

.ls-1 {
  letter-spacing: 1px; }

.ls-2, .navbar__side-menu__content__search-content__nav-tabs .nav-link {
  letter-spacing: 2px; }

.ls-3 {
  letter-spacing: 3px; }

.ls-4 {
  letter-spacing: 4px; }

.ls-5 {
  letter-spacing: 5px; }

.text-faded {
  opacity: .7; }

.video-list {
  max-width: 1140px;
  padding: 0 30px;
  margin: auto auto 80px auto;
  display: flex;
  align-items: center;
  flex-direction: column; }
  .white-card .video-list {
    padding: 0; }
    .white-card .video-list .video-list-grid .video-list-item {
      background-color: #EFF2F3; }
  .profile .video-list {
    margin-top: 32px; }
    .profile .video-list .video-list-grid {
      grid-template-columns: 100%; }
      @media (min-width: 768px) {
        .profile .video-list .video-list-grid {
          grid-template-columns: repeat(auto-fit, calc(50% - 20px)); } }
      .profile .video-list .video-list-grid .video-list-item .item-title {
        font-size: 1.8rem;
        line-height: 2.1rem; }
        @media (min-width: 768px) {
          .profile .video-list .video-list-grid .video-list-item .item-title {
            font-size: 2.2rem;
            line-height: 2.6rem; } }
  .video-list .video-list-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, 100%);
    gap: 30px;
    width: 100%; }
    @media (min-width: 768px) {
      .video-list .video-list-grid {
        grid-template-columns: repeat(auto-fit, calc(50% - 15px)); } }
    @media (min-width: 992px) {
      .video-list .video-list-grid {
        grid-template-columns: repeat(auto-fit, calc(33.33% - 20px)); } }
    .video-list .video-list-grid .video-list-item {
      border-radius: 10px;
      background-color: #fff;
      overflow: hidden;
      color: #2E283D;
      position: relative;
      padding-bottom: 52.5px; }
      .video-list .video-list-grid .video-list-item:not(:has(.item-speaker)) {
        padding-bottom: 20px; }
      .video-list .video-list-grid .video-list-item:has(.item-speaker) {
        padding-bottom: 52.5px; }
      .video-list .video-list-grid .video-list-item.new-item {
        animation: fade-in .8s ease; }

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: scale(0.9); }
  100% {
    opacity: 1;
    transform: scale(1); } }
    .video-list .video-list-grid a:hover {
      text-decoration: none; }
      .video-list .video-list-grid a:hover img {
        transform: scale(1.2); }
    .video-list .video-list-grid .item-image {
      width: 100%;
      display: block;
      overflow: hidden; }
      .video-list .video-list-grid .item-image img {
        width: 100%;
        object-fit: cover;
        transition: transform .5s ease; }
    .video-list .video-list-grid span {
      display: block; }
    .video-list .video-list-grid .item-label {
      color: #97939f;
      font-size: 1.2rem;
      text-transform: uppercase;
      margin-top: 21px;
      padding: 0 20px; }
    .video-list .video-list-grid .item-title {
      font-size: 1.8rem;
      line-height: 2.6rem;
      margin-top: 10px;
      padding: 0 20px; }
      @media (min-width: 992px) {
        .video-list .video-list-grid .item-title {
          font-size: 2.2rem; } }
    .video-list .video-list-grid .item-speaker {
      font-size: 1.5rem;
      margin-top: 10px;
      font-weight: 400;
      position: absolute;
      bottom: 20px;
      padding: 0 20px 0 43px; }
      .video-list .video-list-grid .item-speaker::before {
        content: '';
        display: inline-block;
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        width: 13px;
        height: 14px;
        background-image: url("../vectors/ico-user-academy.svg");
        background-repeat: no-repeat;
        background-size: contain; }
    .video-list .video-list-grid + .load-more {
      margin-top: 60px; }
  .video-list .load-more {
    color: #0386A3;
    font-size: 1.2rem;
    padding: 14px 20px;
    border-radius: 100px;
    border: solid 2px #0386A3;
    display: inline-block;
    width: fit-content; }
    .video-list .load-more[disabled] {
      opacity: .5;
      cursor: default;
      pointer-events: none; }

@media (min-width: 992px) {
  .video-list.featured .video-list-grid {
    grid-template-columns: repeat(auto-fit, calc(50% - 15px)); } }

.video-list.featured .video-list-grid .video-list-item {
  background-image: linear-gradient(to right, #004F9E 0%, #0386A3 100%);
  color: #fff; }

.video-list.featured .video-list-grid .item-label {
  color: #accee4; }

.video-list.featured .video-list-grid .item-speaker::before {
  background-image: url("../vectors/ico-user-academy-light.svg"); }

/* layout */
@media (max-width: 991.98px) {
  .bottom-navigation .open .bottom-navigation__title:after {
    content: '-'; } }

.bottom-navigation__title:after {
  display: inline-block;
  margin-left: .7rem;
  opacity: .5;
  content: '+'; }
  @media (min-width: 768px) {
    .bottom-navigation__title:after {
      content: none; } }

.bottom-navigation__input-newsletter {
  border-width: 2px !important; }

.bottom-navigation__list {
  display: none; }
  @media (min-width: 768px) {
    .bottom-navigation__list {
      display: block !important; } }

.bottom-navigation a {
  transition: opacity .3s ease; }
  .bottom-navigation a:hover {
    opacity: .7; }

#meeting_request_create_form textarea {
  min-height: 15rem; }

#meeting_request_create_form .form__input {
  -webkit-box-flex: 0;
  flex: 0 0 83.33333%;
  max-width: 80%;
  margin-left: 10%; }

.navbar-station {
  height: 50px;
  margin-right: -10px; }
  .navbar-station img {
    margin-top: 10px; }

.navbar-brand {
  padding: 1rem 0;
  margin-right: 0; }
  .navbar-brand img {
    width: auto;
    height: auto;
    max-width: calc(100vw - 125px);
    max-height: 5rem; }
    @media (min-width: 320px) {
      .navbar-brand img {
        min-height: 5rem;
        max-width: 13.5rem;
        object-fit: contain; } }
    @media (min-width: 413px) {
      .navbar-brand img {
        max-width: 23rem; } }
    @media (min-width: 768px) {
      .navbar-brand img {
        max-width: none; } }

.navbar__toggler:before {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  right: -13px;
  z-index: 0;
  background-color: #0386A3;
  transform: skew(10deg, 0deg);
  content: ''; }

.navbar__toggler__icon {
  margin-right: 1.5rem; }
  @media (min-width: 768px) {
    .navbar__toggler__icon {
      margin-right: 2.2rem; } }
  .navbar__toggler__icon__line {
    width: 2.7rem;
    height: 2px;
    margin-bottom: 5px;
    transform-origin: left; }
    .navbar__toggler__icon__line:nth-child(2) {
      transform: scaleX(0.7); }
    .navbar__toggler__icon__line:nth-child(3) {
      transform: scaleX(0.5); }

.navbar__toggler__text__close {
  opacity: 0;
  visibility: hidden;
  transform: translateX(50%); }

.navbar__quick-links {
  font-weight: 600; }
  .navbar__quick-links > a, .navbar__quick-links .dropdown > a {
    padding: 0 1.5rem; }
    .navbar__quick-links > a:before, .navbar__quick-links .dropdown > a:before {
      position: absolute;
      display: block;
      width: 100%;
      height: .4rem;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(16deg, #0386A3 8%, #5A2392 100%);
      z-index: 1;
      transform: translateY(100%) scaleX(1);
      transform-origin: bottom;
      transition: background .3s ease, transform .3s ease;
      content: ''; }
    .navbar__quick-links > a.active:before, .navbar__quick-links .dropdown > a.active:before {
      transform: translateY(0%) scaleX(1); }
    .navbar__quick-links > a.active:hover:before, .navbar__quick-links .dropdown > a.active:hover:before {
      transform: translateY(0%) scaleX(0.8); }

.navbar__side-menu {
  visibility: hidden;
  opacity: 1;
  transform: translateX(15%); }
  .navbar__side-menu__content__background {
    transform: translateX(100%); }
    .navbar__side-menu__content__background:before {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: #0386A3;
      background-color: #2E283D;
      background-image: linear-gradient(172deg, #0386A3 32%, #5A2392 100%);
      transform: translateX(0%) skew(10deg, 0deg) scaleX(1.5);
      content: ''; }
      @media (min-width: 413px) {
        .navbar__side-menu__content__background:before {
          transform: translateX(0%) skew(10deg, 0deg) scaleX(1.8); } }
      @media (min-width: 768px) {
        .navbar__side-menu__content__background:before {
          transform: translateX(0%) skew(10deg, 0deg) scaleX(1.3); } }
  .navbar__side-menu__content__search {
    padding: 1rem 4rem 1rem 0;
    border-width: 0 0 3px 0;
    border-style: solid;
    border-color: #FFFFFF; }
    .navbar__side-menu__content__search:hover::placeholder {
      color: rgba(255, 255, 255, 0.6); }
    .navbar__side-menu__content__search::placeholder {
      color: rgba(255, 255, 255, 0.5);
      transition: color .3s ease; }
  .navbar__side-menu__content__search-content {
    display: none; }
  .navbar__side-menu__col {
    opacity: 0;
    transform: translateY(10rem) rotate(-4deg);
    transform-origin: top; }
    .navbar__side-menu__col a {
      transition: opacity .3s ease; }
      .navbar__side-menu__col a:hover {
        opacity: .7; }
    .navbar__side-menu__col__location::before, .navbar__side-menu__col__language::before, .navbar__side-menu__col__login::before {
      display: inline-block;
      width: 1.8rem;
      height: 1.8rem;
      margin-right: .6rem;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center bottom;
      content: ''; }
    .navbar__side-menu__col__location::before {
      background-image: url("../vectors/icon-map-marker.svg"); }
    .navbar__side-menu__col__language::before {
      background-image: url("../vectors/icon-globe-white.svg"); }
    .navbar__side-menu__col__login::before {
      background-image: url("../vectors/icon-user.svg"); }

.navbar .dropdown > a:after {
  position: relative;
  display: flex;
  width: 1.1rem;
  height: 1.1rem;
  margin-left: 1rem;
  background-image: url("../vectors/icon-caret.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 50% 53%;
  transition: transform .4s ease .05s;
  content: ''; }

.navbar .dropdown.show > a:after {
  transform: rotate(-180deg); }

.navbar .dropdown-menu {
  font-size: 1.4rem;
  border-radius: 0;
  z-index: 0;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-3.5rem);
  transition: all .4s ease; }
  .navbar .dropdown-menu.show {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    transition: opacity .1s linear, visibility .4s, transform .4s ease; }
  .navbar .dropdown-menu img {
    transition: transform .8s ease; }
    .navbar .dropdown-menu img:hover {
      transform: scale(1.1); }
  .navbar .dropdown-menu .col {
    max-width: 360px; }

.navbar__side-menu__content .overflow-auto {
  padding-left: 1.5rem;
  padding-right: 1.5rem; }
  @media (min-width: 768px) {
    .navbar__side-menu__content .overflow-auto {
      padding-left: 3rem;
      padding-right: 3rem; } }

.impersonitification-header {
  text-align: center;
  font-weight: 600;
  background-color: #F5F5F5;
  color: #333;
  border: 1px solid #333;
  padding: 1rem; }
  .impersonitification-header p {
    margin: 0;
    padding: 0; }

.type-hint {
  display: none;
  visibility: hidden;
  opacity: 0;
  transform: translateY(100px); }

@media (max-width: 991.98px) {
  .navbar__side-menu__main-search-container {
    margin-left: -1.5rem;
    margin-right: -1.5rem; } }

.search-result {
  background-color: #FFFFFF;
  transform: translate3d(0, 0, 0); }
  @media (max-width: 991.98px) {
    .search-result .embed-responsive {
      max-width: 14rem; } }
  @supports (backdrop-filter: blur(10px)) {
    .search-result {
      backdrop-filter: blur(10px);
      background-color: rgba(255, 255, 255, 0.9); } }

.search-reveal {
  opacity: 0;
  visibility: hidden;
  transform: translateY(2rem); }
  .search-reveal .schedule__el__abstract {
    display: flex;
    flex-direction: column; }
    .search-reveal .schedule__el__abstract a {
      margin-top: 1rem; }
  .search-reveal a {
    color: #0386A3;
    font-weight: bold; }

.search-content__type {
  border-bottom: 2px solid rgba(255, 255, 255, 0.5); }

.navbar__side-menu__content__search-content {
  opacity: 0;
  visibility: hidden; }

.navbar__side-menu__btn-search {
  width: 3rem;
  height: 3rem;
  margin-top: -2rem;
  background-image: url("../vectors/icon-search.svg");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 3rem;
  transition: transform .5s ease; }
  .navbar__side-menu__btn-search:hover {
    transform: rotate(-20deg); }

.dropdown-menu > .container > .row > .col:nth-child(6) {
  display: block; }
  @media (max-width: 1024px) {
    .dropdown-menu > .container > .row > .col:nth-child(6) {
      display: none; } }

.email-template a {
  color: #ee2e24;
  text-decoration: underline; }

.email-template a,
.email-template strong,
.email-template span,
.email-template p {
  font-size: 14px;
  line-height: 20px; }

.email-template ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px; }
  .email-template ul li {
    padding: 5px;
    margin-left: 15px; }

.email-template .border-row {
  width: 270px;
  margin: 3em auto;
  border-left: 5px solid #ee2e24;
  padding: 0 0 0 15px; }
  .email-template .border-row a {
    color: #15c;
    font-weight: bold; }

.email-template.footer-email a {
  font-weight: bold; }

::selection {
  background: #f5f5f5; }

::-moz-selection {
  background: #f5f5f5; }

img::selection {
  background: transparent; }

img::-moz-selection {
  background: transparent; }

select:-moz-focusring,
option:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000; }
  select:-moz-focusring:active, select:-moz-focusring:focus,
  option:-moz-focusring:active,
  option:-moz-focusring:focus {
    color: transparent;
    text-shadow: 0 0 0 #000; }

html {
  min-height: 100%;
  font-size: 10px; }

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1.4rem;
  color: #2E283D;
  background-color: #F5F5F5; }

main {
  flex: 1 0 auto; }

.vh-50 {
  height: 50vh; }

.vh-50-min {
  min-height: 50vh; }

.vh-100 {
  height: 100vh; }

.vh-100-min {
  min-height: 100vh; }

.top {
  top: 0; }
  .top-25 {
    top: 25%; }
  .top-50 {
    top: 50%; }

.left {
  left: 0; }
  .left-50 {
    left: 50%; }

.bottom {
  bottom: 0; }

.right {
  right: 0; }

.t-50 {
  transform: translate(-50%, -50%); }

.tx-50 {
  transform: translateX(-50%); }

.ty-50 {
  transform: translateY(-50%); }

.z-1 {
  z-index: 1; }

.z-2 {
  z-index: 2; }

.z-3 {
  z-index: 3; }

.z-4 {
  z-index: 4; }

.z-5 {
  z-index: 5; }

.fit-cover {
  object-fit: cover;
  object-position: center; }

.fit-contain {
  object-fit: contain;
  object-position: center; }

.o-hidden {
  overflow: hidden; }

@media (min-width: 768px) {
  .column-2 {
    column-count: 2;
    column-gap: 0; } }

.delay-1 {
  transition-delay: .1s !important; }

.delay-2 {
  transition-delay: .2s !important; }

.delay-3 {
  transition-delay: .3s !important; }

.delay-4 {
  transition-delay: .4s !important; }

.delay-5 {
  transition-delay: .5s !important; }

.delay-6 {
  transition-delay: .6s !important; }

.delay-7 {
  transition-delay: .7s !important; }

.delay-8 {
  transition-delay: .8s !important; }

.delay-9 {
  transition-delay: .9s !important; }

.delay-10 {
  transition-delay: 1s !important; }

.outline-none {
  outline: none;
  box-shadow: none; }
  .outline-none:hover, .outline-none:active, .outline-none:focus {
    outline: none; }

.divider {
  display: block;
  width: 100%;
  height: 2px; }

.img-cover {
  background-size: cover;
  background-repeat: no-repeat; }

.pt-navbar {
  padding-top: 7rem; }
  @media (min-width: 768px) {
    .pt-navbar {
      padding-top: 7rem; } }

@media (min-width: 992px) {
  .border-lg-right {
    border-right: 1px solid #D9D9D9; } }

.mt-navbar {
  margin-top: 6.2rem; }
  @media (min-width: 413px) {
    .mt-navbar {
      margin-top: 7rem; } }

.top-navbar {
  top: 6.2rem; }
  @media (min-width: 413px) {
    .top-navbar {
      top: 7rem; } }

.icon-social {
  max-width: 2.5rem;
  max-height: 2rem;
  vertical-align: sub;
  margin-right: 0.8rem; }
  .icon-social.icon-podcast {
    transform: scale(1.5); }

.reveal {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 1s linear, transform 1s cubic-bezier(0.4, 0.44, 0.27, 1.01); }
  .reveal.not-revealed {
    opacity: 0;
    transform: translateY(10%); }

.has-right-arrow {
  transition: opacity .4s ease; }
  .has-right-arrow:after {
    display: inline-block;
    width: 2.2rem;
    height: 1rem;
    margin-left: 1rem;
    background-image: url("../vectors/icon-arrow-right-dark.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    transition: transform .4s ease;
    content: ''; }
  .has-right-arrow:hover {
    opacity: .8; }
    .has-right-arrow:hover:after {
      transform: translateX(10px); }

@media (min-width: 992px) {
  .has-live-show-icon {
    padding-left: 3rem; } }

.has-live-show-icon[data-live-show="true"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  padding-left: 0; }
  @media (min-width: 992px) {
    .has-live-show-icon[data-live-show="true"] {
      justify-content: flex-end; } }

.has-live-show-icon::before {
  display: inline-block;
  width: 5.6rem;
  height: 4rem;
  margin-right: .8rem;
  vertical-align: middle;
  background-image: url("../vectors/icon-live-show-new.svg");
  background-repeat: no-repeat;
  background-size: 4.5rem;
  background-position: left center;
  content: ''; }

.has-speaker-icon:before,
.has-time-icon:before,
.has-message-icon:before {
  display: inline-block;
  width: 3.5rem;
  height: 3.5rem;
  background-repeat: no-repeat;
  background-position: center;
  content: ''; }

.has-speaker-icon:before {
  background-image: url("../vectors/icon-mic.svg");
  background-size: 2rem 2.8rem; }

.has-time-icon:before {
  background-image: url("../vectors/icon-time.svg");
  background-size: 2rem 2.4rem; }

.has-message-icon:before {
  background-image: url("../vectors/icon-message.svg");
  background-size: 2rem 2.8rem; }

.has-pin-icon:before {
  display: inline-block;
  width: 2rem;
  height: 2.5rem;
  margin-right: .8rem;
  vertical-align: middle;
  background-image: url("../vectors/icon-map-marker-dark.svg");
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: left center;
  content: ''; }

.has-bookmark-saved-icon {
  padding-left: 5rem; }
  .has-bookmark-saved-icon:before {
    position: absolute;
    display: inline-block;
    width: 2rem;
    height: 2.5rem;
    top: .5rem;
    left: 2.2rem;
    background-image: url("../vectors/icon-bookmark-saved-color.svg");
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: left center;
    content: ''; }

.filters__heading {
  top: 7rem;
  background-color: #FFFFFF;
  border-bottom: 1px solid #D9D9D9; }
  @media (min-width: 768px) {
    .filters__heading > div {
      padding: 0; } }
  @supports (backdrop-filter: blur(10px)) {
    .filters__heading {
      backdrop-filter: blur(10px);
      background-color: rgba(255, 255, 255, 0.9); } }
  .filters__heading .nav-item.active:after {
    display: block;
    width: 130%;
    height: .5rem;
    margin-top: 1rem;
    margin-left: -15%;
    background-color: #0386A3;
    content: ''; }
  @media (max-width: 767.98px) {
    .filters__heading--schedule {
      top: 1rem; } }

.schedule {
  min-height: 70vh; }
  .schedule__day__time-range--hided {
    display: none !important; }
  .schedule__day__time-range__indicator {
    position: sticky;
    top: 11.7rem;
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    margin: auto;
    text-align: center; }
    @media (min-width: 768px) {
      .schedule__day__time-range__indicator {
        position: relative;
        top: 0;
        text-align: left; } }
    .schedule__day__time-range__indicator__time {
      border-bottom: 1px solid #D9D9D9; }
      @media (min-width: 768px) {
        .schedule__day__time-range__indicator__time {
          position: sticky;
          top: 25rem;
          border-bottom: none; } }
      .schedule__day__time-range__indicator__time__hour {
        font-size: 1.8rem;
        font-weight: 300;
        line-height: 1.2; }
        @media (min-width: 768px) {
          .schedule__day__time-range__indicator__time__hour {
            font-size: 1.8rem; } }
        @media (min-width: 992px) {
          .schedule__day__time-range__indicator__time__hour {
            font-size: 2.6rem; } }
      .schedule__day__time-range__indicator__time__day {
        font-size: 2.8vw; }
        @media (min-width: 768px) {
          .schedule__day__time-range__indicator__time__day {
            font-size: unset; } }
  .schedule__el__areas, .schedule__el__details {
    font-size: 1.2rem; }
  .schedule__el__abstract {
    max-width: 55rem; }
    .schedule__el__abstract a {
      color: #0386A3;
      font-weight: bold; }
  .schedule-main-el {
    transition: all 1s ease; }
    .schedule-main-el--faded {
      transform: translateY(-10px);
      opacity: 0; }

.welcome--participant:before {
  position: absolute;
  display: block;
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url("../vectors/shape-cutted-rectangle-red.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top left;
  content: ''; }
  @media (min-width: 768px) {
    .welcome--participant:before {
      width: 44%;
      height: 40rem;
      background-size: cover;
      background-position: right bottom; } }
  @media (min-width: 1200px) {
    .welcome--participant:before {
      height: 45rem; } }
  @media (min-width: 1610px) {
    .welcome--participant:before {
      height: 30vw; } }

.welcome--participant__avatar:after {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #344AF0;
  border-radius: 1.5rem;
  z-index: 0;
  transform: translate(-7%, -5%);
  box-shadow: 1rem 0 3rem rgba(46, 40, 61, 0.5);
  content: ''; }
  @media (min-width: 320px) {
    .welcome--participant__avatar:after {
      border-radius: 2rem; } }
  @media (min-width: 768px) {
    .welcome--participant__avatar:after {
      border-radius: 3rem; } }

.welcome--participant__avatar__img {
  border-radius: 1.5rem;
  z-index: 1;
  box-shadow: 1rem 0 3rem rgba(46, 40, 61, 0.5); }
  @media (min-width: 413px) {
    .welcome--participant__avatar__img {
      border-radius: 2rem; } }
  @media (min-width: 768px) {
    .welcome--participant__avatar__img {
      border-radius: 3rem; } }

.welcome--participant__main-btn {
  border-width: 2px; }

.has-left-border {
  padding-left: 1.5rem;
  border-left: 4px solid rgba(46, 40, 61, 0.15); }

.participant__article:nth-child(odd) {
  background-color: rgba(46, 40, 61, 0.035); }

.participant__article__title {
  font-size: 1.8rem; }
  @media (min-width: 768px) {
    .participant__article__title {
      font-size: 2.6rem; } }

/* WIDGET */
.widget-button button,
.widget-button a {
  display: table;
  color: #0386A3;
  font-weight: 700;
  text-decoration: none !important;
  padding: 1rem 1.5rem;
  background-color: #FFFFFF;
  font-size: 1.2rem;
  border: 2px solid #0386A3;
  border-radius: 2rem;
  transition: all .3s ease; }
  .widget-button button:hover,
  .widget-button a:hover {
    background-color: #0386A3;
    color: #FFFFFF;
    text-decoration: none; }

.home-slide-button a:not(.x-btn) {
  display: inline-block;
  color: #FFFFFF;
  font-weight: 700;
  text-decoration: none;
  padding: 1rem 1.5rem;
  background-color: transparent;
  font-size: 1.2rem;
  border: 2px solid #FFFFFF;
  border-radius: 2rem;
  transition: all .3s ease;
  margin: 1em 0;
  min-width: 180px;
  text-align: center; }
  .home-slide-button a:not(.x-btn):hover {
    background-color: #FFFFFF;
    color: #222222;
    text-decoration: none; }

.widget-close-button {
  cursor: pointer; }

.modal:after {
  content: '';
  display: block;
  background-color: rgba(46, 40, 61, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1; }

.modal i {
  cursor: pointer; }

.schedule__item {
  display: none;
  min-height: 20rem;
  transition: background .5s ease;
  animation: none;
  border-top: 1px solid #dee2e6; }
  .schedule__item:first-child {
    border-top: 0; }
  .schedule__item--visible {
    display: block; }
  .schedule__item__container {
    opacity: 1;
    transform-origin: bottom;
    transition: transform 0.8s cubic-bezier(0.06, 0.61, 0.37, 1.26), opacity 1s ease; }
    .schedule__item__container .icon-alert {
      width: 1.5rem;
      vertical-align: middle; }
    .schedule__item__container .btn {
      min-width: 16.5rem; }
    .schedule__item__container .btn--confirmed__text--subscribed:before {
      background-size: 1.8rem; }
    .schedule__item__container .btn--confirmed__text--unsubscribe:before {
      background-size: 1.2rem; }
    .schedule__item__container .category-block {
      font-size: 1.2rem; }
      .schedule__item__container .category-block > span:not(:first-child) {
        margin-left: 0.6rem; }
  .schedule__item.not-revealed {
    background-color: #FFFFFF; }
    .schedule__item.not-revealed:before, .schedule__item.not-revealed:after {
      position: relative;
      display: block;
      margin: 0;
      background-image: linear-gradient(-45deg, #FFFFFF, #F5F5F5, #F5F5F5, #FFFFFF);
      background-size: 400% 400%;
      animation: gradientPlaceholder 4s ease infinite;
      content: ''; }
      @media (min-width: 992px) {
        .schedule__item.not-revealed:before, .schedule__item.not-revealed:after {
          margin: 0 10%; } }
    .schedule__item.not-revealed:before {
      width: 100%;
      height: 6rem; }
      @media (min-width: 992px) {
        .schedule__item.not-revealed:before {
          width: 65%; } }
    .schedule__item.not-revealed:after {
      margin-top: 2rem;
      width: calc(100% - 30px);
      height: 12rem;
      animation-direction: reverse; }
      @media (min-width: 992px) {
        .schedule__item.not-revealed:after {
          width: 90%; } }
    .schedule__item.not-revealed .schedule__item__container {
      opacity: 0;
      transform: scale(0.95); }

.live-show-indicator {
  display: none; }
  .live-show-indicator[data-live-show="true"] {
    display: flex; }

.product__expand-description {
  transition: height .7s ease; }
  .product__expand-description--not-expanded {
    height: 17rem; }

.rating__dot {
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  margin-left: .5rem; }

@media (min-width: 992px) {
  .welcome--participant__location {
    padding-left: 3rem; } }

.welcome--participant__location.has-pin-icon:before {
  display: inline-block; }
  @media (min-width: 992px) {
    .welcome--participant__location.has-pin-icon:before {
      position: absolute;
      top: .1rem;
      left: 0; } }

.floating-agenda {
  background-color: rgba(46, 40, 61, 0.3);
  opacity: 0;
  visibility: hidden; }
  .floating-agenda__panel {
    margin: 3rem;
    max-width: 60rem;
    border-radius: 1rem;
    transform: translate(2rem, 2rem); }
    @media (min-width: 768px) {
      .floating-agenda__panel {
        margin: 5rem; } }
    .floating-agenda__panel__events {
      max-height: 70vh;
      overflow: auto; }
      @media (min-width: 768px) {
        .floating-agenda__panel__events {
          max-height: 60rem; } }
    .floating-agenda__panel__background {
      border-radius: 50%;
      transform-origin: bottom right;
      transform: translate(50%, 50%) scale(0); }
    .floating-agenda__panel__content {
      opacity: 0;
      transform-origin: bottom;
      transform: translateY(10px) scale(0.97); }
  .floating-agenda__filters {
    top: 0; }
  .floating-agenda__day-heading {
    border-bottom: 1px solid #dee2e6; }
  .floating-agenda__list li {
    border-top: 1px solid #dee2e6; }
    .floating-agenda__list li:first-child {
      border-top: none; }
  .floating-agenda .filters__heading {
    top: 0; }

iframe.speaker-slide {
  width: 787px;
  height: 466px; }
  @media (max-width: 991.98px) {
    iframe.speaker-slide {
      width: 100%;
      height: 100%; } }

.no-result {
  display: none; }
  .no-result--visible {
    display: block; }

.jssocials-shares {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly; }
  .jssocials-shares .jssocials-share {
    margin-right: 0; }
    .jssocials-shares .jssocials-share .jssocials-share-link span.jssocials-share-label {
      display: none; }

@media (max-width: 767.98px) {
  .w-xs-100 {
    width: 100%; } }

.close-button {
  cursor: pointer; }

.hide-block {
  opacity: 0; }

.heading--white h1 {
  font-size: 4.5rem;
  color: #2e283d !important;
  font-weight: 700 !important;
  line-height: 1.2; }
  @media (max-width: 767.98px) {
    .heading--white h1 {
      font-size: calc(1.575rem + 3.9vw); } }
  .heading--white h1 strong {
    font-weight: 700 !important;
    background: linear-gradient(165deg, #ed1c24 38%, #e2189c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; }
    @media (max-width: 767.98px) {
      .heading--white h1 strong {
        display: inline-block; } }

.heading--white p {
  margin-top: 1rem !important;
  color: #2e283d !important;
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 1.2; }

.profile-progress.progress {
  height: 2.5em;
  background: #bbb; }
  .profile-progress.progress .progress-bar {
    height: 2.5em; }

.pimcore_area_entry > .pimcore_area_buttons.top {
  width: 430px; }

.medium-icon-size {
  width: 130px; }

.small-icon-size {
  width: 70px; }

.navbar__side-menu__content__search-content__nav-tabs .nav-item .nav-link {
  letter-spacing: 0.8px; }
  .navbar__side-menu__content__search-content__nav-tabs .nav-item .nav-link .badge {
    padding: .25em .42em; }

@media (max-width: 767.98px) {
  .partners-news-image {
    max-width: 200px; } }

.border-top-1 {
  border-top: 1px solid; }

.border-top-2 {
  border-top: 2px solid; }

.border-top-3 {
  border-top: 3px solid; }

.border-top-4 {
  border-top: 4px solid; }

.border-top-5 {
  border-top: 5px solid; }

.border-top-6 {
  border-top: 6px solid; }

.border-top-7 {
  border-top: 7px solid; }

.border-top-8 {
  border-top: 8px solid; }

.border-top-9 {
  border-top: 9px solid; }

.border-top-10 {
  border-top: 10px solid; }

.border-bottom-1 {
  border-bottom: 1px solid; }

.border-bottom-2 {
  border-bottom: 2px solid; }

.border-bottom-3 {
  border-bottom: 3px solid; }

.border-bottom-4 {
  border-bottom: 4px solid; }

.border-bottom-5 {
  border-bottom: 5px solid; }

.border-bottom-6 {
  border-bottom: 6px solid; }

.border-bottom-7 {
  border-bottom: 7px solid; }

.border-bottom-8 {
  border-bottom: 8px solid; }

.border-bottom-9 {
  border-bottom: 9px solid; }

.border-bottom-10 {
  border-bottom: 10px solid; }

.border-left-1 {
  border-left: 1px solid; }

.border-left-2 {
  border-left: 2px solid; }

.border-left-3 {
  border-left: 3px solid; }

.border-left-4 {
  border-left: 4px solid; }

.border-left-5 {
  border-left: 5px solid; }

.border-left-6 {
  border-left: 6px solid; }

.border-left-7 {
  border-left: 7px solid; }

.border-left-8 {
  border-left: 8px solid; }

.border-left-9 {
  border-left: 9px solid; }

.border-left-10 {
  border-left: 10px solid; }

.border-right-1 {
  border-right: 1px solid; }

.border-right-2 {
  border-right: 2px solid; }

.border-right-3 {
  border-right: 3px solid; }

.border-right-4 {
  border-right: 4px solid; }

.border-right-5 {
  border-right: 5px solid; }

.border-right-6 {
  border-right: 6px solid; }

.border-right-7 {
  border-right: 7px solid; }

.border-right-8 {
  border-right: 8px solid; }

.border-right-9 {
  border-right: 9px solid; }

.border-right-10 {
  border-right: 10px solid; }

.pt-6 {
  padding-top: 3.5rem !important; }

.pt-7 {
  padding-top: 4rem !important; }

.pt-8 {
  padding-top: 4.5rem !important; }

.pt-9 {
  padding-top: 5rem !important; }

.pt-10 {
  padding-top: 5.5rem !important; }

.pb-6 {
  padding-bottom: 3.5rem !important; }

.pb-7 {
  padding-bottom: 4rem !important; }

.pb-8 {
  padding-bottom: 4.5rem !important; }

.pb-9 {
  padding-bottom: 5rem !important; }

.pb-10 {
  padding-bottom: 5.5rem !important; }

.pl-6 {
  padding-left: 3.5rem !important; }

.pl-7 {
  padding-left: 4rem !important; }

.pl-8 {
  padding-left: 4.5rem !important; }

.pl-9 {
  padding-left: 5rem !important; }

.pl-10 {
  padding-left: 5.5rem !important; }

.pr-6 {
  padding-right: 3.5rem !important; }

.pr-7 {
  padding-right: 4rem !important; }

.pr-8 {
  padding-right: 4.5rem !important; }

.pr-9 {
  padding-right: 5rem !important; }

.pr-10 {
  padding-right: 5.5rem !important; }

.mt-6 {
  padding-top: 3.5rem !important; }

.mt-7 {
  padding-top: 4rem !important; }

.mt-8 {
  padding-top: 4.5rem !important; }

.mt-9 {
  padding-top: 5rem !important; }

.mt-10 {
  padding-top: 5.5rem !important; }

.mb-6 {
  padding-bottom: 3.5rem !important; }

.mb-7 {
  padding-bottom: 4rem !important; }

.mb-8 {
  padding-bottom: 4.5rem !important; }

.mb-9 {
  padding-bottom: 5rem !important; }

.mb-10 {
  padding-bottom: 5.5rem !important; }

.mb-11 {
  padding-bottom: 9.5rem !important; }

.ml-6 {
  padding-left: 3.5rem !important; }

.ml-7 {
  padding-left: 4rem !important; }

.ml-8 {
  padding-left: 4.5rem !important; }

.ml-9 {
  padding-left: 5rem !important; }

.ml-10 {
  padding-left: 5.5rem !important; }

.mr-6 {
  padding-right: 3.5rem !important; }

.mr-7 {
  padding-right: 4rem !important; }

.mr-8 {
  padding-right: 4.5rem !important; }

.mr-9 {
  padding-right: 5rem !important; }

.mr-10 {
  padding-right: 5.5rem !important; }

html#facebook body div {
  width: 100%; }

html.SandboxRoot body {
  max-height: 259px;
  overflow-y: scroll; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-truncated {
  display: flex;
  flex-direction: column; }
  .text-truncated a {
    margin-top: 1rem; }

body a {
  color: #0386A3;
  font-weight: bold;
  text-decoration: none; }
  body a:hover {
    color: #0386A3; }

.alert.alert-success {
  margin: 0 -15px; }

.col-lg-7 .area-video-container iframe {
  min-height: 450px; }
  @media (max-width: 1610px) {
    .col-lg-7 .area-video-container iframe {
      min-height: 280px; } }
  @media (max-width: 990px) {
    .col-lg-7 .area-video-container iframe {
      min-height: unset;
      max-height: 200px; } }
  @media (max-width: 767px) {
    .col-lg-7 .area-video-container iframe {
      min-height: unset;
      max-height: 280px; } }
  @media (max-width: 415px) {
    .col-lg-7 .area-video-container iframe {
      max-height: 215px; } }
  @media (max-width: 375px) {
    .col-lg-7 .area-video-container iframe {
      max-height: 190px; } }
  @media (max-width: 350px) {
    .col-lg-7 .area-video-container iframe {
      max-height: 165px; } }

.flickr-feed .embed-responsive-item p {
  margin-bottom: 0; }

.flickr-feed .embed-responsive-item a img {
  width: 100% !important;
  height: 100% !important; }

.flickr-feed iframe {
  width: 100% !important;
  height: 100% !important; }

#main-partners .filters-heading {
  width: calc(100% + 20px);
  margin-left: -20px !important;
  border-bottom: solid 1px #D9D9D9;
  text-align: center; }

#main-partners #tag-filter {
  max-width: 1440px;
  width: 100%;
  gap: 1rem;
  position: relative;
  scroll-timeline-name: --tag-filter;
  scroll-timeline-axis: x; }
  #main-partners #tag-filter::after {
    content: '';
    position: fixed;
    bottom: 0;
    right: 0;
    height: 81px;
    width: 40px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #fff);
    pointer-events: none; }
    @supports (animation-timeline: --tag-filter) {
      #main-partners #tag-filter::after {
        animation: fade-out 1s forwards ease-in-out;
        animation-duration: .5s;
        animation-timeline: --tag-filter;
        animation-range-start: exit 90%; } }

@keyframes fade-out {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }
  @media (min-width: 768px) {
    #main-partners #tag-filter {
      gap: 1.5rem;
      justify-content: center;
      flex-wrap: wrap; }
      #main-partners #tag-filter::after {
        display: none; } }

.reference-item .card__image__text {
  background: linear-gradient(to bottom, #eb1c25 0%, #692487 100%);
  min-height: 33%; }

.embed-responsive-3by4:before {
  padding-top: 125%; }

/* pages */
.links-within-shape {
  position: relative;
  padding: 2rem .5rem 1.5rem; }
  @media (min-width: 992px) {
    .links-within-shape {
      position: absolute;
      display: inline-block;
      right: 0;
      top: 100%;
      background: none;
      padding: 7rem 10% 12% 7rem;
      transform: rotate(17deg) translateY(-85%);
      z-index: 1; } }
  @media (min-width: 1610px) {
    .links-within-shape {
      padding: 7rem 10% 10% 7rem; } }
  .links-within-shape:before, .links-within-shape:after {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1; }
  .links-within-shape:before {
    background-color: #2E283D;
    transform: translateX(25%) translateY(25%) scale(1.5) rotate(25deg); }
    @media (min-width: 992px) {
      .links-within-shape:before {
        content: ''; } }
  .links-within-shape:after {
    background-color: #0386A3;
    background-image: linear-gradient(165deg, #0386A3 8%, #E2189C 100%);
    content: ''; }
  @media (min-width: 992px) {
    .links-within-shape__list {
      transform: rotate(-17deg); } }
  .links-within-shape__list li {
    float: left;
    vertical-align: middle; }
    .links-within-shape__list li a:before {
      position: relative;
      display: inline-block;
      width: 1.2rem;
      height: 1.2rem;
      top: 1px;
      margin-right: .2rem;
      background-image: url("../vectors/icon-plus.svg");
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      content: ''; }
      @media (min-width: 413px) {
        .links-within-shape__list li a:before {
          margin-right: .7rem; } }
    @media (min-width: 992px) {
      .links-within-shape__list li {
        float: none; } }

.welcome__shape {
  width: 101%;
  bottom: -1px; }

#main-homepage h2 {
  font-size: 4.5rem;
  line-height: 1.2;
  font-weight: 700; }
  #main-homepage h2 strong {
    background: linear-gradient(165deg, #ed1c24 38%, #e2189c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700; }
  @media (max-width: 767.98px) {
    #main-homepage h2 {
      font-size: calc(1.575rem + 3.9vw); } }

.main-dashboard__welcome {
  background-image: url(../images/bg-login-register.jpg);
  background-size: cover;
  background-position: center; }
  .main-dashboard__welcome--roadshow {
    background-image: url(../images/padova.jpg); }
    .main-dashboard__welcome--roadshow:before {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(46, 40, 61, 0.4);
      content: ''; }
  .main-dashboard__welcome .text-primary {
    background: linear-gradient(165deg, #0386A3 38%, #E2189C);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; }

.main-dashboard__welcome-bottom {
  margin-top: -4rem; }
  .main-dashboard__welcome-bottom .filters__heading {
    top: 0; }

.dashboard-avatar {
  background-image: url("../vectors/icon-user-dark.svg");
  background-color: #F5F5F5;
  background-repeat: no-repeat;
  background-size: 5rem;
  background-position: 50% 40%; }
  @media (min-width: 768px) {
    .dashboard-avatar {
      background-size: 8rem; } }
  .dashboard-avatar:hover {
    background-color: #FFFFFF;
    transition: background-color .4s ease; }
  .dashboard-avatar__cta {
    border-top: 1px solid #D9D9D9; }

.main-dashboard__list li .main-dashboard__list__el {
  border-top: 1px solid rgba(46, 40, 61, 0.1); }
  .main-dashboard__list li .main-dashboard__list__el .calendar-icon {
    width: 40px;
    height: 40px;
    object-fit: contain; }

.main-dashboard__list li:first-child .main-dashboard__list__el {
  border-top: 1px solid transparent; }

#visitor_profile_edit_channelSwitch_placeholder,
label[for="visitor_profile_edit_channelSwitch_placeholder"] {
  display: none; }

.loader-schedule-my-area {
  top: 25px; }

.event .shadow-lg {
  box-shadow: 0 2px 143px 0 rgba(0, 0, 0, 0.11) !important; }

.event__info-block {
  background-color: rgba(46, 40, 61, 0.07);
  border-radius: 4px; }
  @media (min-width: 768px) {
    .event__info-block {
      margin-left: 1rem; }
      .event__info-block:first-child {
        margin-left: 0; } }
  .event__info-block:before {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: ''; }
  .event__info-block--calendar:before {
    background-image: url("../vectors/icon-calendar.svg"); }
  .event__info-block--time:before {
    background-image: url("../vectors/icon-clock.svg"); }
  .event__info-block--location:before {
    background-image: url("../vectors/icon-location.svg"); }

.event .card {
  border-radius: 5px;
  margin-top: -5rem; }
  @media (min-width: 992px) {
    .event .card {
      margin-top: -15rem; } }
  .event .card .btn--rounded {
    min-width: 100%; }
    @media (min-width: 768px) {
      .event .card .btn--rounded {
        min-width: 22rem; } }

.event__section-partecipant:before {
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(222deg, #E6376A 0%, #0386A3 100%);
  z-index: 0;
  transform: skew(0deg, -3deg);
  content: ''; }

.event__section-partecipant.special-event-speaker:before {
  background-image: linear-gradient(222deg, #007bff 0%, #6f42c1 100%); }

.event__col-sticky {
  position: sticky;
  top: 10rem; }

.event aside .img-fluid {
  max-width: 30rem; }
  @media (min-width: 992px) {
    .event aside .img-fluid {
      max-width: 100%; } }

.event aside .embed-responsive-1by1, .event aside .page-single__welcome .embed-responsive.page-single-main-image, .page-single__welcome .event aside .embed-responsive.page-single-main-image {
  border-radius: 10px;
  max-width: 14rem; }

@media (min-width: 992px) and (max-width: 1609.98px) {
  #main-workshop .card {
    width: 90%;
    margin-left: auto;
    margin-right: auto; } }

.streaming-bar {
  position: sticky;
  box-shadow: 0 5px 34px 0 rgba(46, 40, 61, 0.5);
  opacity: 1;
  visibility: visible;
  transition: all 1s ease; }
  @supports (position: sticky) {
    .streaming-bar {
      position: sticky; } }
  .streaming-bar--hided {
    transform: translateY(100%);
    opacity: 0;
    visibility: hidden; }
  .streaming-bar .embed-responsive {
    max-width: 10rem;
    transition: transform .4s ease; }
    @media (min-width: 992px) {
      .streaming-bar .embed-responsive {
        max-width: 12rem; } }
    .streaming-bar .embed-responsive:hover {
      transform: scale(0.98); }
    .streaming-bar .embed-responsive:active, .streaming-bar .embed-responsive:focus {
      transform: scale(0.94); }
    .streaming-bar .embed-responsive:after {
      position: absolute;
      display: block;
      width: 2rem;
      height: 2rem;
      top: 50%;
      left: 50%;
      margin-top: -1rem;
      margin-left: -1rem;
      background-image: url("../vectors/icon-play.svg");
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-position: center;
      content: ''; }
  .streaming-bar__title {
    font-size: 1.2rem; }
    @media (min-width: 768px) {
      .streaming-bar__title {
        font-size: 1.8rem; } }
  .streaming-bar__live-text {
    font-size: 1rem; }
    @media (min-width: 768px) {
      .streaming-bar__live-text {
        font-size: 1.2rem; } }
    .streaming-bar__live-text__icon {
      width: .8rem;
      height: .8rem;
      margin-right: .8rem;
      animation: pulse 1.5s ease infinite; }
  .streaming-bar__close {
    font-size: 2rem;
    text-shadow: none;
    transition: color .4s ease; }
  .streaming-bar a, .streaming-bar a:hover {
    color: #fff; }

.icon-alert {
  width: 2rem; }

.unsubscribe-label {
  display: none; }
  @media (pointer: coarse) {
    .unsubscribe-label {
      display: block; } }

.welcome--special-event .embed-responsive:before,
.welcome--live-show .embed-responsive:before {
  padding-top: 42.85%; }
  @media (min-width: 992px) {
    .welcome--special-event .embed-responsive:before,
    .welcome--live-show .embed-responsive:before {
      padding-top: 33%; } }

.slider--faces.no-slider .swiper-wrapper {
  justify-content: center; }
  .slider--faces.no-slider .swiper-wrapper .swiper-slide {
    flex-shrink: 1; }
    @media (min-width: 992px) {
      .slider--faces.no-slider .swiper-wrapper .swiper-slide {
        max-width: 20%; } }

.schedule__item__container:not(:first-child) {
  border-top: 1px solid #dee2e6; }

#main-login-register:after {
  position: absolute;
  display: block;
  width: 100%;
  height: 30%;
  top: 0;
  left: 0;
  background-color: #2E283D;
  background-image: url(../images/bg-login-register.jpg);
  background-size: cover;
  background-position: bottom;
  content: ''; }
  @media (min-width: 768px) {
    #main-login-register:after {
      height: 40%; } }
  @media (min-width: 992px) {
    #main-login-register:after {
      min-height: 12rem; } }

#main-login-register #visitor_login_submit,
#main-login-register #visitor_registration_form_submit {
  width: 350px;
  max-width: 100%; }

#main-login-register .login-social-row > a {
  display: block;
  width: 350px;
  max-width: 100%;
  margin: 0 calc(-15px / 2);
  padding: 1.5rem 1.6rem; }
  #main-login-register .login-social-row > a:hover {
    text-decoration: none; }
  #main-login-register .login-social-row > a + a {
    margin-top: 1.5rem; }
  #main-login-register .login-social-row > a img {
    top: 50%;
    transform: translateY(-50%); }

.welcome--participant__recent-img {
  max-width: 22rem; }

@media (min-width: 992px) {
  .partner-single .welcome--participant:before {
    width: 37%; } }

.partner-single .welcome--participant__img {
  max-height: 6rem; }

.partner-single .welcome--participant__website {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.section-products {
  max-width: 144rem; }

.partner-main-el--faded {
  transform: translateY(-10px);
  opacity: 0; }

.partner-profile-description-block p {
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 1.6;
  margin-bottom: 1rem; }

.speaker__item__container .embed-responsive-item {
  transition: transform .5s ease; }

.speaker__item__container:hover .embed-responsive-item {
  transform: scale(1.05); }

.speaker__item__container .embed-responsive {
  border-radius: 1rem;
  transform: translate3d(0, 0, 0); }
  @media (min-width: 768px) {
    .speaker__item__container .embed-responsive {
      border-radius: 1.5rem; } }

.page-single__welcome h1 {
  background: linear-gradient(165deg, #FFFFFF 38%, #FFFFFF);
  -webkit-background-clip: text;
  font-weight: bolder;
  z-index: 2;
  font-size: 4.5rem;
  line-height: 1.2; }
  @media (max-width: 767.98px) {
    .page-single__welcome h1 {
      font-size: calc(1.575rem + 3.9vw); } }
  .page-single__welcome h1 strong, .page-single__welcome h1 .text-primary {
    background: linear-gradient(165deg, #0386A3 38%, #e2189c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700; }
    @media (max-width: 767.98px) {
      .page-single__welcome h1 strong, .page-single__welcome h1 .text-primary {
        display: inline-block; } }
  .page-single__welcome h1 p {
    line-height: 1.2; }

.page-single__welcome h2 strong, .page-single__welcome h3 strong, .page-single__welcome h5 strong, .page-single__welcome h6 strong, .page-single__welcome p strong {
  font-weight: bold; }

.page-single__welcome h2 a, .page-single__welcome h3 a, .page-single__welcome h5 a, .page-single__welcome h6 a, .page-single__welcome p a {
  color: #0386A3;
  font-weight: bold;
  text-decoration: underline; }

.page-single__welcome a {
  color: #0386A3;
  font-weight: bold;
  text-decoration: underline; }

.page-single__welcome h2:not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  font-weight: normal;
  line-height: 1.6; }

.page-single__welcome h3:not(.display-1):not(.display-2):not(.display-3):not(.display-4):not(.text-dark):not(.text-black) {
  font-weight: normal;
  line-height: 1.6; }

.page-single__welcome p:not(.display-1):not(.display-2):not(.display-3):not(.display-4):not(.text-black):not(.card__text) {
  font-size: 1.8rem;
  line-height: 2; }
  .page-single__welcome p:not(.display-1):not(.display-2):not(.display-3):not(.display-4):not(.text-black):not(.card__text).news__el__abstract {
    font-size: 1.6rem;
    line-height: 1.8; }
  .page-single__welcome p:not(.display-1):not(.display-2):not(.display-3):not(.display-4):not(.text-black):not(.card__text).schedule__el__abstract {
    font-size: 1.4rem;
    line-height: 1.5; }

.page-single__welcome ul {
  padding-left: 18px;
  font-size: 1.8rem;
  line-height: 2; }
  .page-single__welcome ul a {
    color: #0386A3;
    font-weight: bold;
    text-decoration: underline; }
  .page-single__welcome ul li.schedule__item a.schedule-item-action-button {
    color: #FFFFFF; }

@media (min-width: 768px) {
  .page-single__welcome .embed-responsive.page-single-main-image:before {
    padding-top: 39%; } }

@media (min-width: 1200px) {
  .page-single__welcome .embed-responsive.page-single-main-image:before {
    padding-top: 30%; } }

@media (min-width: 1610px) {
  .page-single__welcome .embed-responsive.page-single-main-image:before {
    padding-top: 22%; } }

.page-single__welcome .embed-responsive.page-single-main-image:after {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(0deg, #2e283d 0%, rgba(46, 40, 61, 0) 100%);
  content: '';
  z-index: 0; }

.page-single__welcome .schedule__item__container h3 {
  line-height: 1.2; }

.page-single__welcome .thematic-area p {
  font-size: 1.4rem;
  line-height: 1.5; }

.page-single__welcome .schedule__day__time-range__indicator {
  top: 11rem; }

@media (max-width: 767.98px) {
  .page-single__welcome .filters__heading--schedule {
    top: 7rem; } }

.page-single__welcome .hot-topic p {
  font-size: 1.4rem;
  line-height: 1.5; }

.page-single__welcome .partner__item__container h3 {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.2; }
  @media (max-width: 767.98px) {
    .page-single__welcome .partner__item__container h3 {
      font-size: calc(1.305rem + .66vw); } }

.page-single__welcome .partner__item__container p {
  font-size: 1.2rem;
  line-height: 1.5; }
  @media (min-width: 992px) {
    .page-single__welcome .partner__item__container p {
      font-size: 1.4rem; } }
  @media (min-width: 1610px) {
    .page-single__welcome .partner__item__container p {
      max-width: 30rem; } }

.social-share-wrapper {
  text-align: center; }
  .social-share-wrapper .social-share-container {
    display: inline-block;
    min-width: 200px; }

.organization-news-content a,
.organization-news-content a > * {
  color: #0386A3 !important; }

.bulletin-page .block-grid {
  width: 100% !important;
  max-width: none !important; }

.bulletin-page #contentFooter {
  display: none; }

.bulletin-page .nl-container .row {
  display: revert;
  margin-left: 0;
  margin-right: 0; }

.bulletin-page .nl-container table.row-content {
  width: 100%; }

.bulletin-page .nl-container .border {
  border: none !important; }

#simple-page h1 {
  background: linear-gradient(165deg, #FFFFFF 38%, #FFFFFF);
  -webkit-background-clip: text;
  font-weight: bolder;
  z-index: 2;
  font-size: 4.5rem;
  line-height: 1.2; }
  @media (max-width: 767.98px) {
    #simple-page h1 {
      font-size: calc(1.575rem + 3.9vw); } }
  #simple-page h1 strong, #simple-page h1 .text-primary {
    background: linear-gradient(165deg, #0386A3 38%, #e2189c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700; }
    @media (max-width: 767.98px) {
      #simple-page h1 strong, #simple-page h1 .text-primary {
        display: inline-block; } }

#simple-page h2:not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  font-weight: normal;
  line-height: 1.6; }

#simple-page p:not(.display-1):not(.display-2):not(.display-3):not(.display-4):not(.text-black):not(.card__text) {
  font-size: 1.8rem;
  line-height: 2; }

.tab-container {
  max-width: 1000px;
  margin: auto; }
  .tab-container div.nav-tabs {
    justify-content: center; }
    .tab-container div.nav-tabs a.nav-link {
      color: #222;
      font-size: 1.6rem;
      padding: 20px;
      border: 0; }
      @media (max-width: 767.98px) {
        .tab-container div.nav-tabs a.nav-link {
          font-size: 1.2rem;
          padding: 10px 6px; } }
      .tab-container div.nav-tabs a.nav-link.active {
        position: relative;
        border: 0;
        background-color: transparent;
        border-bottom: 6px solid #0386A3; }
      .tab-container div.nav-tabs a.nav-link:hover {
        border: none;
        border-bottom: 6px solid #f1f1f1; }

.page .rdueb-home-block,
#simple-page .rdueb-home-block {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 70px;
  padding-bottom: 90px; }
  @media (max-width: 767.98px) {
    .page .rdueb-home-block,
    #simple-page .rdueb-home-block {
      padding: 15px; } }
  .page .rdueb-home-block:before,
  #simple-page .rdueb-home-block:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 0; }
  .page .rdueb-home-block .container,
  #simple-page .rdueb-home-block .container {
    position: relative;
    z-index: 1; }
  .page .rdueb-home-block .row,
  #simple-page .rdueb-home-block .row {
    background: #fff;
    border-left: 18px solid #0386A3;
    padding: 60px;
    align-items: center; }
    @media (max-width: 767.98px) {
      .page .rdueb-home-block .row,
      #simple-page .rdueb-home-block .row {
        padding: 30px 20px; } }
    .page .rdueb-home-block .row .col-lg-6,
    #simple-page .rdueb-home-block .row .col-lg-6 {
      position: relative;
      padding-left: 50px; }
      @media (max-width: 767.98px) {
        .page .rdueb-home-block .row .col-lg-6,
        #simple-page .rdueb-home-block .row .col-lg-6 {
          padding-left: 15px; } }
      .page .rdueb-home-block .row .col-lg-6 h2:not(.display-1):not(.display-2):not(.display-3):not(.display-4),
      #simple-page .rdueb-home-block .row .col-lg-6 h2:not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
        font-size: 28px;
        font-weight: 700;
        text-transform: uppercase;
        margin-top: 25px;
        margin-bottom: 25px; }
      .page .rdueb-home-block .row .col-lg-6 p.subtitle:not(.display-1):not(.display-2):not(.display-3):not(.display-4):not(.text-black):not(.card__text),
      #simple-page .rdueb-home-block .row .col-lg-6 p.subtitle:not(.display-1):not(.display-2):not(.display-3):not(.display-4):not(.text-black):not(.card__text) {
        font-size: 22px;
        line-height: 31px; }
      .page .rdueb-home-block .row .col-lg-6 p:not(.display-1):not(.display-2):not(.display-3):not(.display-4):not(.text-black):not(.card__text),
      #simple-page .rdueb-home-block .row .col-lg-6 p:not(.display-1):not(.display-2):not(.display-3):not(.display-4):not(.text-black):not(.card__text) {
        font-size: 16px;
        line-height: 23px; }
      .page .rdueb-home-block .row .col-lg-6.first-column,
      #simple-page .rdueb-home-block .row .col-lg-6.first-column {
        padding-left: 0;
        padding-right: 50px; }
        @media (max-width: 767.98px) {
          .page .rdueb-home-block .row .col-lg-6.first-column,
          #simple-page .rdueb-home-block .row .col-lg-6.first-column {
            padding-right: 15px; } }
        .page .rdueb-home-block .row .col-lg-6.first-column:after,
        #simple-page .rdueb-home-block .row .col-lg-6.first-column:after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 1px;
          background: #d8d8d8; }
          @media (max-width: 767.98px) {
            .page .rdueb-home-block .row .col-lg-6.first-column:after,
            #simple-page .rdueb-home-block .row .col-lg-6.first-column:after {
              display: none; } }

.schedule-subscription {
  position: relative;
  display: block;
  background-color: #e9ecef;
  width: 100%;
  height: 0;
  padding-top: 100%;
  color: #2e283d;
  border-radius: 20px;
  transition: background .4s ease; }

.schedule-subscription {
  transform: scale(0.97);
  transform-origin: center;
  transition: transform .3s ease, background .3s ease; }

.schedule-subscription svg * {
  fill: #343a40;
  transition: all .4s ease; }

.schedule-subscription:hover {
  background-color: #344af0; }

.schedule-subscription:hover {
  transform: scale(1); }

.schedule-subscription:hover svg * {
  fill: #FFFFFF; }

.schedule-subscription svg {
  width: 90px; }
  @media (max-width: 767.98px) {
    .schedule-subscription svg {
      width: 70px; } }

@media (max-width: 540px) {
  #main-program .filters__heading--schedule.container {
    padding-left: 0;
    padding-right: 0; } }

@media (min-width: 541px) {
  #main-program .filters__heading--schedule.container:after {
    content: '';
    display: inline-block;
    width: 30px;
    position: absolute;
    right: 15px;
    top: 0;
    bottom: 0;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 100%); } }

#tag-filter-title {
  position: sticky;
  left: 0;
  background: #fff;
  padding: 12px 15px;
  margin-right: 15px;
  z-index: 100; }

@media (min-width: 541px) {
  #tag-filter-title {
    padding-left: 0; } }

#tag-filter-title:after {
  content: '';
  display: inline-block;
  width: 30px;
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  background: linear-gradient(90deg, white 0%, rgba(255, 255, 255, 0) 100%); }

/* components */
.ajax-form .form__input--submit,
.form .form__input--submit {
  transition: opacity .2s ease, color .2s ease, background-color .2s ease; }

.form-btn {
  background-image: none;
  background-color: #0386A3;
  border-radius: 5px;
  font-size: 1.6rem;
  color: #fff;
  width: 100%;
  font-weight: 700;
  opacity: 1;
  transition: opacity .2s ease, color .2s ease, background-color .2s ease; }
  @media (min-width: 768px) {
    .form-btn {
      font-size: 1.8rem; } }
  .form-btn:hover, .form-btn:active, .form-btn:focus {
    opacity: .7; }
  .form-btn.secondary {
    background-color: #fff;
    color: #0386A3;
    border: solid 1px #0386A3;
    text-decoration: none;
    transition: opacity .2s ease, color .2s ease, background-color .2s ease; }
    .form-btn.secondary:hover, .form-btn.secondary:active, .form-btn.secondary:focus {
      opacity: .7;
      color: #fff;
      background-color: #0386A3; }

.social-btn {
  position: relative; }
  .social-btn + .social-btn {
    margin-top: 1.5rem; }
  .social-btn::before {
    content: '';
    display: inline-block;
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 17px;
    height: 17px;
    background-repeat: no-repeat;
    background-size: contain; }
    @media (min-width: 768px) {
      .social-btn::before {
        width: 27px;
        height: 27px; } }
  .social-btn.google::before {
    background-image: url("../vectors/ico-button-google.svg"); }
  .social-btn.facebook::before {
    background-image: url("../vectors/ico-button-facebook.svg"); }
  .social-btn.linkedin::before {
    background-image: url("../vectors/ico-button-linkedin.svg"); }

.form .form__input input,
.form .formbuilder-row .form-group input,
.ajax-form .form__input input,
.ajax-form .formbuilder-row .form-group input {
  border: solid 1px rgba(46, 40, 61, 0.3);
  border-radius: 5px;
  padding: 10px 15px; }
  .form .form__input input:focus,
  .form .formbuilder-row .form-group input:focus,
  .ajax-form .form__input input:focus,
  .ajax-form .formbuilder-row .form-group input:focus {
    border-color: #0386A3; }

.form .form__input--submit,
.ajax-form .form__input--submit {
  background-image: none; }

.custom-control.custom-checkbox label,
.form-check label {
  padding-left: 2.6rem;
  line-height: 1.8rem; }
  .custom-control.custom-checkbox label::before,
  .form-check label::before {
    width: 1.4rem;
    height: 1.4rem;
    border: solid 1px #2E283D;
    background-color: #fff; }
  .custom-control.custom-checkbox label::after,
  .form-check label::after {
    width: 5px;
    height: 10px;
    left: 5px; }

.custom-control.custom-checkbox input[type="checkbox"]:checked + label::before,
.form-check input[type="checkbox"]:checked + label::before {
  background-color: #0386A3;
  border-color: #0386A3; }

.item-form .inner-container {
  max-width: 350px;
  margin: auto; }

.item-form .form-title {
  font-size: 2rem;
  color: #2E283D;
  font-weight: 700;
  text-align: center;
  margin-bottom: 6px; }
  @media (min-width: 768px) {
    .item-form .form-title {
      font-size: 2.6rem; } }

.item-form .form-group {
  margin-top: 25px; }

.item-form label {
  font-size: 1.5rem;
  font-weight: 400; }

.academy > .slider {
  margin-bottom: 50px; }
  @media (max-aspect-ratio: 16 / 7) and (min-width: 992px) {
    .academy > .slider .slider__slide {
      max-height: max(67vh, 470px); } }
  .academy > .slider .slider--welcome__pagination {
    bottom: 6rem; }
  @media (min-width: 992px) {
    .academy > .slider .swiper-pagination {
      display: none; } }
  .academy > .slider .slide__content__container {
    bottom: 110px;
    position: absolute; }
    @media (min-width: 768px) {
      .academy > .slider .slide__content__container {
        position: static; } }
  .academy > .slider .slider__slide::after {
    background-color: rgba(0, 0, 0, 0.4); }
  @media (min-width: 768px) {
    .academy > .slider.no-overlay .slider__slide::after {
      background-color: transparent; } }
  @media (min-width: 768px) {
    .academy > .slider .slider__slide__content .span,
    .academy > .slider .slider__slide__content h1,
    .academy > .slider .slider__slide__content p {
      max-width: 44.667%; } }
  @media (min-aspect-ratio: 19 / 9), (min-width: 1800px) {
    .academy > .slider .slider__slide__content {
      padding-bottom: 0; } }
  .academy > .slider .slider__slide__content__label {
    margin-bottom: 30px;
    display: block; }
  .academy > .slider .slider__slide__content__title.display-1 {
    font-size: 3.4rem;
    line-height: 3.8rem; }
    @media (min-width: 992px) {
      .academy > .slider .slider__slide__content__title.display-1 {
        font-size: 4.5rem;
        line-height: 5rem; } }
  .academy > .slider .mobile-slide {
    display: block; }
    @media (min-width: 768px) {
      .academy > .slider .mobile-slide {
        display: none; } }
  .academy > .slider .desktop-slide {
    display: none; }
    @media (min-width: 768px) {
      .academy > .slider .desktop-slide {
        display: block; } }

@media (max-aspect-ratio: 16 / 7) and (min-width: 992px) {
  #slider-page .slider__slide,
  #slider-page .slider__slide__content,
  #slider-page .desktop-slide {
    max-height: max(67vh, 470px); } }

#slider-page section.welcome.welcome {
  margin-bottom: 50px; }

#slider-page .welcome__shape {
  width: 100%; }

#slider-page .slider {
  background-color: transparent; }

#slider-page .slider__slide__content {
  padding-bottom: 0;
  position: relative; }

#slider-page .slide__content__container {
  bottom: 110px;
  position: absolute; }
  @media (min-width: 768px) {
    #slider-page .slide__content__container {
      position: static; } }

#slider-page .slider__slide__content__label {
  color: rgba(46, 40, 61, 0.5);
  font-size: 1.2rem;
  margin-bottom: 15px; }
  @media (min-width: 992px) {
    #slider-page .slider__slide__content__label {
      color: rgba(255, 255, 255, 0.5); } }
  @media (min-width: 992px) {
    #slider-page .slider__slide__content__label {
      font-size: 1.4rem; } }

#slider-page .slider__slide__content__title {
  font-size: 3.4rem; }
  @media (min-width: 992px) {
    #slider-page .slider__slide__content__title {
      font-size: 4.5rem; } }

#slider-page .slider__slide__content__paragraph {
  margin-top: 19px;
  font-size: 1.6rem;
  line-height: 2.2rem;
  max-width: none; }

#slider-page .slider__slide__bottom {
  margin-top: 30px; }
  @media (min-width: 992px) {
    #slider-page .slider__slide__bottom {
      margin-top: 0; } }

@media (min-width: 768px) and (max-height: 1113px) {
  #slider-page .slider__slide::before {
    padding-top: 0; } }

@media (min-aspect-ratio: 16 / 9) and (min-width: 1700px) {
  #slider-page .slider__slide::before {
    padding-top: 0; } }

@media (max-width: 991.98px) {
  #slider-page .slider__slide::before {
    padding-top: 0; } }

#slider-page .slider__slide::after {
  content: none; }

#slider-page .slider--welcome__pagination {
  display: block; }
  @media (max-width: 991.98px) {
    #slider-page .slider--welcome__pagination {
      bottom: 4rem; } }
  @media (min-width: 768px) {
    #slider-page .slider--welcome__pagination {
      display: none; } }

#slider-page .home-slide-button a:not(.x-btn) {
  border-color: #000;
  color: #000; }
  @media (min-width: 992px) {
    #slider-page .home-slide-button a:not(.x-btn) {
      border-color: #fff;
      color: #fff; } }

@media (max-width: 1199.98px) {
  #slider-page .slider__slide__content__title {
    color: #2E283D; }
  #slider-page .slider__slide__content__paragraph {
    color: #2E283D; } }

@media (min-width: 992px) {
  #slider-page .slider__slide__content__title,
  #slider-page .slider__slide__content__paragraph {
    color: #fff; }
  #slider-page .slider__slide__bottom {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); } }

#slider-page .mobile-slide {
  display: block; }
  @media (min-width: 992px) {
    #slider-page .mobile-slide {
      display: none; } }

#slider-page .desktop-slide {
  display: none; }
  @media (min-width: 992px) {
    #slider-page .desktop-slide {
      display: block; } }

.search-page .top-items .item-topic {
  color: rgba(0, 0, 0, 0.5); }

.search-page form {
  position: relative; }

.search-page .error-message {
  position: absolute;
  bottom: -20px;
  left: 0;
  font-size: 1.2rem;
  color: #FF0000;
  animation: reveal .3s ease-out; }

@keyframes reveal {
  0% {
    transform: translateX(50px); }
  100% {
    transform: translateX(0); } }

.search-page #academy_search_form {
  position: relative; }
  .search-page #academy_search_form .form__input {
    padding-right: 70px;
    border-bottom: solid 1px #000; }
  .search-page #academy_search_form .form__input.invalid {
    border-bottom: solid 1px #FF0000; }
  .search-page #academy_search_form #academy_search_form_query {
    all: unset;
    font-size: 1.8rem;
    font-weight: 700;
    padding-bottom: 15px; }
    @media (min-width: 768px) {
      .search-page #academy_search_form #academy_search_form_query {
        font-size: 4.5rem; } }
    .search-page #academy_search_form #academy_search_form_query::selection {
      background-color: #0386a3;
      color: #fff; }
  .search-page #academy_search_form button[type="reset"],
  .search-page #academy_search_form button[type="submit"] {
    all: unset;
    cursor: pointer;
    position: relative;
    padding: 1rem 20px !important; }
    .search-page #academy_search_form button[type="reset"]::before, .search-page #academy_search_form button[type="reset"]::after,
    .search-page #academy_search_form button[type="submit"]::before,
    .search-page #academy_search_form button[type="submit"]::after {
      content: '';
      position: absolute;
      right: 0;
      top: 2px;
      width: 25px;
      height: 2px;
      background-color: #000;
      transition: transform .1s ease; }
    .search-page #academy_search_form button[type="reset"]:hover::before, .search-page #academy_search_form button[type="reset"]:focus::before,
    .search-page #academy_search_form button[type="submit"]:hover::before,
    .search-page #academy_search_form button[type="submit"]:focus::before {
      transform: rotate(-45deg) scale(1.15); }
    .search-page #academy_search_form button[type="reset"]:hover::after, .search-page #academy_search_form button[type="reset"]:focus::after,
    .search-page #academy_search_form button[type="submit"]:hover::after,
    .search-page #academy_search_form button[type="submit"]:focus::after {
      transform: rotate(45deg) scale(1.15); }
    .search-page #academy_search_form button[type="reset"]::before, .search-page #academy_search_form button[type="reset"]:active::before,
    .search-page #academy_search_form button[type="submit"]::before,
    .search-page #academy_search_form button[type="submit"]:active::before {
      transform: rotate(-45deg) scale(1); }
    .search-page #academy_search_form button[type="reset"]::after, .search-page #academy_search_form button[type="reset"]:active::after,
    .search-page #academy_search_form button[type="submit"]::after,
    .search-page #academy_search_form button[type="submit"]:active::after {
      transform: rotate(45deg) scale(1); }
    .search-page #academy_search_form button[type="reset"] span,
    .search-page #academy_search_form button[type="submit"] span {
      display: block;
      overflow: hidden;
      width: 0;
      height: 0;
      clip: rect(0, 0, 0, 0);
      position: absolute; }
  .search-page #academy_search_form button[type="submit"] {
    top: -7px;
    transform-origin: 50px;
    transition: transform .1s ease; }
    .search-page #academy_search_form button[type="submit"]::before {
      width: 16px;
      height: 16px;
      border: solid 2px #000;
      border-radius: 25px;
      background-color: transparent;
      right: 5px;
      top: 0; }
    .search-page #academy_search_form button[type="submit"]::after {
      right: 0;
      top: 16px;
      width: 10px; }
    .search-page #academy_search_form button[type="submit"]:hover, .search-page #academy_search_form button[type="submit"]:focus {
      transform: scale(1.15); }
      .search-page #academy_search_form button[type="submit"]:hover::before, .search-page #academy_search_form button[type="submit"]:focus::before {
        transform: rotate(-45deg) scale(1); }
      .search-page #academy_search_form button[type="submit"]:hover::after, .search-page #academy_search_form button[type="submit"]:focus::after {
        transform: rotate(45deg) scale(1); }
  .search-page #academy_search_form .form-group + div,
  .search-page #academy_search_form .form-group + div + div {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%); }
  .search-page #academy_search_form .form-group + div {
    right: 40px; }

.search-page .search-form-container {
  position: relative; }
  .search-page .search-form-container.has-no-input .animation-container .search-hint {
    transform: translateX(0px);
    opacity: 1; }

.search-page .animation-container {
  position: absolute;
  top: 50%;
  width: calc(100% - 70px);
  overflow: hidden;
  pointer-events: none;
  transform: translateY(calc(-50% - 8px)); }
  @media (min-width: 768px) {
    .search-page .animation-container {
      transform: translateY(-50%); } }
  .search-page .animation-container .search-hint {
    color: rgba(0, 0, 0, 0.5);
    font-weight: 700;
    font-size: 1.8rem;
    margin-bottom: 0;
    transform: translateX(500px);
    opacity: 0;
    transition: transform .8s ease-out, opacity .8s ease-out;
    white-space: nowrap; }
    @media (min-width: 768px) {
      .search-page .animation-container .search-hint {
        font-size: 3.5rem; } }

.search-page .search-filters {
  margin-top: 60px;
  display: flex;
  gap: 8.5px;
  white-space: nowrap;
  overflow: auto;
  padding: 0 30px 15px 30px;
  margin-left: -30px;
  width: calc(100% + 60px); }
  .search-page .search-filters + .video-list {
    margin-top: 35px; }
  @media (min-width: 768px) {
    .search-page .search-filters {
      margin-top: 100px;
      width: 100%;
      margin-left: 0;
      padding: 0; } }
  .search-page .search-filters button[data-filter-id] {
    --filterBgColor: #eff2f3;
    --filterTextColor: #000;
    --filterCounterBgColor: #000;
    --filterCounterColor: #eff2f3;
    --filterBorderColor: #000;
    border-radius: 21px;
    height: 42px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    font-size: 1.2rem;
    font-weight: 700;
    border-style: solid;
    color: var(--filterTextColor);
    background-color: var(--filterBgColor);
    border-color: var(--filterBorderColor);
    transition: color .1s ease, background-color .1s ease, border-color .1s ease; }
    .search-page .search-filters button[data-filter-id]:hover {
      --filterBgColor: #000;
      --filterTextColor: #eff2f3;
      --filterCounterBgColor: #eff2f3;
      --filterCounterColor: #000;
      --filterBorderColor: #000; }
    .search-page .search-filters button[data-filter-id]:active {
      --filterBgColor: #54b7d6;
      --filterTextColor: #fff;
      --filterCounterBgColor: #fff;
      --filterCounterColor: #54b7d6;
      --filterBorderColor: #0386A3; }
    .search-page .search-filters button[data-filter-id].selected {
      --filterBgColor: #0386A3;
      --filterTextColor: #fff;
      --filterCounterBgColor: #fff;
      --filterCounterColor: #0386A3;
      --filterBorderColor: #0386A3; }
    .search-page .search-filters button[data-filter-id] .counter-container {
      margin-left: 5px;
      height: 16px;
      display: flex;
      padding: 0 5px;
      align-items: center;
      border-radius: 10px;
      background-color: var(--filterCounterBgColor);
      color: var(--filterCounterColor);
      transition: color .1s ease, background-color .1s ease; }

.search-page .video-list {
  padding: 0; }

.search-page .video-list-grid {
  grid-template-columns: 100%; }
  .search-page .video-list-grid .video-list-item {
    display: flex;
    padding: 30px;
    gap: 30px;
    height: 350px; }
    @media (min-width: 768px) {
      .search-page .video-list-grid .video-list-item {
        height: 285px; } }
    .search-page .video-list-grid .video-list-item:has(.item-speaker), .search-page .video-list-grid .video-list-item:not(:has(.item-speaker)) {
      padding-bottom: 30px; }
    .search-page .video-list-grid .video-list-item.filtered {
      display: none; }
    .search-page .video-list-grid .video-list-item.disabled {
      background-color: #e7e7e7; }
      .search-page .video-list-grid .video-list-item.disabled a {
        pointer-events: none; }
      .search-page .video-list-grid .video-list-item.disabled .item-link {
        display: none; }
  .search-page .video-list-grid .item-content {
    padding-bottom: 23px; }
  .search-page .video-list-grid .item-image {
    width: 227px;
    height: 227px;
    border: solid 1px rgba(46, 40, 61, 0.2);
    flex: 0 0 auto;
    display: none; }
    @media (min-width: 768px) {
      .search-page .video-list-grid .item-image {
        display: block; } }
    .search-page .video-list-grid .item-image img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .search-page .video-list-grid .item-label {
    margin-top: 0; }
  .search-page .video-list-grid .item-title {
    margin-top: 0;
    padding-left: 0;
    color: #000;
    padding: 0 0 1px;
    margin-bottom: 10px;
    font-size: 2rem;
    line-height: 2.4rem;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; }
    @media (min-width: 768px) {
      .search-page .video-list-grid .item-title {
        line-height: 3.1rem;
        font-size: 2.6rem; } }
  .search-page .video-list-grid .item-description {
    margin-top: 5px;
    font-size: 1.5rem;
    line-height: 2.2rem;
    font-weight: 400;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    overflow: hidden; }
    @media (min-width: 768px) {
      .search-page .video-list-grid .item-description {
        -webkit-line-clamp: 4;
        font-size: 1.8rem;
        line-height: 2.6rem;
        margin-top: 15px; } }
  .search-page .video-list-grid .item-link {
    position: absolute;
    bottom: 30px; }
    .search-page .video-list-grid .item-link a {
      position: relative;
      padding-left: 24px; }
      .search-page .video-list-grid .item-link a::before, .search-page .video-list-grid .item-link a::after {
        content: '';
        position: absolute;
        left: calc(14px / 2);
        top: 50%;
        height: 13px;
        width: 1px;
        border-right: solid 1px #0386A3;
        display: inline-block;
        transition: transform .3s ease; }
      .search-page .video-list-grid .item-link a::before {
        transform: translateY(-50%) rotate(0deg); }
      .search-page .video-list-grid .item-link a::after {
        transform: translateY(-50%) rotate(90deg); }
    .search-page .video-list-grid .item-link a {
      font-size: 1.6rem;
      font-weight: 400;
      transition: color .2s ease-out; }
      .search-page .video-list-grid .item-link a::before, .search-page .video-list-grid .item-link a::after {
        transition: border-color .2s ease-out; }
      .search-page .video-list-grid .item-link a:hover {
        color: #54b7d6; }
        .search-page .video-list-grid .item-link a:hover::before, .search-page .video-list-grid .item-link a:hover::after {
          border-color: #54b7d6; }

.favorite-link,
.jssocials-shares .jssocials-share .jssocials-share-link {
  filter: grayscale(0) brightness(1);
  transition: filter .1s ease; }
  .favorite-link:hover, .favorite-link:active, .favorite-link:focus,
  .jssocials-shares .jssocials-share .jssocials-share-link:hover,
  .jssocials-shares .jssocials-share .jssocials-share-link:active,
  .jssocials-shares .jssocials-share .jssocials-share-link:focus {
    filter: grayscale(1) brightness(2.2); }

.favorite-link {
  display: block;
  margin-bottom: 24px; }
  .favorite-link.favorite .favorite-icon {
    background-image: url("../vectors/ico-preferiti-pieno.svg"); }
  .favorite-link .favorite-icon {
    background-image: url("../vectors/ico-preferiti.svg");
    background-repeat: no-repeat;
    width: 18px;
    height: 24px;
    display: block;
    margin-left: 6px; }

/* layout */
footer .footer-row .footer-1,
footer .footer-row .footer-2,
footer .footer-row .footer-3 {
  text-align: center;
  margin-bottom: 0;
  color: #fff; }

footer .footer-row .footer-3 {
  display: flex;
  justify-content: center;
  align-items: center; }

@media (max-width: 991.98px) {
  footer .footer-row .footer-3 a + a {
    margin-left: 15px; } }

@media (max-width: 991.98px) {
  footer .footer-row .icon-social {
    margin-right: 0;
    padding: 0 4px; } }

@media (min-width: 1200px) {
  footer .footer-row .footer-1 {
    text-align: left; }
  footer .footer-row .footer-2 {
    text-align: center; }
  footer .footer-row .footer-3 {
    justify-content: flex-end; } }

@media (max-width: 1199.98px) {
  footer .footer-row {
    flex-direction: column;
    align-items: center;
    gap: 20px; }
    footer .footer-row .footer-1 {
      color: rgba(255, 255, 255, 0.5); }
    footer .footer-row .footer-1,
    footer .footer-row .footer-3 {
      order: 2; }
    footer .footer-row .footer-2 {
      order: 1; } }

footer .footer-row .footer-links {
  display: flex;
  justify-content: center;
  gap: 20px; }

.navbar .logo-container {
  padding-left: 30px; }
  @media (min-width: 992px) {
    .navbar .logo-container {
      padding-left: 40px; } }
  .navbar .logo-container .navbar-brand {
    height: 66px; }
    .navbar .logo-container .navbar-brand img {
      height: 100%; }
    @media (min-width: 768px) {
      .navbar .logo-container .navbar-brand {
        height: unset; } }

.header-search {
  padding: 0 25px;
  position: relative;
  display: flex;
  align-items: center;
  transform: skew(10deg, 0);
  transition: transform .3s ease;
  z-index: 2;
  /*@include media-breakpoint-up(lg) {
    &:focus-within {
      transform: translateX(0);

      form {
        input {
          width: 160px;
          max-width: calc(100% - 90px);
        }
      }

      &::after {
        width: 425px;
      }

      button[type="reset"] {
        opacity: 1;
        pointer-events: all;
      }
    }
  }*/ }
  .header-search:hover, .header-search:active, .header-search:focus {
    z-index: 6; }
    .header-search:hover::after, .header-search:active::after, .header-search:focus::after {
      transform: scaleX(1.35); }
  .header-search::after {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    content: '';
    background-color: #EFF2F3;
    transform: scaleX(1);
    transition: transform .3s ease; }
  .header-search form {
    position: relative;
    z-index: 1; }
    .header-search form input {
      width: 90px;
      transition: width .3s ease; }
  .header-search span.search-mobile-btn {
    display: block;
    background-image: url("/bundles/smau/vectors/ico-search-academy-dark.svg");
    background-repeat: no-repeat;
    background-size: 23px;
    z-index: 1;
    width: 23px;
    height: 23px;
    object-fit: contain;
    vertical-align: middle;
    position: relative;
    transform: skew(-10deg, 0); }
  .header-search button {
    all: unset;
    background-repeat: no-repeat;
    width: 23px;
    height: 23px;
    object-fit: contain;
    vertical-align: middle;
    position: relative;
    /*&[type="submit"],
    &.search-mobile-btn{
      background-image: url("/bundles/smau/vectors/ico-search-academy-dark.svg");
      z-index: 1;
    }*/ }
    .header-search button[type="reset"] {
      opacity: 0;
      pointer-events: none;
      transition: opacity .3s ease;
      position: absolute;
      right: -140px; }
      .header-search button[type="reset"]::before, .header-search button[type="reset"]::after {
        content: '';
        position: absolute;
        width: 15px;
        height: 1px;
        display: inline-block;
        left: 0;
        top: 50%;
        background-color: #000; }
      .header-search button[type="reset"]::before {
        transform: rotate(45deg); }
      .header-search button[type="reset"]::after {
        transform: rotate(-45deg); }
  .header-search input {
    all: unset;
    user-select: auto; }
    .header-search input::selection {
      color: #fff;
      background-color: #0986a3; }

.overlay-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1; }

.navbar__toggler::before {
  right: -13px;
  width: calc(100% + 13px);
  background-color: #0386A3;
  opacity: 1;
  transition: opacity .7s ease; }
  html.menu-open .navbar__toggler::before {
    opacity: 0; }

.navbar__side-menu__content__background::before {
  background-image: linear-gradient(180deg, #004f9E 0%, #0386A3 100%); }

.navbar__quick-links {
  font-size: 1.4rem; }

.social-header {
  width: 100%;
  position: fixed;
  top: 0;
  height: 78.13px;
  transform: translateY(-100%);
  transition: transform .2s ease;
  background-color: #fff;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10; }
  @media (min-width: 768px) {
    .social-header {
      display: none; } }
  .social-header.scrolled {
    transform: translateY(0); }
  .social-header .navbar-brand {
    padding: 0; }
    @media (min-width: 320px) {
      .social-header .navbar-brand img {
        min-height: 4rem;
        max-height: 4rem; } }
  .social-header .smau-social-share {
    display: flex;
    gap: 16px;
    align-items: center; }
    .social-header .smau-social-share .favorite-link {
      margin-bottom: 0; }
  .social-header .jssocials-share + .jssocials-share {
    margin-left: 16px; }
  .social-header .jssocials-share img.jssocials-share-logo {
    height: 2.9rem; }

/* readmore */
#vector-cutted-rectangle {
  fill: var(--bodyColor, #EFF2F3); }

.read-more {
  position: relative;
  overflow: hidden;
  padding-bottom: 3rem; }
  .read-more.is-active .read-more__footer a::before {
    transform: translateY(-50%) rotate(90deg); }

.read-more__content {
  overflow: hidden;
  transition: height .3s;
  /*display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;*/ }
  .read-more.is-active .read-more__content {
    display: block; }

.read-more__footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #eff2f3; }
  .read-more__footer a {
    position: relative;
    padding-left: 24px; }
    .read-more__footer a::before, .read-more__footer a::after {
      content: '';
      position: absolute;
      left: calc(14px / 2);
      top: 50%;
      height: 13px;
      width: 1px;
      border-right: solid 1px #0386A3;
      display: inline-block;
      transition: transform .3s ease; }
    .read-more__footer a::before {
      transform: translateY(-50%) rotate(0deg); }
    .read-more__footer a::after {
      transform: translateY(-50%) rotate(90deg); }
  .read-more__footer a {
    font-weight: 400;
    font-size: 1.6rem; }

/* // */
/* spinner */
[id^="hx-spinner-"]:empty {
  display: block;
  height: 450px;
  position: relative;
  background-color: #e1e1e1;
  overflow: hidden;
  border-radius: 10px;
  margin: 20px 0; }
  [id^="hx-spinner-"]:empty::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    height: 450px;
    background-image: linear-gradient(-45deg, transparent 0%, transparent 25%, #fff 50%, transparent 75%, transparent 100%);
    animation: loading-shine 1.7s ease-in-out infinite; }

@keyframes loading-shine {
  0% {
    transform: translateX(-100%); }
  100% {
    transform: translateX(100%); } }

/* // */
body a {
  color: #0386A3; }

body.page.academy {
  overscroll-behavior: none; }
  body.page.academy.page-blank #slider-page > .top-items {
    max-width: 1140px; }
  body.page.academy.page-blank .top-items .item-title,
  body.page.academy.page-blank .top-items .item-content,
  body.page.academy.page-blank .top-items h1.item-topic,
  body.page.academy.page-blank .top-items h2.item-topic,
  body.page.academy.page-blank .top-items h3.item-topic,
  body.page.academy.page-blank .top-items h4.item-topic,
  body.page.academy.page-blank .top-items h5.item-topic {
    color: #000; }
  body.page.academy .bg-academy-gray {
    background-color: #EFF2F3 !important; }
  body.page.academy .bg-secondary {
    background-color: #0386A3 !important; }
  body.page.academy .academy-container {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    padding-left: 30px;
    padding-right: 30px; }
    body.page.academy .academy-container.narrow {
      max-width: 790px; }
    body.page.academy .academy-container.desktop-only {
      padding-left: 0;
      padding-right: 0;
      max-width: none; }
      @media (min-width: 768px) {
        body.page.academy .academy-container.desktop-only {
          padding-left: 30px;
          padding-right: 30px;
          max-width: 1140px; } }
      body.page.academy .academy-container.desktop-only.narrow {
        max-width: none; }
        @media (min-width: 768px) {
          body.page.academy .academy-container.desktop-only.narrow {
            padding-left: 30px;
            padding-right: 30px;
            max-width: 790px; } }
  body.page.academy .white-card {
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;
    width: 100%;
    margin-top: 43px; }
    body.page.academy .white-card .item-title {
      color: #2E283D;
      font-weight: 700;
      font-size: 2.8rem; }
      @media (min-width: 768px) {
        body.page.academy .white-card .item-title {
          font-size: 3.2rem; } }
  body.page.academy .title-element {
    max-width: 1140px;
    margin: auto auto 32px auto;
    font-size: 2.8rem;
    font-weight: 700;
    padding: 0 30px; }
    @media (min-width: 768px) {
      body.page.academy .title-element {
        font-size: 3.2rem;
        margin: auto auto 40px auto; } }

.bg-polygon {
  --height: 50dvh;
  position: absolute;
  width: 100%;
  height: calc(var(--height, 100%) + 6.16vw);
  background-image: linear-gradient(227deg, #004F9E 0%, #0386A3 100%);
  display: inline-block;
  clip-path: polygon(0% calc(6.16vw + var(--height)), 0 0, 100% 0, 100% var(--height));
  transition: clip-path .5s ease-out, opacity .5s ease-out, height .5s ease-out;
  aspect-ratio: 1/1;
  z-index: -1; }
  .bg-polygon.height-1 {
    --height: 586px; }
  .bg-polygon.height-2 {
    --height: 613px; }

.bg-variable {
  background-color: var(--bodyColor, #EFF2F3) !important; }

.welcome--participant:before {
  background-image: url("../vectors/shape-cutted-rettangle-grey.svg"); }

.area-wysiwyg {
  max-width: 1140px;
  margin: 0 auto;
  padding: 15px 30px; }

.navbar-brand img {
  max-height: 6rem; }

.btn-navbar-login {
  background-image: none; }
  @media (min-width: 768px) {
    .btn-navbar-login {
      padding-right: calc(2rem + 40px);
      padding-left: 60px; } }
  .btn-navbar-login:hover span, .btn-navbar-login:hover::before {
    filter: invert(1); }
  .btn-navbar-login span {
    color: #fff;
    font-weight: 400;
    transition: filter .3s ease; }
  .btn-navbar-login::before {
    content: '';
    display: inline-block;
    position: absolute;
    left: 35px;
    top: 50%;
    transform: translateY(-50%);
    width: 2rem;
    height: 2rem;
    background-image: url("../vectors/ico-user-academy-light.svg");
    background-repeat: no-repeat;
    z-index: 1;
    transition: filter .3s ease; }
  .btn-navbar-login::after {
    background-color: #0386A3; }

.top-items .item-topic {
  margin-top: 60px;
  text-transform: uppercase; }
  .top-items .item-topic a,
  .top-items .item-topic p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 1.2rem;
    font-weight: 700; }
    @media (min-width: 768px) {
      .top-items .item-topic a,
      .top-items .item-topic p {
        font-size: 1.6rem; } }

.top-items h1.item-topic,
.top-items h2.item-topic,
.top-items h3.item-topic,
.top-items h4.item-topic,
.top-items h5.item-topic {
  color: rgba(255, 255, 255, 0.5);
  font-size: 1.2rem;
  font-weight: 700; }
  @media (min-width: 768px) {
    .top-items h1.item-topic,
    .top-items h2.item-topic,
    .top-items h3.item-topic,
    .top-items h4.item-topic,
    .top-items h5.item-topic {
      font-size: 1.6rem; } }

.top-items .item-title {
  color: #fff;
  font-size: 3.4rem;
  line-height: 3.8rem;
  font-weight: 700;
  margin-top: 60px; }
  @media (min-width: 768px) {
    .top-items .item-title {
      font-size: 4.5rem;
      line-height: 5rem; } }

.top-items .item-topic + .item-title {
  margin-top: 22px; }

.top-items .item-content {
  color: #fff;
  font-size: 1.9rem;
  line-height: 2.8rem;
  margin-top: 20px; }

.top-items .item-speaker {
  position: relative;
  color: #fff;
  font-size: 1.6rem;
  padding-left: 25px; }
  .top-items .item-speaker::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 13px;
    height: 14px;
    display: inline-block;
    background-image: url("../vectors/ico-user-academy-light.svg");
    background-repeat: no-repeat;
    background-size: contain; }

.top-items .item-buttons {
  margin-top: 33px;
  display: flex;
  gap: 20px;
  flex-direction: column; }
  @media (min-width: 768px) {
    .top-items .item-buttons {
      flex-direction: row;
      gap: 30px; } }
  .top-items .item-buttons a {
    border: solid 1px #fff;
    padding: 15px 20px;
    min-width: 160px;
    text-align: center;
    color: #fff;
    border-radius: 5px;
    font-size: 1.6rem;
    font-weight: 700;
    background-color: rgba(255, 255, 255, 0);
    transition: background-color 0.1s ease, color 0.1s ease;
    width: 100%; }
    @media (min-width: 768px) {
      .top-items .item-buttons a {
        width: auto; } }
    .top-items .item-buttons a:hover {
      background-color: rgba(255, 255, 255, 0.7);
      color: #000;
      text-decoration: none; }
    .top-items .item-buttons a:active, .top-items .item-buttons a:focus {
      background-color: white;
      color: #000; }

/* pages */
.video-container {
  margin-top: 25px;
  margin-bottom: 30px;
  width: 100%;
  aspect-ratio: 16 / 9;
  background-color: #000;
  border-radius: 10px;
  overflow: hidden;
  position: relative; }
  .video-container .spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: none; }
  .video-container hx\:include:empty + .spinner {
    display: block; }
    .video-container hx\:include:empty + .spinner::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      width: 10px;
      height: 1px;
      animation: spinner 1.5s cubic-bezier(0.18, 0.89, 0.32, 1.28) infinite; }

@keyframes spinner {
  0% {
    transform: translate(-70px, 0);
    width: 60px; }
  25% {
    transform: translate(-70px, 0);
    width: 200px; }
  50% {
    transform: translate(70px, 0);
    width: 60px; }
  75% {
    transform: translate(-70px, 0);
    width: 200px; }
  100% {
    transform: translate(-70px, 0);
    width: 60px; } }

.vote-widget {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  padding-bottom: 10px;
  width: 250px; }
  @media (min-width: 768px) {
    .vote-widget {
      border-bottom: dashed 1px #b6b7be;
      padding-bottom: 30px;
      width: 100%; } }
  .vote-widget p,
  .vote-widget .form-group {
    margin-bottom: 0; }
  .vote-widget .vote-title {
    margin-left: 0;
    font-weight: 700;
    margin-right: 8px; }
    @media (min-width: 768px) {
      .vote-widget .vote-title {
        margin-right: 20px; } }
  .vote-widget span {
    font-size: 1.6rem; }
    @media (min-width: 768px) {
      .vote-widget span {
        margin-left: 10px; } }
  .vote-widget #video_vote_create_form_votes {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 2px; }
  .vote-widget input[type="radio"] {
    position: relative;
    width: 21px;
    height: 21px;
    appearance: none;
    display: block; }
    .vote-widget input[type="radio"] p {
      width: 100%;
      height: 100%;
      display: block; }
    .vote-widget input[type="radio"]::before, .vote-widget input[type="radio"]::after {
      top: 0;
      left: 0;
      overflow: visible;
      border: none;
      width: 21px;
      height: 21px;
      background-color: transparent;
      background-repeat: no-repeat; }
    .vote-widget input[type="radio"]::before {
      background-image: url("../vectors/ico-star-empty.svg"); }
    .vote-widget input[type="radio"]::after {
      background-image: url("../vectors/ico-star-full.svg");
      background-color: transparent;
      transform: none;
      opacity: 0;
      transition: opacity .3s ease; }
    .vote-widget input[type="radio"]:checked::after,
    .vote-widget input[type="radio"]:checked ~ input::after, .vote-widget input[type="radio"]:hover::after {
      opacity: 1; }
  .vote-widget p:has(input[type="radio"]:checked) ~ p > input::after {
    opacity: 1; }
  .vote-widget:has(p > input[type="radio"]:hover) p > input:not(:hover)::after {
    opacity: 0; }
  .vote-widget:has(p > input[type="radio"]:hover) p:has(input[type="radio"]:hover) ~ p > input::after {
    opacity: 1; }
  .vote-widget label {
    display: none; }

.bottom-items {
  display: flex; }
  .bottom-items .smau-social-share {
    display: none; }
    @media (min-width: 768px) {
      .bottom-items .smau-social-share {
        display: block; } }
    .bottom-items .smau-social-share .jssocials-shares {
      display: flex;
      flex-direction: column;
      padding-right: 65px;
      font-size: 1.43em;
      margin: 0;
      gap: 10px; }
      .bottom-items .smau-social-share .jssocials-shares .jssocials-share {
        margin-top: 0;
        margin-bottom: 0; }
        .bottom-items .smau-social-share .jssocials-shares .jssocials-share .jssocials-share-link {
          display: block; }
  .bottom-items .event-title {
    font-weight: 700;
    font-size: 2rem; }
    @media (min-width: 768px) {
      .bottom-items .event-title {
        font-size: 2.6rem; } }
  .bottom-items .event-content {
    font-size: 1.6rem;
    margin: 24px 0 30px; }
    .bottom-items .event-content p {
      margin-bottom: 0; }
  .bottom-items .item-detail-speaker {
    border-top: dashed 1px #B6B7BE;
    border-bottom: dashed 1px #B6B7BE;
    padding: 30px 0;
    margin-bottom: 35px; }
    .bottom-items .item-detail-speaker .item-speaker-inner {
      display: flex;
      gap: 17px;
      flex-direction: column; }
      @media (min-width: 768px) {
        .bottom-items .item-detail-speaker .item-speaker-inner {
          flex-direction: row; } }
      .bottom-items .item-detail-speaker .item-speaker-inner .speaker-img {
        width: 78px;
        height: 78px;
        border-radius: 100px;
        overflow: hidden;
        flex: 0 0 auto; }
        .bottom-items .item-detail-speaker .item-speaker-inner .speaker-img.placeholder {
          border: solid 1px #c9c9c9; }
        .bottom-items .item-detail-speaker .item-speaker-inner .speaker-img img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .bottom-items .item-detail-speaker .item-speaker-inner .speaker-name {
        font-size: 2.2rem;
        font-weight: 700;
        margin-bottom: 12px; }
      .bottom-items .item-detail-speaker .item-speaker-inner .speaker-bio {
        font-size: 1.6rem; }
        .bottom-items .item-detail-speaker .item-speaker-inner .speaker-bio p {
          margin-bottom: 2rem; }

main.video .white-card {
  margin-bottom: 50px !important; }
  @media (min-width: 768px) {
    main.video .white-card {
      margin-bottom: 70px !important; } }

main.profile .top-items {
  opacity: 0;
  transition: 1s ease; }

body.bg-polygon-ready main.profile .top-items,
body.bg-polygon-error main.profile .top-items {
  opacity: 1; }

body.bg-polygon-error main.profile .bg-polygon {
  opacity: 0; }

body.bg-polygon-error main.profile .top-items .item-title,
body.bg-polygon-error main.profile .top-items .item-content,
body.bg-polygon-error main.profile .top-items .item-speaker {
  color: #000; }

body.bg-polygon-error main.profile .top-items .item-speaker::before {
  background-image: url("../vectors/ico-user-academy.svg"); }

body.page.academy .profile .white-card {
  margin-bottom: 100px;
  padding: 40px 20px; }
  @media (min-width: 768px) {
    body.page.academy .profile .white-card {
      padding: 40px 30px; } }
  body.page.academy .profile .white-card .video-list .video-list-grid + .load-more {
    margin-top: 50px; }

.snam-container,
#slider-page > .top-items {
  width: 100%;
  max-width: 790px;
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px; }

.item-snam:first-child {
  margin-top: 50px; }

.item-snam:last-child {
  margin-bottom: 50px; }

.item-snam .item-upper {
  background-color: rgba(46, 40, 61, 0.2);
  border-radius: 10px;
  padding: 20px; }
  @media (min-width: 768px) {
    .item-snam .item-upper {
      padding: 30px; } }
  .item-snam .item-upper .item-title-container {
    display: flex;
    gap: 15px; }
  .item-snam .item-upper .item-counter {
    font-weight: 700;
    background-color: var(--counterColor, #EBA83A);
    color: var(--textColor, #fff);
    aspect-ratio: 1/1;
    padding: 8px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.2rem;
    height: 39.5px; }
    @media (min-width: 768px) {
      .item-snam .item-upper .item-counter {
        font-size: 2.6rem;
        height: 46.5px; } }
  .item-snam .item-upper .item-title {
    color: var(--textColor, #fff);
    font-weight: 700;
    font-size: 2rem; }
    @media (min-width: 768px) {
      .item-snam .item-upper .item-title {
        font-size: 2.6rem; } }
  .item-snam .item-upper .item-content {
    margin-top: 20px;
    color: var(--textColor, #fff);
    font-size: 1.6rem; }
    @media (min-width: 768px) {
      .item-snam .item-upper .item-content {
        font-size: 1.9rem; } }

.item-snam .item-video {
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #d8d8d8; }
  .item-snam .item-video img {
    width: 100%;
    object-fit: cover; }

.item-snam .item-lower {
  display: flex;
  margin-top: 20px;
  padding-bottom: 50px;
  gap: 30px; }
  @media (min-width: 768px) {
    .item-snam .item-lower {
      gap: 75px; } }
  .item-snam .item-lower .item-speaker {
    position: relative;
    color: #fff;
    font-size: 1.6rem;
    padding-left: 25px;
    color: var(--textColor, #fff); }
    .item-snam .item-lower .item-speaker::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 13px;
      height: 14px;
      display: inline-block;
      background-image: url("../vectors/ico-user-academy-light.svg");
      background-repeat: no-repeat;
      background-size: contain; }
  .item-snam .item-lower .item-minutes {
    color: var(--textColor, #fff);
    position: relative;
    padding-left: 25px; }
    .item-snam .item-lower .item-minutes::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background-image: url("../vectors/ico-clock.svg");
      background-repeat: no-repeat;
      width: 15px;
      height: 15px; }

main.login .top-items {
  opacity: 0;
  transition: 1s ease; }

body.bg-polygon-ready main.login .top-items,
body.bg-polygon-error main.login .top-items {
  opacity: 1; }

body.bg-polygon-error main.login .bg-polygon {
  opacity: 0; }

body.bg-polygon-error main.login .top-items .item-title,
body.bg-polygon-error main.login .top-items .item-content,
body.bg-polygon-error main.login .top-items .item-speaker {
  color: #000; }

body.bg-polygon-error main.login .top-items .item-speaker::before {
  background-image: url("../vectors/ico-user-academy.svg"); }

body.page.academy main.login {
  padding-bottom: 100px; }
  body.page.academy main.login .before-social {
    margin-bottom: 20px;
    text-align: center;
    font-size: 1.4rem;
    overflow: hidden; }
  body.page.academy main.login .collapse-link {
    margin-top: 20px;
    text-align: center;
    font-size: 1.6rem;
    overflow: hidden;
    transition: margin-top .2s ease, height .2s ease; }
    body.page.academy main.login .collapse-link .signup-string {
      transition: transform .5s ease, opacity .5s ease;
      transform: translateX(0); }
    body.page.academy main.login .collapse-link .login-string {
      display: block;
      transition: transform .5s ease, opacity .5s ease;
      transform: translateX(0);
      opacity: 1; }
      body.page.academy main.login .collapse-link .login-string button {
        position: relative; }
    body.page.academy main.login .collapse-link button {
      all: unset;
      color: #0386a3;
      text-decoration: underline;
      font-weight: 400;
      cursor: pointer; }
  body.page.academy main.login .smau-academy-login-collapse .form-group:first-child {
    margin-top: 0; }
  body.page.academy main.login .smau-academy-login-collapse > h3 + form .form-group:first-child {
    margin-top: 25px; }
  body.page.academy main.login .smau-academy-login-collapse:not(.show) + .collapse-link {
    margin-top: 0;
    height: 0; }
    body.page.academy main.login .smau-academy-login-collapse:not(.show) + .collapse-link .signup-string {
      opacity: 0;
      transform: translateX(-120px);
      pointer-events: none; }
  body.page.academy main.login .smau-academy-login-collapse.show + .collapse-link .login-string {
    opacity: 0;
    transform: translateX(120px);
    pointer-events: none; }

main.video .top-items {
  opacity: 0;
  transition: 1s ease; }

body.bg-polygon-ready main.video .top-items,
body.bg-polygon-error main.video .top-items {
  opacity: 1; }

body.bg-polygon-error main.video .bg-polygon {
  opacity: 0; }

body.bg-polygon-error main.video .top-items .item-title,
body.bg-polygon-error main.video .top-items .item-content,
body.bg-polygon-error main.video .top-items .item-speaker {
  color: #000; }

body.bg-polygon-error main.video .top-items .item-speaker::before {
  background-image: url("../vectors/ico-user-academy.svg"); }
